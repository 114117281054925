/* stylelint-disable */
.sysmexAtomAccordionPanel_contentHeader {
    margin-bottom: 0;
    padding: 16px;
    background-color: rgba(0,91,172,0.1);
    border-top: 1px solid rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Sysmex, "Source Sans Pro", sans-serif;
}
.sysmexAtomAccordionPanel_contentHeader .sysmexAtomAccordionPanel_titleWrapper {
        pointer-events: none;
    }
.sysmexAtomAccordionPanel_contentHeader p {
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: uppercase;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #333333;
        text-transform: uppercase;
    }
.sysmexAtomAccordionPanel_contentHeader .sysmexAtomAccordionPanel_subtitle {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        text-transform: none;
    }
.sysmexAtomAccordionPanel_contentHeader .sysmexAtomAccordionPanel_icon--plus,
    .sysmexAtomAccordionPanel_contentHeader .sysmexAtomAccordionPanel_icon--minus {
        font-size: 32px;
        pointer-events: none;
    }
.sysmexAtomAccordionPanel_contentHeader .sysmexAtomAccordionPanel_icon--plus {
        background-color: #333333;
    }
.sysmexAtomAccordionPanel_contentHeader .sysmexAtomAccordionPanel_icon--minus {
        display: none;
        background-color: #005BAC;
    }
.sysmexAtomAccordionPanel_contentHeader:hover p {
            color: #00B8EE;
        }
.sysmexAtomAccordionPanel_contentHeader:hover i {
            background-color: #00B8EE;
        }
.sysmexAtomAccordionPanel_content {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
    color: #333333;
    text-transform: none;
    font-size: 16px;
    /* font-family: "Source Sans Pro", sans-serif; */

    background-color: #FFFFFF;
    margin-bottom: 8px;
    overflow: hidden;
    padding: 0 24px;
    transition: all 0.15s ease;
    max-height: 0;
    border-bottom: 0 solid transparent;
    opacity: 0;
}
.sysmexAtomAccordionPanel_content table {
        font-size: 19px;
    }
.sysmexAtomAccordionPanel_content table ol {
            padding-left: 1em;
        }
.sysmexAtomAccordionPanel_content figcaption {
        margin-top: -24px;
    }
.sysmexAtomAccordionPanel_content p:last-of-type {
        margin-bottom: 0;
    }
.sysmexAtomAccordionPanel_content h1,
    .sysmexAtomAccordionPanel_content h2,
    .sysmexAtomAccordionPanel_content h3,
    .sysmexAtomAccordionPanel_content h4,
    .sysmexAtomAccordionPanel_content h5 {
        font-family: inherit;
    }
.sysmexAtomAccordionPanel_contentHeaderActive .sysmexAtomAccordionPanel_icon--minus {
        display: block;
    }
.sysmexAtomAccordionPanel_contentHeaderActive p {
        color: #005BAC;
    }
.sysmexAtomAccordionPanel_contentHeaderActive .sysmexAtomAccordionPanel_icon--plus {
        display: none;
    }
.sysmexAtomAccordionPanel_contentHeaderActive + .sysmexAtomAccordionPanel_content {
        padding: 24px 24px 32px 24px;
        max-height: none;
        max-height: initial;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        opacity: 1;
        overflow: visible;
    }
.sysmexAtomAccordionPanel_contentHeaderActive + .sysmexAtomAccordionPanel_content > div:not(:last-child) {
            margin-bottom: 40px;
        }

/* stylelint-disable */
.sysmexAtomCheckbox_checkbox {
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 0;
    color: #333333;
    text-transform: none;
    font-size: 18px;
    /* font-family: "Source Sans Pro", sans-serif; */

    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: 2px
}
.sysmexAtomCheckbox_checkbox.sysmexAtomCheckbox_alignInline {
        display: inline-flex;
    }
.sysmexAtomCheckbox_checkbox .sysmexAtomCheckbox_checkboxIsChecked {
        cursor: pointer;
        display: none;
        color: #005BAC
    }
.sysmexAtomCheckbox_checkbox .sysmexAtomCheckbox_checkboxIsChecked::before {
            left: -1px;
            position: relative;
        }
.sysmexAtomCheckbox_checkbox label {
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 16px;
        /* font-family: "Source Sans Pro", sans-serif; */

        display: flex;
        position: relative;
        cursor: pointer;
    }
.sysmexAtomCheckbox_checkbox input {
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        background: #FFFFFF;
        border: 1px solid rgba(0,0,0,0.1);
        height: 21px;
        width: 21px;
        cursor: pointer;
        margin: 0 8px 0 0;
        position: relative;
        top: 1px;
        border-radius: 2px;
        flex: none

        /* IE - don't ask -.- */
    }
.sysmexAtomCheckbox_checkbox input::-ms-check {
            border: 1px solid rgba(0,0,0,0.1);
            color: transparent;
        }
.sysmexAtomCheckbox_checkbox input:checked + .sysmexAtomCheckbox_checkboxIsChecked {
            display: block;
            font-size: 23px;
            position: absolute;
        }
.sysmexAtomCheckbox_checkbox input:disabled {
            cursor: not-allowed;
        }
.sysmexAtomCheckbox_checkbox input:disabled + .sysmexAtomCheckbox_checkboxIsChecked {
            cursor: not-allowed;
            color: #909DA8;
        }
.sysmexAtomCheckbox_checkbox input:focus {
            outline: 0;
        }
.sysmexAtomCheckbox_checkbox.sysmexAtomCheckbox_error label {
            color: #E7413E;
        }
.sysmexAtomCheckbox_checkbox.sysmexAtomCheckbox_error .sysmexAtomCheckbox_errorMessage {
            display: block;
        }
.sysmexAtomCheckbox_checkbox.sysmexAtomCheckbox_error input {
            border-color: #E7413E;
        }
.sysmexAtomCheckbox_checkbox.sysmexAtomCheckbox_colored {
        padding: 8px;
    }
.sysmexAtomCheckbox_checkbox.sysmexAtomCheckbox_colored label {
            color: #FFFFFF;
        }
.sysmexAtomCheckbox_checkbox.sysmexAtomCheckbox_colored input {
            border-color: #FFFFFF;
        }
.sysmexAtomCheckbox_checkbox.sysmexAtomCheckbox_colored .sysmexAtomCheckbox_checkboxIsChecked {
            color: #000000;
        }
.sysmexAtomCheckbox_checkbox.sysmexAtomCheckbox_isOutlined {
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 14px;
        /* font-family: "Source Sans Pro", sans-serif; */

        border: 1px solid rgba(51,51,51,0.6);
        border-radius: 4px;
        padding: 4px 8px;
        margin-bottom: 4px;
        line-height: 24px;
    }
.sysmexAtomCheckbox_checkbox.sysmexAtomCheckbox_isOutlined input {
            border: 1px solid rgba(51,51,51,0.6);
        }

/* stylelint-disable */
.sysmexAtomContentContainer_container {
    background-color: #FFFFFF;
    padding: 16px;
    margin-left: -16px;
    margin-right: -16px;
    box-sizing: border-box
}
@media only screen and (min-width: 992px) {
.sysmexAtomContentContainer_container {
        margin-left: 0;
        margin-right: 0
}
    }

/* stylelint-disable */
.sysmexAtomDescriptionTeaser_descriptionTeaser .sysmexAtomDescriptionTeaser_readMoreBtn {
        display: none;
        margin: 16px 0;
    }
.sysmexAtomDescriptionTeaser_descriptionTeaser .sysmexAtomDescriptionTeaser_show {
        display: block;
    }
.sysmexAtomDescriptionTeaser_descriptionTeaser .sysmexAtomDescriptionTeaser_hidden {
        display: none;
    }
.sysmexAtomDescriptionTeaser_descriptionTeaser .sysmexAtomDescriptionTeaser_teaserText,
    .sysmexAtomDescriptionTeaser_descriptionTeaser .sysmexAtomDescriptionTeaser_teaserTextTrim {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        margin-bottom: 24px;
    }

/* stylelint-disable */
.sysmexAtomFormFieldDescription_content {
    background: rgba(0,91,172,0.06);

    font-style: normal;

    font-weight: 400;

    line-height: 26px;

    margin-bottom: 0;

    color: #333333;

    text-transform: none;

    font-size: 18px;

    /* font-family: "Source Sans Pro", sans-serif; */

    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}
.sysmexAtomFormFieldDescription_content .sysmexAtomFormFieldDescription_inner {
        padding: 10px;
        width: 90%;
    }
.sysmexAtomFormFieldDescription_opener {
    position: absolute;
    top: -14px;
    right: 7px;
    font-size: 20px;
    border-radius: 50%;
    color: #005BAC;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
}
.sysmexAtomFormFieldDescription_opener .sysmexAtomFormFieldDescription_infoFilled {
        display: block;
    }
.sysmexAtomFormFieldDescription_opener .sysmexAtomFormFieldDescription_infoOutline {
        display: none;
    }
.sysmexAtomFormFieldDescription_active .sysmexAtomFormFieldDescription_opener .sysmexAtomFormFieldDescription_infoFilled {
            display: none;
        }
.sysmexAtomFormFieldDescription_active .sysmexAtomFormFieldDescription_opener .sysmexAtomFormFieldDescription_infoOutline {
            display: block;
        }
.sysmexAtomFormFieldDescription_active .sysmexAtomFormFieldDescription_content {
        max-height: 500px;
        transition: max-height 0.25s ease-in;
    }

.sysmexAtomGlobalLoader_globalLoader {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
    width: 100%;
    pointer-events: none;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s
}

.sysmexAtomGlobalLoader_globalLoader.sysmexAtomGlobalLoader_is--visible {
        opacity: 0.7;
        pointer-events: all;
    }

.sysmexAtomGlobalLoader_globalLoader .sysmexAtomGlobalLoader_spinner {
        opacity: 1;
        position: relative;
        width: 80px;
        height: 80px;
    }

.sysmexAtomGlobalLoader_globalLoader .sysmexAtomGlobalLoader_spinner div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 8px solid #fff;
            border-radius: 50%;
            -webkit-animation: spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                    animation: spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #00b8ee transparent transparent
        }

.sysmexAtomGlobalLoader_globalLoader .sysmexAtomGlobalLoader_spinner div:nth-child(1) {
                -webkit-animation-delay: -0.45s;
                        animation-delay: -0.45s;
            }

.sysmexAtomGlobalLoader_globalLoader .sysmexAtomGlobalLoader_spinner div:nth-child(2) {
                -webkit-animation-delay: -0.3s;
                        animation-delay: -0.3s;
            }

.sysmexAtomGlobalLoader_globalLoader .sysmexAtomGlobalLoader_spinner div:nth-child(3) {
                -webkit-animation-delay: -0.15s;
                        animation-delay: -0.15s;
            }

@-webkit-keyframes spinner-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinner-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: 'sysmexicons';
    src:  url('../../../../sysmex_fluid_components/Resources/Public/Fonts/sysmexicons.eot?j7eib4');
    src:  url('../../../../sysmex_fluid_components/Resources/Public/Fonts/sysmexicons.eot?j7eib4#iefix') format('embedded-opentype'), url('../../../../sysmex_fluid_components/Resources/Public/Fonts/sysmexicons.woff2?j7eib4') format('woff2'), url('../../../../sysmex_fluid_components/Resources/Public/Fonts/sysmexicons.ttf?j7eib4') format('truetype'), url('../../../../sysmex_fluid_components/Resources/Public/Fonts/sysmexicons.woff?j7eib4') format('woff'), url('../../../../sysmex_fluid_components/Resources/Public/Fonts/sysmexicons.svg?j7eib4#sysmexicons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="sysmexAtomIcon_sysi-"], [class*=" sysmexAtomIcon_sysi-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'sysmexicons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sysmexAtomIcon_sysi-social-twitter-x-symbol:before {
    content: "\ea45";
}

.sysmexAtomIcon_sysi-twitter-x:before {
    content: "\ea44";
}

.sysmexAtomIcon_sysi-drag:before {
    content: "\ea43";
}

.sysmexAtomIcon_sysi-oncology-melanoma:before {
    content: "\ea41";
}

.sysmexAtomIcon_sysi-oncology-urological-cancer:before {
    content: "\ea42";
}

.sysmexAtomIcon_sysi-DNK:before {
    content: "\e981";
}

.sysmexAtomIcon_sysi-oncology:before {
    content: "\e9d1";
}

.sysmexAtomIcon_sysi-social-facebook-circle:before {
    content: "\ea30";
}

.sysmexAtomIcon_sysi-social-facebook-symbol:before {
    content: "\ea31";
}

.sysmexAtomIcon_sysi-social-instagram-circle:before {
    content: "\ea32";
}

.sysmexAtomIcon_sysi-social-instagram-symbol:before {
    content: "\ea33";
}

.sysmexAtomIcon_sysi-social-linkedin-circle:before {
    content: "\ea34";
}

.sysmexAtomIcon_sysi-social-linkedin-symbol:before {
    content: "\ea35";
}

.sysmexAtomIcon_sysi-social-twitter-circle:before {
    content: "\ea36";
}

.sysmexAtomIcon_sysi-social-twitter-symbol:before {
    content: "\ea37";
}

.sysmexAtomIcon_sysi-social-youtube-circle:before {
    content: "\ea38";
}

.sysmexAtomIcon_sysi-social-youtube-symbol:before {
    content: "\ea39";
}

.sysmexAtomIcon_sysi-social-xing-symbol:before {
    content: "\ea3a";
}

.sysmexAtomIcon_sysi-social-xing-circle:before {
    content: "\ea3b";
}

.sysmexAtomIcon_sysi-social-kununu-symbol:before {
    content: "\ea3c";
}

.sysmexAtomIcon_sysi-social-kununu-circle:before {
    content: "\ea3d";
}

.sysmexAtomIcon_sysi-rss-feed-circle:before {
    content: "\ea3e";
}

.sysmexAtomIcon_sysi-rss-feed-symbol:before {
    content: "\ea3f";
}

.sysmexAtomIcon_sysi-cloud-filled:before {
    content: "\ea40";
}

.sysmexAtomIcon_sysi-people-mirror:before {
    content: "\e900";
}

.sysmexAtomIcon_sysi-people:before {
    content: "\e901";
}

.sysmexAtomIcon_sysi-accredited:before {
    content: "\e902";
}

.sysmexAtomIcon_sysi-add-file-folder-plus:before {
    content: "\e903";
}

.sysmexAtomIcon_sysi-Analyser-and-computer-screen:before {
    content: "\e904";
}

.sysmexAtomIcon_sysi-animals-cat:before {
    content: "\e905";
}

.sysmexAtomIcon_sysi-animals-cow:before {
    content: "\e906";
}

.sysmexAtomIcon_sysi-animals-dog:before {
    content: "\e907";
}

.sysmexAtomIcon_sysi-animals-dragon:before {
    content: "\e908";
}

.sysmexAtomIcon_sysi-animals-dromedary:before {
    content: "\e909";
}

.sysmexAtomIcon_sysi-animals-guinea-pig:before {
    content: "\e90a";
}

.sysmexAtomIcon_sysi-animals-horse:before {
    content: "\e90b";
}

.sysmexAtomIcon_sysi-animals-monkey:before {
    content: "\e90c";
}

.sysmexAtomIcon_sysi-animals-mouse:before {
    content: "\e90d";
}

.sysmexAtomIcon_sysi-animals-rat:before {
    content: "\e90e";
}

.sysmexAtomIcon_sysi-announcment:before {
    content: "\e90f";
}

.sysmexAtomIcon_sysi-archiving:before {
    content: "\e910";
}

.sysmexAtomIcon_sysi-arrow-document-download:before {
    content: "\e911";
}

.sysmexAtomIcon_sysi-arrow-alt-to-down--2x:before {
    content: "\e912";
}

.sysmexAtomIcon_sysi-arrow-alt-to-down:before {
    content: "\e913";
}

.sysmexAtomIcon_sysi-arrow-alt-to-left--2x:before {
    content: "\e914";
}

.sysmexAtomIcon_sysi-arrow-alt-to-left:before {
    content: "\e915";
}

.sysmexAtomIcon_sysi-arrow-alt-to-right--2x:before {
    content: "\e916";
}

.sysmexAtomIcon_sysi-arrow-alt-to-right:before {
    content: "\e917";
}

.sysmexAtomIcon_sysi-arrow-alt-to-up--2x:before {
    content: "\e918";
}

.sysmexAtomIcon_sysi-arrow-alt-to-up:before {
    content: "\e919";
}

.sysmexAtomIcon_sysi-arrow-circle--down:before {
    content: "\e91a";
}

.sysmexAtomIcon_sysi-arrow-circle--left:before {
    content: "\e91b";
}

.sysmexAtomIcon_sysi-arrow-circle--right:before {
    content: "\e91c";
}

.sysmexAtomIcon_sysi-arrow-circle--up:before {
    content: "\e91d";
}

.sysmexAtomIcon_sysi-arrow-direction--ne--2x:before {
    content: "\e91e";
}

.sysmexAtomIcon_sysi-arrow-direction--nw--2x:before {
    content: "\e91f";
}

.sysmexAtomIcon_sysi-arrow-direction--right--2x:before {
    content: "\e920";
}

.sysmexAtomIcon_sysi-arrow-direction--se--2x:before {
    content: "\e921";
}

.sysmexAtomIcon_sysi-arrow-direction--sw--2x:before {
    content: "\e922";
}

.sysmexAtomIcon_sysi-arrow-directional--down--2x:before {
    content: "\e923";
}

.sysmexAtomIcon_sysi-arrow-directional--down:before {
    content: "\e924";
}

.sysmexAtomIcon_sysi-arrow-directional--ne:before {
    content: "\e925";
}

.sysmexAtomIcon_sysi-arrow-directional--nw:before {
    content: "\e926";
}

.sysmexAtomIcon_sysi-arrow-directional--right:before {
    content: "\e927";
}

.sysmexAtomIcon_sysi-arrow-directional--se:before {
    content: "\e928";
}

.sysmexAtomIcon_sysi-arrow-directional--sw:before {
    content: "\e929";
}

.sysmexAtomIcon_sysi-arrow-directional--up--2x:before {
    content: "\e92a";
}

.sysmexAtomIcon_sysi-arrow-directional--up:before {
    content: "\e92b";
}

.sysmexAtomIcon_sysi-arrow-directional-left--2x:before {
    content: "\e92c";
}

.sysmexAtomIcon_sysi-arrow-directional-left:before {
    content: "\e92d";
}

.sysmexAtomIcon_sysi-arrows-circle-circular:before {
    content: "\e92e";
}

.sysmexAtomIcon_sysi-arrows-enlarge:before {
    content: "\e92f";
}

.sysmexAtomIcon_sysi-arrows-minimise:before {
    content: "\e930";
}

.sysmexAtomIcon_sysi-ascites-peritoneal-belly:before {
    content: "\e931";
}

.sysmexAtomIcon_sysi-at-e-mail:before {
    content: "\e932";
}

.sysmexAtomIcon_sysi-bacteria:before {
    content: "\e933";
}

.sysmexAtomIcon_sysi-bell-notification:before {
    content: "\e934";
}

.sysmexAtomIcon_sysi-bell:before {
    content: "\e935";
}

.sysmexAtomIcon_sysi-bird:before {
    content: "\e936";
}

.sysmexAtomIcon_sysi-bones-synovial:before {
    content: "\e937";
}

.sysmexAtomIcon_sysi-book-download:before {
    content: "\e938";
}

.sysmexAtomIcon_sysi-bubbles:before {
    content: "\e939";
}

.sysmexAtomIcon_sysi-bullet-2x:before {
    content: "\e93a";
}

.sysmexAtomIcon_sysi-bullet:before {
    content: "\e93b";
}

.sysmexAtomIcon_sysi-burger--double:before {
    content: "\e93c";
}

.sysmexAtomIcon_sysi-burger--triple:before {
    content: "\e93d";
}

.sysmexAtomIcon_sysi-calendar:before {
    content: "\e93e";
}

.sysmexAtomIcon_sysi-cancel-bold--2x:before {
    content: "\e93f";
}

.sysmexAtomIcon_sysi-cancel-bold:before {
    content: "\e940";
}

.sysmexAtomIcon_sysi-cancel-thin_1:before {
    content: "\e941";
}

.sysmexAtomIcon_sysi-cancel-thin--2x:before {
    content: "\e942";
}

.sysmexAtomIcon_sysi-cancel-thin:before {
    content: "\e943";
}

.sysmexAtomIcon_sysi-cancer:before {
    content: "\e944";
}

.sysmexAtomIcon_sysi-CAPD:before {
    content: "\e945";
}

.sysmexAtomIcon_sysi-car-filled:before {
    content: "\e946";
}

.sysmexAtomIcon_sysi-caret--down--2x:before {
    content: "\e947";
}

.sysmexAtomIcon_sysi-caret--down:before {
    content: "\e948";
}

.sysmexAtomIcon_sysi-caret--left--2x:before {
    content: "\e949";
}

.sysmexAtomIcon_sysi-caret--left:before {
    content: "\e94a";
}

.sysmexAtomIcon_sysi-caret--right--2x:before {
    content: "\e94b";
}

.sysmexAtomIcon_sysi-caret--right:before {
    content: "\e94c";
}

.sysmexAtomIcon_sysi-caret--up--2x:before {
    content: "\e94d";
}

.sysmexAtomIcon_sysi-caret--up:before {
    content: "\e94e";
}

.sysmexAtomIcon_sysi-cart:before {
    content: "\e94f";
}

.sysmexAtomIcon_sysi-cell:before {
    content: "\e950";
}

.sysmexAtomIcon_sysi-cellphone:before {
    content: "\e951";
}

.sysmexAtomIcon_sysi-Central-Server_1:before {
    content: "\e952";
}

.sysmexAtomIcon_sysi-Central-Server:before {
    content: "\e953";
}

.sysmexAtomIcon_sysi-chained:before {
    content: "\e954";
}

.sysmexAtomIcon_sysi-chevron--down--2x:before {
    content: "\e955";
}

.sysmexAtomIcon_sysi-chevron--down:before {
    content: "\e956";
}

.sysmexAtomIcon_sysi-chevron--left--2x:before {
    content: "\e957";
}

.sysmexAtomIcon_sysi-chevron--left:before {
    content: "\e958";
}

.sysmexAtomIcon_sysi-chevron--right--2x:before {
    content: "\e959";
}

.sysmexAtomIcon_sysi-chevron--right:before {
    content: "\e95a";
}

.sysmexAtomIcon_sysi-chevron--up--2x:before {
    content: "\e95b";
}

.sysmexAtomIcon_sysi-chevron--up:before {
    content: "\e95c";
}

.sysmexAtomIcon_sysi-chevron-double--down_1:before {
    content: "\e95d";
}

.sysmexAtomIcon_sysi-chevron-double--down--2x:before {
    content: "\e95e";
}

.sysmexAtomIcon_sysi-chevron-double--down--thin--2x:before {
    content: "\e95f";
}

.sysmexAtomIcon_sysi-chevron-double--down:before {
    content: "\e960";
}

.sysmexAtomIcon_sysi-chevron-double--left_1:before {
    content: "\e961";
}

.sysmexAtomIcon_sysi-chevron-double--left--2x:before {
    content: "\e962";
}

.sysmexAtomIcon_sysi-chevron-double--left--thin--2x:before {
    content: "\e963";
}

.sysmexAtomIcon_sysi-chevron-double--left:before {
    content: "\e964";
}

.sysmexAtomIcon_sysi-chevron-double--right_1:before {
    content: "\e965";
}

.sysmexAtomIcon_sysi-chevron-double--right--2x:before {
    content: "\e966";
}

.sysmexAtomIcon_sysi-chevron-double--right--thin--2x:before {
    content: "\e967";
}

.sysmexAtomIcon_sysi-chevron-double--right:before {
    content: "\e968";
}

.sysmexAtomIcon_sysi-chevron-double--up_1:before {
    content: "\e969";
}

.sysmexAtomIcon_sysi-chevron-double--up--2x:before {
    content: "\e96a";
}

.sysmexAtomIcon_sysi-chevron-double--up--thin--2x:before {
    content: "\e96b";
}

.sysmexAtomIcon_sysi-chevron-double--up:before {
    content: "\e96c";
}

.sysmexAtomIcon_sysi-clipboard:before {
    content: "\e96d";
}

.sysmexAtomIcon_sysi-cloud:before {
    content: "\e96e";
}

.sysmexAtomIcon_sysi-cogs:before {
    content: "\e96f";
}

.sysmexAtomIcon_sysi-Computer-Technician:before {
    content: "\e970";
}

.sysmexAtomIcon_sysi-computer-X:before {
    content: "\e971";
}

.sysmexAtomIcon_sysi-computer:before {
    content: "\e972";
}

.sysmexAtomIcon_sysi-confirm-bold--2x:before {
    content: "\e973";
}

.sysmexAtomIcon_sysi-confirm-bold:before {
    content: "\e974";
}

.sysmexAtomIcon_sysi-confirm-thin_1:before {
    content: "\e975";
}

.sysmexAtomIcon_sysi-confirm-thin--2x:before {
    content: "\e976";
}

.sysmexAtomIcon_sysi-confirm-thin:before {
    content: "\e977";
}

.sysmexAtomIcon_sysi-covid-quick-test-2:before {
    content: "\e978";
}

.sysmexAtomIcon_sysi-covid-quick-test:before {
    content: "\e979";
}

.sysmexAtomIcon_sysi-customer-service-man:before {
    content: "\e97a";
}

.sysmexAtomIcon_sysi-customer-service-woman:before {
    content: "\e97b";
}

.sysmexAtomIcon_sysi-cut:before {
    content: "\e97c";
}

.sysmexAtomIcon_sysi-cycle:before {
    content: "\e97d";
}

.sysmexAtomIcon_sysi-cytology:before {
    content: "\e97e";
}

.sysmexAtomIcon_sysi-dewatering:before {
    content: "\e97f";
}

.sysmexAtomIcon_sysi-diagram:before {
    content: "\e980";
}

.sysmexAtomIcon_sysi-document-picture:before {
    content: "\e982";
}

.sysmexAtomIcon_sysi-document-certificate:before {
    content: "\e983";
}

.sysmexAtomIcon_sysi-document-deposit:before {
    content: "\e984";
}

.sysmexAtomIcon_sysi-document-download:before {
    content: "\e985";
}

.sysmexAtomIcon_sysi-document-letter:before {
    content: "\e986";
}

.sysmexAtomIcon_sysi-document-news:before {
    content: "\e987";
}

.sysmexAtomIcon_sysi-document-newsletter:before {
    content: "\e988";
}

.sysmexAtomIcon_sysi-document-simple_1:before {
    content: "\e989";
}

.sysmexAtomIcon_sysi-document-simple:before {
    content: "\e98a";
}

.sysmexAtomIcon_sysi-document-stack:before {
    content: "\e98b";
}

.sysmexAtomIcon_sysi-drop-covid-quick-test-3:before {
    content: "\e98c";
}

.sysmexAtomIcon_sysi-drop-packaging:before {
    content: "\e98d";
}

.sysmexAtomIcon_sysi-dyeing:before {
    content: "\e98e";
}

.sysmexAtomIcon_sysi-e-book:before {
    content: "\e98f";
}

.sysmexAtomIcon_sysi-electrophoresis:before {
    content: "\e990";
}

.sysmexAtomIcon_sysi-embedding:before {
    content: "\e991";
}

.sysmexAtomIcon_sysi-entrance:before {
    content: "\e992";
}

.sysmexAtomIcon_sysi-exclamation:before {
    content: "\e993";
}

.sysmexAtomIcon_sysi-exit:before {
    content: "\e994";
}

.sysmexAtomIcon_sysi-Extended-IPU:before {
    content: "\e995";
}

.sysmexAtomIcon_sysi-external-link:before {
    content: "\e996";
}

.sysmexAtomIcon_sysi-eye-closed:before {
    content: "\e997";
}

.sysmexAtomIcon_sysi-eye-open:before {
    content: "\e998";
}

.sysmexAtomIcon_sysi-fax:before {
    content: "\e999";
}

.sysmexAtomIcon_sysi-felt-pen:before {
    content: "\e99a";
}

.sysmexAtomIcon_sysi-file-folder:before {
    content: "\e99b";
}

.sysmexAtomIcon_sysi-filters:before {
    content: "\e99c";
}

.sysmexAtomIcon_sysi-fingerprint:before {
    content: "\e99d";
}

.sysmexAtomIcon_sysi-Firewall:before {
    content: "\e99e";
}

.sysmexAtomIcon_sysi-flow-cytometry:before {
    content: "\e99f";
}

.sysmexAtomIcon_sysi-funnel:before {
    content: "\e9a0";
}

.sysmexAtomIcon_sysi-globe-wireframe:before {
    content: "\e9a1";
}

.sysmexAtomIcon_sysi-graduate:before {
    content: "\e9a2";
}

.sysmexAtomIcon_sysi-handshake:before {
    content: "\e9a3";
}

.sysmexAtomIcon_sysi-headset:before {
    content: "\e9a4";
}

.sysmexAtomIcon_sysi-heart:before {
    content: "\e9a5";
}

.sysmexAtomIcon_sysi-hematology:before {
    content: "\e9a6";
}

.sysmexAtomIcon_sysi-hierarchy:before {
    content: "\e9a7";
}

.sysmexAtomIcon_sysi-home:before {
    content: "\e9a8";
}

.sysmexAtomIcon_sysi-info:before {
    content: "\e9a9";
}

.sysmexAtomIcon_sysi-ipad-tablet:before {
    content: "\e9aa";
}

.sysmexAtomIcon_sysi-keyvisual-screen-checked:before {
    content: "\e9ab";
}

.sysmexAtomIcon_sysi-knife-cut:before {
    content: "\e9ac";
}

.sysmexAtomIcon_sysi-lab-dish-2:before {
    content: "\e9ad";
}

.sysmexAtomIcon_sysi-labeling:before {
    content: "\e9ae";
}

.sysmexAtomIcon_sysi-laptop:before {
    content: "\e9af";
}

.sysmexAtomIcon_sysi-laser:before {
    content: "\e9b0";
}

.sysmexAtomIcon_sysi-list:before {
    content: "\e9b1";
}

.sysmexAtomIcon_sysi-location-map-2:before {
    content: "\e9b2";
}

.sysmexAtomIcon_sysi-location-map:before {
    content: "\e9b3";
}

.sysmexAtomIcon_sysi-lotus-filled:before {
    content: "\e9b4";
}

.sysmexAtomIcon_sysi-mail-adress:before {
    content: "\e9b5";
}

.sysmexAtomIcon_sysi-mail--2x:before {
    content: "\e9b6";
}

.sysmexAtomIcon_sysi-mail:before {
    content: "\e9b7";
}

.sysmexAtomIcon_sysi-man-mask:before {
    content: "\e9b8";
}

.sysmexAtomIcon_sysi-marker:before {
    content: "\e9b9";
}

.sysmexAtomIcon_sysi-math-minus--2x:before {
    content: "\e9ba";
}

.sysmexAtomIcon_sysi-math-minus-bold:before {
    content: "\e9bb";
}

.sysmexAtomIcon_sysi-math-minus:before {
    content: "\e9bc";
}

.sysmexAtomIcon_sysi-math-plus--2x:before {
    content: "\e9bd";
}

.sysmexAtomIcon_sysi-math-plus-bold:before {
    content: "\e9be";
}

.sysmexAtomIcon_sysi-math-plus:before {
    content: "\e9bf";
}

.sysmexAtomIcon_sysi-matrix:before {
    content: "\e9c0";
}

.sysmexAtomIcon_sysi-medicine-packaging:before {
    content: "\e9c1";
}

.sysmexAtomIcon_sysi-melanoma:before {
    content: "\e9c2";
}

.sysmexAtomIcon_sysi-meter:before {
    content: "\e9c3";
}

.sysmexAtomIcon_sysi-microscope:before {
    content: "\e9c4";
}

.sysmexAtomIcon_sysi-mouse-trap:before {
    content: "\e9c5";
}

.sysmexAtomIcon_sysi-mouse:before {
    content: "\e9c6";
}

.sysmexAtomIcon_sysi-music-off:before {
    content: "\e9c7";
}

.sysmexAtomIcon_sysi-music-on:before {
    content: "\e9c8";
}

.sysmexAtomIcon_sysi-Network-switch:before {
    content: "\e9c9";
}

.sysmexAtomIcon_sysi-network:before {
    content: "\e9ca";
}

.sysmexAtomIcon_sysi-new-window:before {
    content: "\e9cb";
}

.sysmexAtomIcon_sysi-oncology-breast-cancer:before {
    content: "\e9cc";
}

.sysmexAtomIcon_sysi-oncology-colon-cancer:before {
    content: "\e9cd";
}

.sysmexAtomIcon_sysi-oncology-gastric-cancer:before {
    content: "\e9ce";
}

.sysmexAtomIcon_sysi-oncology-gynecologic-cancer:before {
    content: "\e9cf";
}

.sysmexAtomIcon_sysi-oncology-lung-cancer:before {
    content: "\e9d0";
}

.sysmexAtomIcon_sysi-online-Privacy-Statement:before {
    content: "\e9d2";
}

.sysmexAtomIcon_sysi-oral-cancer:before {
    content: "\e9d3";
}

.sysmexAtomIcon_sysi-paper-plane:before {
    content: "\e9d4";
}

.sysmexAtomIcon_sysi-parasites:before {
    content: "\e9d5";
}

.sysmexAtomIcon_sysi-passwort:before {
    content: "\e9d6";
}

.sysmexAtomIcon_sysi-pause-button:before {
    content: "\e9d7";
}

.sysmexAtomIcon_sysi-pencil:before {
    content: "\e9d8";
}

.sysmexAtomIcon_sysi-people-check:before {
    content: "\e9d9";
}

.sysmexAtomIcon_sysi-people-curve:before {
    content: "\e9da";
}

.sysmexAtomIcon_sysi-people-group:before {
    content: "\e9db";
}

.sysmexAtomIcon_sysi-people-man-speech:before {
    content: "\e9dc";
}

.sysmexAtomIcon_sysi-people-man-standing:before {
    content: "\e9dd";
}

.sysmexAtomIcon_sysi-people-torso_1:before {
    content: "\e9de";
}

.sysmexAtomIcon_sysi-people-torso:before {
    content: "\e9df";
}

.sysmexAtomIcon_sysi-people-torsos-filled:before {
    content: "\e9e0";
}

.sysmexAtomIcon_sysi-people-torsos:before {
    content: "\e9e1";
}

.sysmexAtomIcon_sysi-people-woman-speech:before {
    content: "\e9e2";
}

.sysmexAtomIcon_sysi-people-woman-standing:before {
    content: "\e9e3";
}

.sysmexAtomIcon_sysi-peri-cardial-brain:before {
    content: "\e9e4";
}

.sysmexAtomIcon_sysi-peri-cardial-heart:before {
    content: "\e9e5";
}

.sysmexAtomIcon_sysi-phone:before {
    content: "\e9e6";
}

.sysmexAtomIcon_sysi-picture:before {
    content: "\e9e7";
}

.sysmexAtomIcon_sysi-piece-puzzle:before {
    content: "\e9e8";
}

.sysmexAtomIcon_sysi-piggybank-filled:before {
    content: "\e9e9";
}

.sysmexAtomIcon_sysi-pills:before {
    content: "\e9ea";
}

.sysmexAtomIcon_sysi-pin-remember:before {
    content: "\e9eb";
}

.sysmexAtomIcon_sysi-play-academy:before {
    content: "\e9ec";
}

.sysmexAtomIcon_sysi-point-of-care-testing:before {
    content: "\e9ed";
}

.sysmexAtomIcon_sysi-power:before {
    content: "\e9ee";
}

.sysmexAtomIcon_sysi-printer:before {
    content: "\e9ef";
}

.sysmexAtomIcon_sysi-product-xn-single:before {
    content: "\e9f0";
}

.sysmexAtomIcon_sysi-product-xn-system:before {
    content: "\e9f1";
}

.sysmexAtomIcon_sysi-protein:before {
    content: "\e9f3";
}

.sysmexAtomIcon_sysi-publication:before {
    content: "\e9f4";
}

.sysmexAtomIcon_sysi-puzzle:before {
    content: "\e9f5";
}

.sysmexAtomIcon_sysi-question:before {
    content: "\e9f6";
}

.sysmexAtomIcon_sysi-Racked-stacked-server:before {
    content: "\e9f7";
}

.sysmexAtomIcon_sysi-radiation:before {
    content: "\e9f8";
}

.sysmexAtomIcon_sysi-radiology:before {
    content: "\e9f9";
}

.sysmexAtomIcon_sysi-RBC:before {
    content: "\e9fa";
}

.sysmexAtomIcon_sysi-scanner:before {
    content: "\e9fb";
}

.sysmexAtomIcon_sysi-scanner1:before {
    content: "\e9fc";
}

.sysmexAtomIcon_sysi-scattergram:before {
    content: "\e9fd";
}

.sysmexAtomIcon_sysi-screen-24-7_1:before {
    content: "\e9fe";
}

.sysmexAtomIcon_sysi-screen-24-7:before {
    content: "\e9ff";
}

.sysmexAtomIcon_sysi-screen-blank:before {
    content: "\ea00";
}

.sysmexAtomIcon_sysi-screen-pathology:before {
    content: "\ea01";
}

.sysmexAtomIcon_sysi-search:before {
    content: "\ea02";
}

.sysmexAtomIcon_sysi-share:before {
    content: "\ea03";
}

.sysmexAtomIcon_sysi-shield-protect:before {
    content: "\ea04";
}

.sysmexAtomIcon_sysi-Smear-cell:before {
    content: "\ea05";
}

.sysmexAtomIcon_sysi-Smearing:before {
    content: "\ea06";
}

.sysmexAtomIcon_sysi-social-channels-symbol:before {
    content: "\ea07";
}

.sysmexAtomIcon_sysi-social-media-circle:before {
    content: "\ea08";
}

.sysmexAtomIcon_sysi-social-media:before {
    content: "\ea09";
}

.sysmexAtomIcon_sysi-square-circle:before {
    content: "\ea0a";
}

.sysmexAtomIcon_sysi-star:before {
    content: "\ea0b";
}

.sysmexAtomIcon_sysi-stethoscope:before {
    content: "\ea0c";
}

.sysmexAtomIcon_sysi-stop-button:before {
    content: "\ea0d";
}

.sysmexAtomIcon_sysi-subtract-file-folder-minus:before {
    content: "\ea0e";
}

.sysmexAtomIcon_sysi-test-containers:before {
    content: "\ea0f";
}

.sysmexAtomIcon_sysi-test-flask:before {
    content: "\ea10";
}

.sysmexAtomIcon_sysi-test-stand:before {
    content: "\ea11";
}

.sysmexAtomIcon_sysi-test-tube:before {
    content: "\ea12";
}

.sysmexAtomIcon_sysi-text-balloon:before {
    content: "\ea13";
}

.sysmexAtomIcon_sysi-text-balloons:before {
    content: "\ea14";
}

.sysmexAtomIcon_sysi-tools_1:before {
    content: "\ea15";
}

.sysmexAtomIcon_sysi-tools:before {
    content: "\ea16";
}

.sysmexAtomIcon_sysi-trash:before {
    content: "\ea17";
}

.sysmexAtomIcon_sysi-triangle-and-paise-backward:before {
    content: "\ea18";
}

.sysmexAtomIcon_sysi-triangle-and-pause:before {
    content: "\ea19";
}

.sysmexAtomIcon_sysi-triangle-down-2:before {
    content: "\ea1a";
}

.sysmexAtomIcon_sysi-triangle-left-2:before {
    content: "\ea1b";
}

.sysmexAtomIcon_sysi-triangle-right-2:before {
    content: "\ea1c";
}

.sysmexAtomIcon_sysi-triangle-up-2:before {
    content: "\ea1d";
}

.sysmexAtomIcon_sysi-triple-dot:before {
    content: "\ea1e";
}

.sysmexAtomIcon_sysi-truck:before {
    content: "\ea1f";
}

.sysmexAtomIcon_sysi-urinalysis:before {
    content: "\ea20";
}

.sysmexAtomIcon_sysi-urological-cancer:before {
    content: "\ea21";
}

.sysmexAtomIcon_sysi-virus:before {
    content: "\ea22";
}

.sysmexAtomIcon_sysi-WBC:before {
    content: "\ea23";
}

.sysmexAtomIcon_sysi-web-bold-circular-arrow:before {
    content: "\ea24";
}

.sysmexAtomIcon_sysi-web-bold-circular-two-arrows:before {
    content: "\ea25";
}

.sysmexAtomIcon_sysi-web-bold-zoom-minus:before {
    content: "\ea26";
}

.sysmexAtomIcon_sysi-web-bold-zoom-plus:before {
    content: "\ea27";
}

.sysmexAtomIcon_sysi-wi-fi:before {
    content: "\ea28";
}

.sysmexAtomIcon_sysi-woman-mask:before {
    content: "\ea29";
}

.sysmexAtomIcon_sysi-Workstation-Network-server-VPN-Server:before {
    content: "\ea2a";
}

.sysmexAtomIcon_sysi-Workstation-Server:before {
    content: "\ea2b";
}

.sysmexAtomIcon_sysi-world-language:before {
    content: "\ea2c";
}

.sysmexAtomIcon_sysi-yoga-filled:before {
    content: "\ea2d";
}

.sysmexAtomIcon_sysi-zoom-in:before {
    content: "\ea2e";
}

.sysmexAtomIcon_sysi-zoom-out:before {
    content: "\ea2f";
}

/* stylelint-disable */
.sysmexAtomIconLink_link {
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 0;
    color: #333333;
    text-transform: none;
    font-size: 18px;
    /* font-family: "Source Sans Pro", sans-serif; */

    display: inline-flex;
    align-items: center;
}
.sysmexAtomIconLink_link--small {
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 14px;
    }
/* font-family: "Source Sans Pro", sans-serif; */
.sysmexAtomIconLink_link--small .sysmexAtomIconLink_icon {
            font-size: 2rem;
            margin-right: 4px;
        }
.sysmexAtomIconLink_link:active,
    .sysmexAtomIconLink_link:hover,
    .sysmexAtomIconLink_link:focus {
        color: #00B8EE;
    }
.sysmexAtomIconLink_icon {
    margin-right: 8px;
}

/* stylelint-disable */

/* stylelint-disable */
.sysmexAtomInput_input input {
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 16px;
        /* font-family: "Source Sans Pro", sans-serif; */

        margin-bottom: 0;
        padding: 14px 65px 14px 16px;
        border: 1px solid rgba(0,0,0,0.3);
        box-sizing: border-box;
        width: 100%;
        border-radius: 3px
    }
.sysmexAtomInput_input input::-moz-placeholder {
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #909DA8;
            font-style: italic;
        }
input::-moz-placeholder {
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #909DA8;
            font-style: italic;
        }
.sysmexAtomInput_input input::placeholder {
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #909DA8;
            font-style: italic;
        }
.sysmexAtomInput_input input.sysmexAtomInput_icon {
            padding-left: 48px;
        }
.sysmexAtomInput_input .sysmexAtomInput_container {
        position: relative;
        width: 100%;
    }
.sysmexAtomInput_input .sysmexAtomInput_container i.sysmexAtomInput_icon {
            position: absolute;
            left: 0;
            font-size: 48px;
            color: rgba(0,0,0,0.1);
        }
.sysmexAtomInput_input .sysmexAtomInput_container .sysmexAtomInput_clear {
            position: absolute;
            right: 12px;
            top: 12px;
            cursor: pointer;
        }
.sysmexAtomInput_input .sysmexAtomInput_container .sysmexAtomInput_clear i {
                font-size: 24px;
            }
.sysmexAtomInput_input .sysmexAtomInput_errorMessage {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #E7413E;
        display: none;
        margin-top: -5px;
        margin-bottom: 5px;
    }
.sysmexAtomInput_input.sysmexAtomInput_error input {
            border-color: #E7413E;
            color: #E7413E;
        }
.sysmexAtomInput_input.sysmexAtomInput_error input::-moz-placeholder {
            color: #E7413E;
        }
input::-moz-placeholder {
            color: #E7413E;
        }
.sysmexAtomInput_input.sysmexAtomInput_error input::placeholder {
            color: #E7413E;
        }
.sysmexAtomInput_input.sysmexAtomInput_error .sysmexAtomInput_errorMessage {
            display: block;
        }
.sysmexAtomInput_input.sysmexAtomInput_input--isDisabled .sysmexAtomInput_label {
            color: #909DA8;
        }
.sysmexAtomInput_input.sysmexAtomInput_input--isDisabled input {
            color: #909DA8;
            cursor: not-allowed;
        }

/* stylelint-disable */
.iti__flag {
    background-image: url("../../../Resources/Public/Images/flags.png");
}
/* stylelint-disable */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .iti__flag {
        background-image: url("../../../Resources/Public/Images/flags@2x.png");
    }
}
/* stylelint-enable */
.sysmexAtomInputPhone_inputPhone .iti--allow-dropdown {
        width: 100%;
    }
.sysmexAtomInputPhone_inputPhone .sysmexAtomInputPhone_dropdown {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        margin-bottom: 0;
        padding: 11px 65px 11px 16px;
        border: 1px solid rgba(0,0,0,0.3);
        box-sizing: border-box;
        width: 100%;
        border-radius: 3px
    }
.sysmexAtomInputPhone_inputPhone .sysmexAtomInputPhone_dropdown::-moz-placeholder {
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #909DA8;
        }
.dropdown::-moz-placeholder {
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #909DA8;
        }
.sysmexAtomInputPhone_inputPhone .sysmexAtomInputPhone_dropdown::placeholder {
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #909DA8;
        }
.sysmexAtomInputPhone_inputPhone .sysmexAtomInputPhone_dropdown.sysmexAtomInputPhone_icon {
            padding-left: 48px;
        }
.sysmexAtomInputPhone_inputPhone .sysmexAtomInputPhone_container {
        position: relative;
        width: 100%;
    }
.sysmexAtomInputPhone_inputPhone .sysmexAtomInputPhone_container i.sysmexAtomInputPhone_icon {
            position: absolute;
            left: 0;
            font-size: 48px;
            color: rgba(0,0,0,0.1);
        }
.sysmexAtomInputPhone_inputPhone .sysmexAtomInputPhone_errorMessage {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #E7413E;
        display: none;
        margin-top: -5px;
        margin-bottom: 5px;
    }
.sysmexAtomInputPhone_inputPhone.sysmexAtomInputPhone_error input {
            border-color: #E7413E;
            color: #E7413E;
        }
.sysmexAtomInputPhone_inputPhone.sysmexAtomInputPhone_error input::-moz-placeholder {
            color: #E7413E;
        }
input::-moz-placeholder {
            color: #E7413E;
        }
.sysmexAtomInputPhone_inputPhone.sysmexAtomInputPhone_error input::placeholder {
            color: #E7413E;
        }
.sysmexAtomInputPhone_inputPhone.sysmexAtomInputPhone_error .sysmexAtomInputPhone_errorMessage {
            display: block;
        }
.sysmexAtomInputPhone_inputPhone.sysmexAtomInputPhone_input--isDisabled .sysmexAtomInputPhone_label {
            color: #909DA8;
        }
.sysmexAtomInputPhone_inputPhone.sysmexAtomInputPhone_input--isDisabled input {
            color: #909DA8;
            cursor: not-allowed;
        }

.sysmexAtomLoadingSpinner_loadingOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    background: white;
    width: 100%;
    pointer-events: none;
}

    .sysmexAtomLoadingSpinner_loadingOverlay .sysmexAtomLoadingSpinner_loadingSpinner {
        text-align: center;
        opacity: 1;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .sysmexAtomLoadingSpinner_loadingOverlay .sysmexAtomLoadingSpinner_loadingSpinner div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 64px;
            height: 64px;
            margin: 8px;
            border: 8px solid #fff;
            border-radius: 50%;
            -webkit-animation: spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                    animation: spinner-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #00b8ee transparent transparent
        }

    .sysmexAtomLoadingSpinner_loadingOverlay .sysmexAtomLoadingSpinner_loadingSpinner div:nth-child(1) {
                -webkit-animation-delay: -0.45s;
                        animation-delay: -0.45s;
            }

    .sysmexAtomLoadingSpinner_loadingOverlay .sysmexAtomLoadingSpinner_loadingSpinner div:nth-child(2) {
                -webkit-animation-delay: -0.3s;
                        animation-delay: -0.3s;
            }

    .sysmexAtomLoadingSpinner_loadingOverlay .sysmexAtomLoadingSpinner_loadingSpinner div:nth-child(3) {
                -webkit-animation-delay: -0.15s;
                        animation-delay: -0.15s;
            }

.sysmexAtomLoadingSpinner_loadingOverlay.sysmexAtomLoadingSpinner_isGlobal {
        opacity: 0;
        left: 0;
        right: 0;
        position: fixed;
        z-index: 34
    }

.sysmexAtomLoadingSpinner_loadingOverlay.sysmexAtomLoadingSpinner_isGlobal[data-isvisible="1"] {
            -webkit-animation: spinner-fade-in 1s both;
                    animation: spinner-fade-in 1s both;
            transition-timing-function: ease-in;
        }

@-webkit-keyframes spinner-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinner-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spinner-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes spinner-fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.sysmexAtomMedia_media-wrapper .sysmexAtomMedia_media-container {
        border: none;
        width: 100%;
    }
.sysmexAtomMedia_media-wrapper.sysmexAtomMedia_responsive {
        position: relative;
        padding-top: 0;
        height: 0;
        overflow: hidden;
    }
.sysmexAtomMedia_media-wrapper.sysmexAtomMedia_responsive .sysmexAtomMedia_media-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

/* stylelint-disable */
.sysmexAtomModalTrigger_wrapper {
    cursor: pointer;
}

/* stylelint-disable */
.sysmexAtomMultiSelect_hide {
    display: none;
}
.sysmexAtomMultiSelect_isButton .sysmexAtomMultiSelect_main button {
            background-color: #005BAC
        }
.sysmexAtomMultiSelect_isButton .sysmexAtomMultiSelect_main button:active,
            .sysmexAtomMultiSelect_isButton .sysmexAtomMultiSelect_main button:focus,
            .sysmexAtomMultiSelect_isButton .sysmexAtomMultiSelect_main button:visited,
            .sysmexAtomMultiSelect_isButton .sysmexAtomMultiSelect_main button:hover {
                color: #FFFFFF;
            }
.sysmexAtomMultiSelect_isButton .sysmexAtomMultiSelect_main button:hover {
                background: #00B8EE;
            }
.sysmexAtomMultiSelect_isButton .sysmexAtomMultiSelect_main button .sysmexAtomMultiSelect_placeHolder,
            .sysmexAtomMultiSelect_isButton .sysmexAtomMultiSelect_main button .sysmexAtomMultiSelect_title {
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 18px;
                /* font-family: "Source Sans Pro", sans-serif; */

                color: #FFFFFF;
            }
.sysmexAtomMultiSelect_isButton .sysmexAtomMultiSelect_main button::after {
                content: "\e956";
                color: #FFFFFF;
            }
.sysmexAtomMultiSelect_isDisabled .sysmexAtomMultiSelect_main button {
    color: #909DA8;
    cursor: not-allowed
}
.sysmexAtomMultiSelect_isDisabled .sysmexAtomMultiSelect_main button:focus {
        outline: none;
    }
.sysmexAtomMultiSelect_multiSelect {
    display: none;
}
.sysmexAtomMultiSelect_hasError .sysmexAtomMultiSelect_main button {
    border-color: #E7413E;
    color: #E7413E;
}
.sysmexAtomMultiSelect_main {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: left;

    font-style: normal;

    font-weight: 400;

    line-height: 24px;

    margin-bottom: 0;

    color: #333333;

    text-transform: none;

    font-size: 16px;

    /* font-family: "Source Sans Pro", sans-serif; */

    margin-bottom: 0;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
}
.sysmexAtomMultiSelect_main button {
        width: 100%;
        text-align: left;
        height: 48px;
        padding: 12px 65px 12px 16px;
        border: 1px solid rgba(0,0,0,0.3);
        border-radius: 3px;
        background: #FFFFFF;

        font-style: normal;

        font-weight: 400;

        line-height: 24px;

        margin-bottom: 0;

        color: #333333;

        text-transform: none;

        font-size: 16px;

        /* font-family: "Source Sans Pro", sans-serif; */

        cursor: pointer
    }
.sysmexAtomMultiSelect_main button.sysmexAtomMultiSelect_disabled {
            cursor: not-allowed;
            opacity: 0.65;
        }
.sysmexAtomMultiSelect_main button .sysmexAtomMultiSelect_title {
            margin-right: 6px;
            -webkit-user-select: none;
               -moz-user-select: none;
                    user-select: none;
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #333333;
            padding-right: 12px;
        }
.sysmexAtomMultiSelect_main button .sysmexAtomMultiSelect_placeHolder {
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #909DA8;
        }
.sysmexAtomMultiSelect_main button::after {
            font-size: 3rem;
            content: "\e956";
            -webkit-font-smoothing: antialiased;
            font-family: sysmexicons, sans-serif !important;
            font-feature-settings: normal;
            font-style: normal;
            font-variant: normal;
            font-weight: 400;
            line-height: 1;
            speak: none;
            text-transform: none;
            right: 0;
            top: -1px;
            position: absolute;
        }
.sysmexAtomMultiSelect_main.sysmexAtomMultiSelect_open .sysmexAtomMultiSelect_menu {
            border: 1px solid rgba(0,0,0,0.3);
            top: 0;
            position: fixed;
            display: block;
            height: 100vh;

            /* https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
            height: calc(1vh * 100);
            height: calc(var(--sysmexAtomMultiSelectVh, 1vh) * 100);
        }
.sysmexAtomMultiSelect_main.sysmexAtomMultiSelect_open button {
            display: none;
        }
.sysmexAtomMultiSelect_main.sysmexAtomMultiSelect_open .sysmexAtomMultiSelect_bottom-nav {
            display: flex;
        }
.sysmexAtomMultiSelect_main.sysmexAtomMultiSelect_open .sysmexAtomMultiSelect_search-zone {
            display: block;
        }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_bottom-nav {
        padding: 11px 16px;
        font-weight: 600;
        justify-content: space-between;
        box-sizing: border-box;
        position: fixed;
        width: 100%;
        bottom: 0;
        background: #FFFFFF;
        border-top: 1px solid rgba(0,0,0,0.1);
        display: none;
    }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_bottom-nav .sysmexAtomMultiSelect_reset-all,
        .sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_bottom-nav .sysmexAtomMultiSelect_close {
            border-radius: 4px;
            padding: 9px 11px;
            width: 50%;
            text-align: center;

            font-style: normal;

            font-weight: 400;

            line-height: 16px;

            margin-bottom: 0;

            color: #333333;

            text-transform: none;

            font-size: 14px;

            /* font-family: "Source Sans Pro", sans-serif; */

            transition: color 0.12s, border 0.12s, background-color 0.12s;
        }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_bottom-nav .sysmexAtomMultiSelect_close {
            cursor: pointer;
            background: #005BAC;
            border: 1px solid #005BAC;
            color: #FFFFFF;
            margin-left: 13px
        }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_bottom-nav .sysmexAtomMultiSelect_close:hover {
                border: 1px solid #00B8EE;
                background: #00B8EE;
                color: #FFFFFF;
            }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_bottom-nav .sysmexAtomMultiSelect_reset-all {
            cursor: not-allowed;
            color: rgba(51,51,51,0.3);
            border: 1px solid rgba(51,51,51,0.3);
        }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_bottom-nav .sysmexAtomMultiSelect_reset-all-active {
            color: #333333;
            border: 1px solid #333333;
            cursor: pointer
        }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_bottom-nav .sysmexAtomMultiSelect_reset-all-active:hover {
                border: 1px solid #00B8EE;
                background: #00B8EE;
                color: #FFFFFF;
            }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_menu {
        background-color: #FFFFFF;
        border-top: none;
        box-sizing: border-box;
        display: none;
        list-style: none;
        overflow: auto;
        padding: 0;
        width: 100%;
        z-index: 1000;
    }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_menu .sysmexAtomMultiSelect_multi {
            position: relative;
            -webkit-user-select: none;
               -moz-user-select: none;
                    user-select: none;
            list-style: none;
            margin: 0;
            padding: 0 0 11px;
            cursor: pointer;
            overflow-y: auto;
        }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_menu li {
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            font-size: 16px;
            line-height: 22px;
            padding: 10px 16px;
            margin-bottom: 0;
            position: relative
        }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_menu li.sysmexAtomMultiSelect_active {
                margin-bottom: 0;
                color: #005BAC;
            }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_menu li:hover {
                color: #005BAC;
                background-color: rgba(0,91,172,0.1);
            }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_menu li::before {
                border: 1px solid rgba(0,0,0,0.1);
                cursor: pointer;
                height: 19px;
                margin-right: 13px;
                top: 11px;
                position: absolute;
                width: 19px;
                content: "";
                display: inline-block;
                border-radius: 2px;
            }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_menu li.sysmexAtomMultiSelect_active::after {
                -webkit-font-smoothing: antialiased;
                content: "\e974";
                font-family: sysmexicons, sans-serif !important;
                font-feature-settings: normal;
                font-style: normal;
                font-variant: normal;
                font-weight: 400;
                line-height: 1;
                speak: none;
                text-transform: none;
                position: absolute;
                left: 15px;
                top: 10px;
                font-size: 23px;
                color: #005BAC;
            }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_menu li .sysmexAtomMultiSelect_text-wrapper {
                padding-left: 34px;
                position: relative;
                display: block;
            }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_search-zone {
        background-color: #FFFFFF;
        box-sizing: border-box;
        width: 100%;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        display: none;
    }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_search-zone input {
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            margin-bottom: 0;
            box-sizing: border-box;
            width: 100%;
            border: 0;
            padding: 12px 65px 12px 16px
        }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_search-zone input:focus {
                outline: none;
            }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_search-zone input::-moz-placeholder {
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 16px;
                /* font-family: "Source Sans Pro", sans-serif; */

                color: #909DA8;
            }
input::-moz-placeholder {
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 16px;
                /* font-family: "Source Sans Pro", sans-serif; */

                color: #909DA8;
            }
.sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_search-zone input::placeholder {
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 16px;
                /* font-family: "Source Sans Pro", sans-serif; */

                color: #909DA8;
            }
@media only screen and (min-width: 992px) {
        .sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_menu {
            max-height: 330px;
        }

            .sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_menu .sysmexAtomMultiSelect_multi {
                position: static;
                padding-bottom: 0;
            }
            .sysmexAtomMultiSelect_main.sysmexAtomMultiSelect_open .sysmexAtomMultiSelect_menu {
                position: absolute;
                height: auto;
                margin-top: 1px;
            }

            .sysmexAtomMultiSelect_main.sysmexAtomMultiSelect_open button {
                display: block
            }

                .sysmexAtomMultiSelect_main.sysmexAtomMultiSelect_open button::after {
                    content: "\e95c";
                    top: 1px;
                }

            .sysmexAtomMultiSelect_main.sysmexAtomMultiSelect_open::before {
                display: none;
            }

        .sysmexAtomMultiSelect_main .sysmexAtomMultiSelect_bottom-nav {
            position: -webkit-sticky;
            position: sticky;
        }
    }

/* stylelint-disable */
.sysmexAtomMultiSelectAjax_hide {
    display: none;
}
.sysmexAtomMultiSelectAjax_isButton .sysmexAtomMultiSelectAjax_main button {
            background-color: #005BAC
        }
.sysmexAtomMultiSelectAjax_isButton .sysmexAtomMultiSelectAjax_main button:active,
            .sysmexAtomMultiSelectAjax_isButton .sysmexAtomMultiSelectAjax_main button:focus,
            .sysmexAtomMultiSelectAjax_isButton .sysmexAtomMultiSelectAjax_main button:visited,
            .sysmexAtomMultiSelectAjax_isButton .sysmexAtomMultiSelectAjax_main button:hover {
                color: #FFFFFF;
            }
.sysmexAtomMultiSelectAjax_isButton .sysmexAtomMultiSelectAjax_main button:hover {
                background: #00B8EE;
            }
.sysmexAtomMultiSelectAjax_isButton .sysmexAtomMultiSelectAjax_main button .sysmexAtomMultiSelectAjax_placeHolder,
            .sysmexAtomMultiSelectAjax_isButton .sysmexAtomMultiSelectAjax_main button .sysmexAtomMultiSelectAjax_title {
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 18px;
                /* font-family: "Source Sans Pro", sans-serif; */

                color: #FFFFFF;
            }
.sysmexAtomMultiSelectAjax_isButton .sysmexAtomMultiSelectAjax_main button::after {
                content: "\e956";
                color: #FFFFFF;
            }
.sysmexAtomMultiSelectAjax_isDisabled .sysmexAtomMultiSelectAjax_main button {
    color: #909DA8;
    cursor: not-allowed
}
.sysmexAtomMultiSelectAjax_isDisabled .sysmexAtomMultiSelectAjax_main button:focus {
        outline: none;
    }
.sysmexAtomMultiSelectAjax_multiSelect {
    display: none;
}
.sysmexAtomMultiSelectAjax_hasError .sysmexAtomMultiSelectAjax_main button {
    border-color: #E7413E;
    color: #E7413E;
}
.sysmexAtomMultiSelectAjax_main {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: left;

    font-style: normal;

    font-weight: 400;

    line-height: 24px;

    margin-bottom: 0;

    color: #333333;

    text-transform: none;

    font-size: 16px;

    /* font-family: "Source Sans Pro", sans-serif; */

    margin-bottom: 0;
    box-sizing: border-box;
    width: 100%;
    cursor: pointer;
}
.sysmexAtomMultiSelectAjax_main button {
        width: 100%;
        text-align: left;
        height: 48px;
        padding: 12px 65px 12px 16px;
        border: 1px solid rgba(0,0,0,0.3);
        border-radius: 3px;
        background: #FFFFFF;

        font-style: normal;

        font-weight: 400;

        line-height: 24px;

        margin-bottom: 0;

        color: #333333;

        text-transform: none;

        font-size: 16px;

        /* font-family: "Source Sans Pro", sans-serif; */

        cursor: pointer
    }
.sysmexAtomMultiSelectAjax_main button.sysmexAtomMultiSelectAjax_disabled {
            cursor: not-allowed;
            opacity: 0.65;
        }
.sysmexAtomMultiSelectAjax_main button .sysmexAtomMultiSelectAjax_title {
            margin-right: 6px;
            -webkit-user-select: none;
               -moz-user-select: none;
                    user-select: none;
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #333333;
            padding-right: 12px;
        }
.sysmexAtomMultiSelectAjax_main button .sysmexAtomMultiSelectAjax_placeHolder {
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #909DA8;
        }
.sysmexAtomMultiSelectAjax_main button::after {
            font-size: 3rem;
            content: "\e956";
            -webkit-font-smoothing: antialiased;
            font-family: sysmexicons, sans-serif !important;
            font-feature-settings: normal;
            font-style: normal;
            font-variant: normal;
            font-weight: 400;
            line-height: 1;
            speak: none;
            text-transform: none;
            right: 0;
            top: -1px;
            position: absolute;
        }
.sysmexAtomMultiSelectAjax_main.sysmexAtomMultiSelectAjax_open .sysmexAtomMultiSelectAjax_menu {
            border: 1px solid rgba(0,0,0,0.3);
            top: 0;
            position: fixed;
            display: block;
            height: 100vh;

            /* https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
            height: calc(1vh * 100);
            height: calc(var(--sysmexAtomMultiSelectVh, 1vh) * 100);
        }
.sysmexAtomMultiSelectAjax_main.sysmexAtomMultiSelectAjax_open button {
            display: none;
        }
.sysmexAtomMultiSelectAjax_main.sysmexAtomMultiSelectAjax_open .sysmexAtomMultiSelectAjax_bottom-nav {
            display: flex;
        }
.sysmexAtomMultiSelectAjax_main.sysmexAtomMultiSelectAjax_open .sysmexAtomMultiSelectAjax_search-zone {
            display: block;
        }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_bottom-nav {
        padding: 11px 16px;
        font-weight: 600;
        justify-content: space-between;
        box-sizing: border-box;
        position: fixed;
        width: 100%;
        bottom: 0;
        background: #FFFFFF;
        border-top: 1px solid rgba(0,0,0,0.1);
        display: none;
    }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_bottom-nav .sysmexAtomMultiSelectAjax_reset-all,
        .sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_bottom-nav .sysmexAtomMultiSelectAjax_close {
            border-radius: 4px;
            padding: 9px 11px;
            width: 50%;
            text-align: center;

            font-style: normal;

            font-weight: 400;

            line-height: 16px;

            margin-bottom: 0;

            color: #333333;

            text-transform: none;

            font-size: 14px;

            /* font-family: "Source Sans Pro", sans-serif; */

            transition: color 0.12s, border 0.12s, background-color 0.12s;
        }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_bottom-nav .sysmexAtomMultiSelectAjax_close {
            cursor: pointer;
            background: #005BAC;
            border: 1px solid #005BAC;
            color: #FFFFFF;
            margin-left: 13px
        }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_bottom-nav .sysmexAtomMultiSelectAjax_close:hover {
                border: 1px solid #00B8EE;
                background: #00B8EE;
                color: #FFFFFF;
            }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_bottom-nav .sysmexAtomMultiSelectAjax_reset-all {
            cursor: not-allowed;
            color: rgba(51,51,51,0.3);
            border: 1px solid rgba(51,51,51,0.3);
        }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_bottom-nav .sysmexAtomMultiSelectAjax_reset-all-active {
            color: #333333;
            border: 1px solid #333333;
            cursor: pointer
        }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_bottom-nav .sysmexAtomMultiSelectAjax_reset-all-active:hover {
                border: 1px solid #00B8EE;
                background: #00B8EE;
                color: #FFFFFF;
            }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_menu {
        background-color: #FFFFFF;
        border-top: none;
        box-sizing: border-box;
        display: none;
        list-style: none;
        overflow: auto;
        padding: 0;
        width: 100%;
        z-index: 1000;
    }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_menu .sysmexAtomMultiSelectAjax_multi {
            position: relative;
            -webkit-user-select: none;
               -moz-user-select: none;
                    user-select: none;
            list-style: none;
            margin: 0;
            padding: 0 0 11px;
            cursor: pointer;
            overflow-y: auto;
        }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_menu li {
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            font-size: 16px;
            line-height: 22px;
            padding: 10px 16px;
            margin-bottom: 0;
            position: relative
        }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_menu li.sysmexAtomMultiSelectAjax_active {
                margin-bottom: 0;
                color: #005BAC;
            }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_menu li.sysmexAtomMultiSelectAjax_disabled {
                opacity: 0.5;
                pointer-events: none;
            }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_menu li:hover {
                color: #005BAC;
                background-color: rgba(0,91,172,0.1);
            }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_menu li::before {
                border: 1px solid rgba(0,0,0,0.1);
                cursor: pointer;
                height: 19px;
                margin-right: 13px;
                top: 11px;
                position: absolute;
                width: 19px;
                content: "";
                display: inline-block;
                border-radius: 2px;
            }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_menu li.sysmexAtomMultiSelectAjax_active::after {
                -webkit-font-smoothing: antialiased;
                content: "\e974";
                font-family: sysmexicons, sans-serif !important;
                font-feature-settings: normal;
                font-style: normal;
                font-variant: normal;
                font-weight: 400;
                line-height: 1;
                speak: none;
                text-transform: none;
                position: absolute;
                left: 15px;
                top: 10px;
                font-size: 23px;
                color: #005BAC;
            }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_menu li .sysmexAtomMultiSelectAjax_text-wrapper {
                padding-left: 34px;
                position: relative;
                display: block;
            }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_search-zone {
        background-color: #FFFFFF;
        box-sizing: border-box;
        width: 100%;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        display: none;
    }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_search-zone input {
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            margin-bottom: 0;
            box-sizing: border-box;
            width: 100%;
            border: 0;
            padding: 12px 65px 12px 16px
        }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_search-zone input:focus {
                outline: none;
            }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_search-zone input::-moz-placeholder {
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 16px;
                /* font-family: "Source Sans Pro", sans-serif; */

                color: #909DA8;
            }
input::-moz-placeholder {
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 16px;
                /* font-family: "Source Sans Pro", sans-serif; */

                color: #909DA8;
            }
.sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_search-zone input::placeholder {
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 16px;
                /* font-family: "Source Sans Pro", sans-serif; */

                color: #909DA8;
            }
@media only screen and (min-width: 992px) {
        .sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_menu {
            max-height: 330px;
        }

            .sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_menu .sysmexAtomMultiSelectAjax_multi {
                position: static;
                padding-bottom: 0;
            }
            .sysmexAtomMultiSelectAjax_main.sysmexAtomMultiSelectAjax_open .sysmexAtomMultiSelectAjax_menu {
                position: absolute;
                height: auto;
                margin-top: 1px;
            }

            .sysmexAtomMultiSelectAjax_main.sysmexAtomMultiSelectAjax_open button {
                display: block
            }

                .sysmexAtomMultiSelectAjax_main.sysmexAtomMultiSelectAjax_open button::after {
                    content: "\e95c";
                    top: 1px;
                }

            .sysmexAtomMultiSelectAjax_main.sysmexAtomMultiSelectAjax_open::before {
                display: none;
            }

        .sysmexAtomMultiSelectAjax_main .sysmexAtomMultiSelectAjax_bottom-nav {
            position: -webkit-sticky;
            position: sticky;
        }
    }

/* stylelint-disable */
.sysmexAtomNotification_notification {
    padding: 16px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px
}
.sysmexAtomNotification_notification.sysmexAtomNotification_success {
        background-color: #0DAC67;
    }
.sysmexAtomNotification_notification.sysmexAtomNotification_error {
        background-color: #E7413E;
    }
.sysmexAtomNotification_notification.sysmexAtomNotification_info {
        background-color: #005BAC;
    }
.sysmexAtomNotification_notification.sysmexAtomNotification_warning {
        background-color: #F5AC3C;
    }
.sysmexAtomNotification_notification .sysmexAtomNotification_icon {
        font-size: 48px;
        margin-right: 8px;
    }
.sysmexAtomNotification_notification .sysmexAtomNotification_heading {
        font-style: normal;
        font-weight: 900;
        line-height: 16px;
        margin-bottom: 16px;
        color: #333333;
        text-transform: none;
        font-size: 14px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #FFFFFF;
        margin-bottom: 0;
    }
.sysmexAtomNotification_notification .sysmexAtomNotification_contentWrap {
        flex-grow: 1;
    }
.sysmexAtomNotification_notification .sysmexAtomNotification_content p {
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 14px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #FFFFFF;
    }
.sysmexAtomNotification_notification .sysmexAtomNotification_action {
        flex-shrink: 3;
        max-width: 200px;
        margin-left: 48px;
    }

/* stylelint-disable */
.sysmexAtomPassword_passwordInput {
    position: relative;
}
.sysmexAtomPassword_passwordInput .sysmexAtomPassword_toggleShowHidePassword {
        position: absolute;
        z-index: 1;
        cursor: pointer;
        right: 20px;
        top: 16px;

        font-style: normal;

        font-weight: 400;

        line-height: 16px;

        margin-bottom: 0;

        color: #333333;

        text-transform: none;

        font-size: 14px;

        /* font-family: "Source Sans Pro", sans-serif; */

        color: #005BAC;
    }

/* stylelint-disable */
.sysmexAtomProgressBar_progress-track {
    width: 100%;
    background-color: rgba(134,75,153,0.5);
}
.sysmexAtomProgressBar_progress-track .sysmexAtomProgressBar_steps {
        list-style: none;
        display: flex;
        padding: 0;
    }
.sysmexAtomProgressBar_progress-track .sysmexAtomProgressBar_steps span {
            width: 16px;
            background: #FFFFFF;
            -webkit-clip-path: polygon(55% 0, 100% 0%, 45% 100%, 0% 100%);
                    clip-path: polygon(55% 0, 100% 0%, 45% 100%, 0% 100%);
            z-index: 2;
        }
.sysmexAtomProgressBar_progress-track .sysmexAtomProgressBar_steps .sysmexAtomProgressBar_step {
            height: 8px;
            flex: 1
        }
.sysmexAtomProgressBar_progress-track .sysmexAtomProgressBar_steps .sysmexAtomProgressBar_step:first-child {
                background-color: #864B99;
                transform: translate(16px);
            }
.sysmexAtomProgressBar_progress-track .sysmexAtomProgressBar_steps .sysmexAtomProgressBar_step.sysmexAtomProgressBar_isActive {
                background-color: #864B99
            }
.sysmexAtomProgressBar_progress-track .sysmexAtomProgressBar_steps .sysmexAtomProgressBar_step.sysmexAtomProgressBar_isActive::before {
                    content: "";
                    width: 24px;
                    height: 8px;
                    background-color: #864B99;
                    position: absolute;
                    transform: translate(-16px);
                }
.sysmexAtomProgressBar_progress-track .sysmexAtomProgressBar_steps .sysmexAtomProgressBar_step.sysmexAtomProgressBar_isActive:first-child {
                    transform: translate(8px)
                }
.sysmexAtomProgressBar_progress-track .sysmexAtomProgressBar_steps .sysmexAtomProgressBar_step.sysmexAtomProgressBar_isActive:first-child::before {
                        width: 8px;
                        transform: translate(-8px);
                    }
.sysmexAtomProgressBar_progress-track .sysmexAtomProgressBar_steps .sysmexAtomProgressBar_step:not(:last-child) {
                transform: translate(8px);
            }

/* stylelint-disable */
@media only screen and (min-width: 992px) {
.sysmexAtomQuote_quote {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px
}
    }
.sysmexAtomQuote_quote .sysmexAtomQuote_quote-inner {
        position: relative;
        quotes: '«' '»';
        margin: 0;
    }
.sysmexAtomQuote_quote .sysmexAtomQuote_quote-inner .sysmexAtomQuote_text {
            max-width: 39em;
            margin: 0 auto;
        }
.sysmexAtomQuote_quote .sysmexAtomQuote_quote-inner .sysmexAtomQuote_text p {
                font-style: normal;
                font-weight: 400;
                line-height: 48px;
                margin-bottom: 24px;
                color: #333333;
                text-transform: none;
                font-size: 40px;
                /* font-family: "Source Sans Pro", sans-serif; */

                font-family: Sysmex, "Source Sans Pro", sans-serif;
                color: #005BAC;
            }
.sysmexAtomQuote_quote .sysmexAtomQuote_source {
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 16px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #595959;
        opacity: 0.6;
        max-width: 39em;
        margin: 0 auto;
    }
.sysmexAtomQuote_quote .sysmexAtomQuote_image {
        text-align: center
    }
@media only screen and (min-width: 992px) {

    .sysmexAtomQuote_quote .sysmexAtomQuote_image {
            margin-right: 16px;
    }

            .sysmexAtomQuote_quote .sysmexAtomQuote_image img {
                max-height: 200px;
                width: auto;
            }
        }

.sysmexAtomRTEIconLink_link {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    color: #333;
    font-size: 18px;
    font-weight: normal;
    text-decoration: none;
}

    .sysmexAtomRTEIconLink_link em {
        color: white;
        font-size: 48px;
        border-radius: 3px;
        font-weight: normal
    }

    .sysmexAtomRTEIconLink_link em::before {
            display: inline-block;
        }

/* stylelint-disable */
.sysmexAtomRadio_radio {
    position: relative;

    font-style: normal;

    font-weight: 400;

    line-height: 24px;

    margin-bottom: 0;

    color: #333333;

    text-transform: none;

    font-size: 16px;

    /* font-family: "Source Sans Pro", sans-serif; */

    display: inline-flex;
    cursor: pointer;
}
.sysmexAtomRadio_radio input {
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        background: #FFFFFF;
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 50%;
        width: 21px;
        height: 21px;
        cursor: pointer;
        margin: 1px 8px 0 0
    }
.sysmexAtomRadio_radio input:focus {
            outline: 0;
        }
.sysmexAtomRadio_radio input {

        /* IE - don't ask -.- */
    }
.sysmexAtomRadio_radio input::-ms-check {
            border: 1px solid rgba(0,0,0,0.1);
            color: transparent;
        }
.sysmexAtomRadio_radio input:checked + .sysmexAtomRadio_radioIsChecked {
            content: '';
            width: 11px;
            height: 11px;
            background-color: #005BAC;
            border-radius: 50%;
            position: absolute;
            top: 6px;
            left: 5px;
        }
.sysmexAtomRadio_radio input:disabled {
            cursor: not-allowed;
        }
.sysmexAtomRadio_radio input:disabled + .sysmexAtomRadio_radioIsChecked {
            background-color: #909DA8;
        }
.sysmexAtomRadio_radio.sysmexAtomRadio_isOutlined {
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 14px;
        /* font-family: "Source Sans Pro", sans-serif; */

        border: 1px solid rgba(51,51,51,0.6);
        border-radius: 4px;
        padding: 4px 8px;
        margin-bottom: 4px;
        line-height: 24px;
    }
.sysmexAtomRadio_radio.sysmexAtomRadio_isOutlined input {
            border: 1px solid rgba(51,51,51,0.6)
        }
.sysmexAtomRadio_radio.sysmexAtomRadio_isOutlined input:checked + .sysmexAtomRadio_radioIsChecked {
                top: 10px;
                left: 13px;
            }

/* stylelint-disable */
.sysmexAtomSelect_select {
    position: relative;
    width: 100%;
    display: inline-block;
    cursor: pointer;
    text-align: left;
}
.sysmexAtomSelect_select .sysmexAtomSelect_container {
        width: 100%;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
    }
.sysmexAtomSelect_select .sysmexAtomSelect_container input {
            padding-right: 48px;
            cursor: pointer;
        }
.sysmexAtomSelect_select .sysmexAtomSelect_icon {
        position: absolute;
        pointer-events: none;
        right: 0;
        bottom: 0;
        transition: opacity 0.1s ease-out;
        transform: scaleY(1);
        color: #333333;
    }
.sysmexAtomSelect_select .sysmexAtomSelect_icon-open {
        opacity: 1;
        top: -2px;
    }
.sysmexAtomSelect_select .sysmexAtomSelect_icon-close {
        opacity: 0;
    }
.sysmexAtomSelect_select .sysmexAtomSelect_options {
        height: 0;
        transition: height 0.2s ease-out;
        overflow: auto;
        position: absolute;
        width: 100%;
        max-height: 300px;
        top: calc(100% - 1px);
        z-index: 1000;
        list-style: none;
        padding: 0;
        background-color: #FFFFFF;
        box-sizing: border-box;
        border-top: none;
        margin: 2px 0 24px;
    }
.sysmexAtomSelect_select .sysmexAtomSelect_options li {
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            padding: 16px;
            margin-bottom: 0
        }
.sysmexAtomSelect_select .sysmexAtomSelect_options li.sysmexAtomSelect_active {
                font-weight: bold;
            }
.sysmexAtomSelect_select .sysmexAtomSelect_options li:hover {
                color: #005BAC;
                background-color: rgba(0,91,172,0.1);
            }
.sysmexAtomSelect_select.sysmexAtomSelect_open .sysmexAtomSelect_icon-open {
            opacity: 0;
        }
.sysmexAtomSelect_select.sysmexAtomSelect_open .sysmexAtomSelect_icon-close {
            opacity: 1;
        }
.sysmexAtomSelect_select.sysmexAtomSelect_open .sysmexAtomSelect_options {
            height: auto;
            border: 1px solid rgba(0,0,0,0.3);
        }
.sysmexAtomSelect_select.sysmexAtomSelect_open {

        z-index: 1001;
}

/* stylelint-disable */
.sysmexAtomSlider_slider {
    margin-bottom: 48px;
    width: 100%;
    display: block;
}
.sysmexAtomSlider_slider .sysmexAtomSlider_container {
        position: relative;
    }
.sysmexAtomSlider_slider .sysmexAtomSlider_container .sysmexAtomSlider_tooltip {
            position: absolute;
            background: #FFFFFF;
            border: 1px solid rgba(0,0,0,0.1);
            padding: 8px;

            font-style: normal;

            font-weight: 400;

            line-height: 16px;

            margin-bottom: 0;

            color: #333333;

            text-transform: none;

            font-size: 14px;

            /* font-family: "Source Sans Pro", sans-serif; */

            margin: 6px 0 0;
            white-space: nowrap;
        }
.sysmexAtomSlider_slider .sysmexAtomSlider_container .sysmexAtomSlider_tooltip::after,
        .sysmexAtomSlider_slider .sysmexAtomSlider_container .sysmexAtomSlider_tooltip::before {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
.sysmexAtomSlider_slider .sysmexAtomSlider_container .sysmexAtomSlider_tooltip::after {
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: #fff;
            border-width: 3px;
            margin-left: -3px;
        }
.sysmexAtomSlider_slider .sysmexAtomSlider_container .sysmexAtomSlider_tooltip::before {
            border-color: rgba(207, 207, 207, 0);
            border-bottom-color: rgba(0,0,0,0.1);
            border-width: 4px;
            margin-left: -4px;
        }
.sysmexAtomSlider_slider input {
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        width: 100%
    }
.sysmexAtomSlider_slider input:focus {
            outline: none;
        }
.sysmexAtomSlider_slider input::-webkit-slider-thumb {
            -webkit-appearance: none;
                    appearance: none;
            width: 18px;
            height: 18px;
            background-color: #005BAC;
            border-radius: 50%;
            transform: translateY(-25%);
        }
.sysmexAtomSlider_slider input:disabled::-webkit-slider-thumb {
            background-color: #909DA8;
        }
.sysmexAtomSlider_slider input::-moz-range-thumb {
            -moz-appearance: none;
                 appearance: none;
        }
.sysmexAtomSlider_slider input::-ms-thumb {
            appearance: none;
        }
.sysmexAtomSlider_slider input::-webkit-slider-runnable-track {
            height: 8px;
            border-radius: 4px;
            cursor: pointer;
            background: transparent;
            background-color: rgba(0,0,0,0.1);
            color: transparent;
        }
.sysmexAtomSlider_slider input::-moz-range-track {
            /* Vorbereitung CPO */
        }
.sysmexAtomSlider_slider input::-ms-track {
            /* Vorbereitung CPO */
        }

/* stylelint-disable */
.sysmexAtomTab_title {
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0;
    color: #333333;
    text-transform: none;
    font-size: 18px;
    /* font-family: "Source Sans Pro", sans-serif; */

    font-family: Sysmex, "Source Sans Pro", sans-serif;
    border-bottom: 4px solid transparent;
    margin: 0 20px 0 12px;
    transition: all 0.2s;
    cursor: pointer;
    white-space: nowrap
}
.sysmexAtomTab_title:first-child {
        margin-left: 0;
    }
.sysmexAtomTab_title:last-child {
        margin-right: 0;
    }
.sysmexAtomTab_title.sysmexAtomTab_tab--isActive {
        color: #005BAC;
    }
.sysmexAtomTab_title:hover {
        color: #00B8EE;
    }
.sysmexAtomTab_tab .sysmexAtomTab_content {
        display: none;
        padding: 32px 0
    }
.sysmexAtomTab_tab .sysmexAtomTab_content.sysmexAtomTab_hasBackground {
            background-color: #FFFFFF;
            padding: 32px 24px;
        }
.sysmexAtomTab_tab .sysmexAtomTab_content {

        font-style: normal;

        font-weight: 400;

        line-height: 26px;

        margin-bottom: 0;

        color: #333333;

        text-transform: none;

        font-size: 18px

        /* font-family: "Source Sans Pro", sans-serif; */
    }
.sysmexAtomTab_tab .sysmexAtomTab_content.sysmexAtomTab_tab--isActive {
            display: block;
        }
.sysmexAtomTab_tab .sysmexAtomTab_content .sysmexAtomTab_pdfLink {
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: uppercase;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            margin-top: 54px;
            display: flex;
            justify-content: flex-end;
        }
.sysmexAtomTab_tab .sysmexAtomTab_content .sysmexAtomTab_pdfLink a {
                display: flex;
            }
.sysmexAtomTab_tab .sysmexAtomTab_content .sysmexAtomTab_pdfLink a i.sysmexAtomTab_hasBackground {
                        background-color: #005BAC;
                        color: #FFFFFF;
                        font-size: 2.3em;
                        margin-right: 12px;
                    }
.sysmexAtomTab_tab .sysmexAtomTab_content .sysmexAtomTab_pdfLink a div {
                    padding-top: 3px;
                }
.sysmexAtomTab_tab .sysmexAtomTab_content .sysmexAtomTab_pdfLink a span {
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    margin-bottom: 0;
                    color: #333333;
                    text-transform: none;
                    font-size: 14px;
                    /* font-family: "Source Sans Pro", sans-serif; */
                }
.sysmexAtomTab_tab .sysmexAtomTab_content .sysmexAtomTab_pdfLink a:hover div,
                    .sysmexAtomTab_tab .sysmexAtomTab_content .sysmexAtomTab_pdfLink a:hover span {
                        color: #00B8EE;
                    }
.sysmexAtomTab_tab .sysmexAtomTab_content .frame:not(:last-child) {
            margin-bottom: 32px;
        }

/* stylelint-disable */
.sysmexAtomTeaserTilesContainer_teaserTilesContainer {
    overflow: hidden;
}
.sysmexAtomTeaserTilesContainer_teaserTilesContainer a {
        width: 100%;
        max-width: 100%;
        line-height: unset
    }
@media only screen and (min-width: 1280px) {

    .sysmexAtomTeaserTilesContainer_teaserTilesContainer a {
            width: 50%;
            max-width: 50%;
            float: left
    }
        }
@media only screen and (min-width: 768px) {

    .sysmexAtomTeaserTilesContainer_teaserTilesContainer .sysmexAtomTeaserTilesContainer_teaser_four:nth-child(1) > a > div,
    .sysmexAtomTeaserTilesContainer_teaserTilesContainer .sysmexAtomTeaserTilesContainer_teaser_four:nth-child(4) > a > div,
    .sysmexAtomTeaserTilesContainer_teaserTilesContainer .sysmexAtomTeaserTilesContainer_teaser_five:nth-child(5) > a > div {
            flex-direction: row-reverse
    }
        }
@media only screen and (min-width: 1280px) {

    .sysmexAtomTeaserTilesContainer_teaserTilesContainer .sysmexAtomTeaserTilesContainer_teaser_four:nth-child(1) > a > div,
    .sysmexAtomTeaserTilesContainer_teaserTilesContainer .sysmexAtomTeaserTilesContainer_teaser_four:nth-child(4) > a > div,
    .sysmexAtomTeaserTilesContainer_teaserTilesContainer .sysmexAtomTeaserTilesContainer_teaser_five:nth-child(5) > a > div {
            flex-direction: row
    }
        }

/* stylelint-disable */
.sysmexAtomTextarea_textarea textarea {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        margin-bottom: 0;
        padding: 11px 65px 11px 16px;
        border: 1px solid rgba(0,0,0,0.1);
        box-sizing: border-box;
        width: 100%;
        border-radius: 3px;
        min-height: 200px
    }
.sysmexAtomTextarea_textarea textarea::-moz-placeholder {
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #909DA8;
        }
textarea::-moz-placeholder {
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #909DA8;
        }
.sysmexAtomTextarea_textarea textarea::placeholder {
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #909DA8;
        }
.sysmexAtomTextarea_textarea textarea.sysmexAtomTextarea_icon {
            padding-left: 48px;
        }
.sysmexAtomTextarea_textarea .sysmexAtomTextarea_container {
        position: relative;
        width: 100%;
    }
.sysmexAtomTextarea_textarea .sysmexAtomTextarea_container i.sysmexAtomTextarea_icon {
            position: absolute;
            left: 0;
            font-size: 48px;
            color: rgba(0,0,0,0.1);
        }
.sysmexAtomTextarea_textarea .sysmexAtomTextarea_errorMessage {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #E7413E;
        display: none;
        margin-top: -5px;
        margin-bottom: 5px;
    }
.sysmexAtomTextarea_textarea.sysmexAtomTextarea_error textarea {
            border-color: #E7413E;
            color: #E7413E;
        }
.sysmexAtomTextarea_textarea.sysmexAtomTextarea_error textarea::-moz-placeholder {
            color: #E7413E;
        }
textarea::-moz-placeholder {
            color: #E7413E;
        }
.sysmexAtomTextarea_textarea.sysmexAtomTextarea_error textarea::placeholder {
            color: #E7413E;
        }
.sysmexAtomTextarea_textarea.sysmexAtomTextarea_error .sysmexAtomTextarea_errorMessage {
            display: block;
        }
.sysmexAtomTextarea_textarea.sysmexAtomTextarea_textarea--isDisabled .sysmexAtomTextarea_label {
            color: #909DA8;
        }
.sysmexAtomTextarea_textarea.sysmexAtomTextarea_textarea--isDisabled textarea {
            color: #909DA8;
            cursor: not-allowed;
        }

/* stylelint-disable */
.sysmexAtomToggle_toggle {
    position: relative;
    display: inline-flex;
    cursor: pointer;
    margin-bottom: 24px;
    align-items: center
}
@media only screen and (min-width: 768px) {
.sysmexAtomToggle_toggle {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px
        /* font-family: "Source Sans Pro", sans-serif; */
}
    }
.sysmexAtomToggle_toggle .sysmexAtomToggle_toggle--isChecked {
        cursor: pointer;
        display: none;
    }
.sysmexAtomToggle_toggle .sysmexAtomToggle_toggleHandle {
        content: '';
        width: 18px;
        height: 18px;
        background-color: rgba(0,0,0,0.1);
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: 7px;
        transition: background-color 0.2s ease-out, left 0.2s ease-out;
    }
.sysmexAtomToggle_toggle input {
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        background: #FFFFFF;
        border: 1px solid rgba(0,0,0,0.1);
        width: 48px;
        height: 28px;
        cursor: pointer;
        margin: 0 16px 0 0;
        border-radius: 14px;
        flex: none;
        align-self: flex-start

        /* IE - don't ask -.- */
    }
.sysmexAtomToggle_toggle input::-ms-check {
            border: 1px solid rgba(0,0,0,0.1);
            color: transparent;
        }
.sysmexAtomToggle_toggle input:checked + .sysmexAtomToggle_toggleHandle {
            /* width of input - padding - width of handle */
            left: calc(48px - 7px - 18px);
            background-color: #005BAC;
        }
.sysmexAtomToggle_toggle input:disabled {
            cursor: not-allowed;
        }
.sysmexAtomToggle_toggle input:disabled + .sysmexAtomToggle_toggleHandle {
            background-color: rgba(0,0,0,0.1);
        }
.sysmexAtomToggle_toggle--noLabel input {
        margin-right: 0;
    }

/* stylelint-disable */
.sysmexMoleculeAccordion_accordion .sysmexMoleculeAccordion_contentIntro {
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 24px;
        color: #333333;
        text-transform: none;
        font-size: 22px;
        /* font-family: "Source Sans Pro", sans-serif; */
    }

/* stylelint-disable */
.sysmexMoleculeBreadcrumbNavigation_breadcrumbNavigation {
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 0;
    color: #333333;
    text-transform: none;
    font-size: 14px;
    /* font-family: "Source Sans Pro", sans-serif; */

    font-family: Sysmex, "Source Sans Pro", sans-serif;
    color: #005BAC;
    font-weight: normal;
}
.sysmexMoleculeBreadcrumbNavigation_breadcrumbNavigation .sysmexMoleculeBreadcrumbNavigation_breadcrumbNavigation__outerWrapper {
        overflow: hidden;
        height: 30px;
    }
.sysmexMoleculeBreadcrumbNavigation_breadcrumbNavigation .sysmexMoleculeBreadcrumbNavigation_breadcrumbNavigation__outerWrapper .sysmexMoleculeBreadcrumbNavigation_breadcrumbNavigation__innerWrapper {
            overflow-x: auto;
            overflow-y: hidden;
            padding-bottom: 30px;
        }
.sysmexMoleculeBreadcrumbNavigation_breadcrumbNavigation a {
        color: #909DA8;
        margin: 0 -1px
    }
@media only screen and (min-width: 768px) {

    .sysmexMoleculeBreadcrumbNavigation_breadcrumbNavigation a {
            color: #000000
    }
        }
.sysmexMoleculeBreadcrumbNavigation_breadcrumbNavigation a:hover {
            text-decoration: underline
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeBreadcrumbNavigation_breadcrumbNavigation a:hover {
                color: #00B8EE;
                text-decoration: none
        }
            }
.sysmexMoleculeBreadcrumbNavigation_breadcrumbNavigation a.sysmexMoleculeBreadcrumbNavigation_activePage {
            color: #333333;
        }
.sysmexMoleculeBreadcrumbNavigation_breadcrumbNavigation i {
        transform: translateY(20%);
        display: inline-block;
        font-size: 18px;
        color: #909DA8
    }
@media only screen and (min-width: 768px) {

    .sysmexMoleculeBreadcrumbNavigation_breadcrumbNavigation i {
            color: #000000
    }
        }

/* stylelint-disable */
.sysmexMoleculeCardsHeader_cardsHeader {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 8px 0;
}
.sysmexMoleculeCardsHeader_cardsHeader .sysmexMoleculeCardsHeader_icon {
        margin: 0 16px
    }
.sysmexMoleculeCardsHeader_cardsHeader .sysmexMoleculeCardsHeader_icon::before {
            color: #FFFFFF;
        }
.sysmexMoleculeCardsHeader_cardsHeader .sysmexMoleculeCardsHeader_iconburger {
        margin-left: auto
    }
.sysmexMoleculeCardsHeader_cardsHeader .sysmexMoleculeCardsHeader_iconburger::before {
            color: #FFFFFF;
            cursor: pointer;
        }
.sysmexMoleculeCardsHeader_cardsHeader .sysmexMoleculeCardsHeader_title {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        font-family: Sysmex, "Source Sans Pro", sans-serif;
        color: #FFFFFF;
        text-transform: uppercase;
    }
.sysmexMoleculeCardsHeader_cardsHeader.sysmexMoleculeCardsHeader_cardsHeader--isDarkcolor .sysmexMoleculeCardsHeader_icon::before {
                color: #000000;
            }
.sysmexMoleculeCardsHeader_cardsHeader.sysmexMoleculeCardsHeader_cardsHeader--isDarkcolor .sysmexMoleculeCardsHeader_iconburger::before {
                color: #000000;
            }
.sysmexMoleculeCardsHeader_cardsHeader.sysmexMoleculeCardsHeader_cardsHeader--isDarkcolor .sysmexMoleculeCardsHeader_title {
            color: #000000;
        }

.sysmexMoleculeCarousel_carousel {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow-x: hidden
}

@media (--logo-offset) {

    .sysmexMoleculeCarousel_carousel {
        overflow-x: visible
}
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-outer {
        padding: 0 !important;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-outer [hidden] {
        display: none !important;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-outer > button {
        display: none;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-outer [aria-controls],
    .sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-outer [data-action] {
        cursor: pointer;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-slider {
        display: flex;
        transition: all 0s;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-slider > .sysmexMoleculeCarousel_tns-item {
        box-sizing: border-box;
        font-size: 0; /* remove space below slider */
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-horizontal.sysmexMoleculeCarousel_tns-subpixel {
        white-space: nowrap;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-horizontal.sysmexMoleculeCarousel_tns-subpixel > .sysmexMoleculeCarousel_tns-item {
        display: block;
        white-space: normal;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-horizontal.sysmexMoleculeCarousel_tns-no-subpixel::after {
        content: '';
        display: table;
        clear: both;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-horizontal.sysmexMoleculeCarousel_tns-no-subpixel > .sysmexMoleculeCarousel_tns-item {
        float: left;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-horizontal.sysmexMoleculeCarousel_tns-carousel.sysmexMoleculeCarousel_tns-no-subpixel > .sysmexMoleculeCarousel_tns-item {
        margin-right: -100%;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-no-calc {
        position: relative;
        left: 0;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-gallery {
        position: relative;
        left: 0;
        min-height: 1px;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-gallery > .sysmexMoleculeCarousel_tns-item {
        position: absolute;
        left: -100%;
        transition: transform 0s, opacity 0s;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-gallery > .sysmexMoleculeCarousel_tns-slide-active {
        position: relative;
        left: auto !important;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-gallery > .sysmexMoleculeCarousel_tns-moving {
        transition: all 0.25s;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-autowidth {
        display: inline-block;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-lazy-img {
        transition: opacity 0.6s;
        opacity: 0.6;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-lazy-img.sysmexMoleculeCarousel_tns-complete {
        opacity: 1;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-ah {
        transition: height 0s;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-ovh {
        overflow: hidden;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-visually-hidden {
        position: absolute;
        left: -10000em;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-transparent {
        opacity: 0;
        visibility: hidden;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-fadeIn {
        opacity: 1;
        filter: alpha(opacity=100);
        z-index: 0;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-normal,
    .sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-fadeOut {
        opacity: 0;
        filter: alpha(opacity=0);
        z-index: -1;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-vpfix {
        white-space: nowrap;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-vpfix > div,
    .sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-vpfix > li {
        display: inline-block;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-t-subp2 {
        margin: 0 auto;
        width: 310px;
        position: relative;
        height: 10px;
        overflow: hidden;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-t-ct {
        width: 2333.3333%;
        width: calc(100% * 70 / 3);
        position: absolute;
        right: 0;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-t-ct::after {
        content: '';
        display: table;
        clear: both;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_tns-t-ct > div {
        width: 1.4286%;
        width: calc(100% / 70);
        height: 10px;
        float: left;
    }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_carousel-prev,
    .sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_carousel-next {
        -webkit-user-select: none;
           -moz-user-select: none;
                user-select: none;
        outline: none;
        position: absolute;
        z-index: 2;
        text-decoration: none;
        text-shadow: 1px 1px 3px rgba(150, 150, 150, 1);
        left: -8px;
        border: 0;
        top: calc(50% - 40px);
        cursor: pointer;
        color: #fff;
        opacity: 1;
        line-height: 1;
        transition:
            opacity 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67), color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67)
    }

@media (--tablet) {

    .sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_carousel-prev,
    .sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_carousel-next {
            left: -8px
    }
        }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_carousel-prev:active, .sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_carousel-next:active {
            color: #595959;
        }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_carousel-prev i, .sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_carousel-next i {
            font-size: 64px;
        }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_carousel-next {
        right: -8px;
        left: auto
    }

@media (--tablet) {

    .sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_carousel-next {
            right: -8px
    }
        }

.sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_carousel-next[aria-disabled='true'],
    .sysmexMoleculeCarousel_carousel .sysmexMoleculeCarousel_carousel-prev[aria-disabled='true'] {
        opacity: 0.25;
        color: #666;
        cursor: default;
    }

.sysmexMoleculeCarousel_carousel.sysmexMoleculeCarousel_hasPadding .sysmexMoleculeCarousel_tns-slider > .sysmexMoleculeCarousel_tns-item {
            padding: 0 15px;
        }

@media (--logo-offset) {
            .sysmexMoleculeCarousel_carousel.sysmexMoleculeCarousel_controlsOutside .sysmexMoleculeCarousel_carousel-prev {
                left: -56px;
            }

            .sysmexMoleculeCarousel_carousel.sysmexMoleculeCarousel_controlsOutside .sysmexMoleculeCarousel_carousel-next {
                right: -56px;
            }
        }

.sysmexMoleculeCarousel_carousel.sysmexMoleculeCarousel_controlsImageCenter .sysmexMoleculeCarousel_carousel-prev,
        .sysmexMoleculeCarousel_carousel.sysmexMoleculeCarousel_controlsImageCenter .sysmexMoleculeCarousel_carousel-next {
            top: calc(50% - 150px);
        }

/* stylelint-disable */
.sysmexMoleculeCascadingCheckboxes_formField {
    margin-bottom: 24px;
    width: 100%;
    box-sizing: border-box;
    position: relative
}
.sysmexMoleculeCascadingCheckboxes_formField:last-child {
        margin-bottom: 0;
    }
.sysmexMoleculeCascadingCheckboxes_formField .sysmexMoleculeCascadingCheckboxes_label {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        display: block;
        margin-bottom: 5px;
    }
.sysmexMoleculeCascadingCheckboxes_formField .sysmexMoleculeCascadingCheckboxes_description {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        padding-bottom: 8px;
    }
.sysmexMoleculeCascadingCheckboxes_formField .sysmexMoleculeCascadingCheckboxes_innerLabel {
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 23px;
        /* font-family: "Source Sans Pro", sans-serif; */

        padding-top: 8px;
        padding-bottom: 8px;
        display: block;
    }
.sysmexMoleculeCascadingCheckboxes_formField .sysmexMoleculeCascadingCheckboxes_innerCheckboxGroup {
        margin-left: calc(8px + 20px);
        margin-bottom: 8px;
    }
.sysmexMoleculeCascadingCheckboxes_formField .sysmexMoleculeCascadingCheckboxes_innerCheckboxGroup .sysmexMoleculeCascadingCheckboxes_checkboxGroup .sysmexMoleculeCheckboxGroup_container .sysmexAtomCheckbox_checkbox {
                    display: block;
                }
.sysmexMoleculeCascadingCheckboxes_formField .sysmexMoleculeCascadingCheckboxes_errorMessage {
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 23px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #E7413E;
        display: none;
        margin-top: -5px;
        margin-bottom: 5px;
    }
.sysmexMoleculeCascadingCheckboxes_formField.sysmexMoleculeCascadingCheckboxes_error label {
            color: #E7413E;
        }
.sysmexMoleculeCascadingCheckboxes_formField.sysmexMoleculeCascadingCheckboxes_error .sysmexMoleculeCascadingCheckboxes_errorMessage {
            display: block;
        }

/* stylelint-disable */
.sysmexMoleculeCheckboxGroup_checkboxGroup .sysmexMoleculeCheckboxGroup_field {
        width: 58%;
        margin-bottom: 16px
    }
@media only screen and (min-width: 480px) {
    .sysmexMoleculeCheckboxGroup_checkboxGroup .sysmexMoleculeCheckboxGroup_field {
            width: auto
    }
        }
.sysmexMoleculeCheckboxGroup_checkboxGroup .sysmexMoleculeCheckboxGroup_field label {
            margin-right: 16px;
        }
@media only screen and (min-width: 1280px) {
        .sysmexMoleculeCheckboxGroup_checkboxGroup.sysmexMoleculeCheckboxGroup_colored .sysmexMoleculeCheckboxGroup_container {
                display: flex;
                flex-wrap: wrap
        }
            }
.sysmexMoleculeCheckboxGroup_checkboxGroup.sysmexMoleculeCheckboxGroup_colored .sysmexMoleculeCheckboxGroup_field {
            margin: 0 8px 8px 0;
        }
.sysmexMoleculeCheckboxGroup_checkboxGroup.sysmexMoleculeCheckboxGroup_colored .sysmexMoleculeCheckboxGroup_clearAll {
            margin-top: 0;
        }
.sysmexMoleculeCheckboxGroup_clearAll {
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
    color: #333333;
    text-transform: none;
    font-size: 14px;
    /* font-family: "Source Sans Pro", sans-serif; */

    margin-top: 8px;
    display: inline;
    cursor: pointer
}
.sysmexMoleculeCheckboxGroup_clearAll:hover {
        color: #00B8EE;
    }

/* stylelint-disable */
.sysmexMoleculeColoredTeaser_coloredTeaser {
    display: flex;
    flex-direction: column;
}
.sysmexMoleculeColoredTeaser_coloredTeaser img {
        display: block;
        -o-object-fit: cover;
           object-fit: cover;
        width: 100%;
        height: auto;
    }
.sysmexMoleculeColoredTeaser_coloredTeaser .sysmexMoleculeColoredTeaser_teaserTextWrapper {
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        position: relative;
        z-index: 1;
        width: calc(100% - 48px);
        padding: 24px 24px 64px;
        background-color: #005BAC;
        flex-grow: 1;
    }
.sysmexMoleculeColoredTeaser_coloredTeaser .sysmexMoleculeColoredTeaser_teaserTextWrapper .sysmexMoleculeColoredTeaser_title {
            text-transform: uppercase;
            margin-bottom: 32px;
            color: #FFFFFF;
        }
.sysmexMoleculeColoredTeaser_coloredTeaser .sysmexMoleculeColoredTeaser_teaserTextWrapper .sysmexMoleculeColoredTeaser_content {
            overflow: hidden;
        }
.sysmexMoleculeColoredTeaser_coloredTeaser .sysmexMoleculeColoredTeaser_teaserTextWrapper .sysmexMoleculeColoredTeaser_content p {
                color: #FFFFFF;
            }
.sysmexMoleculeColoredTeaser_coloredTeaser .sysmexMoleculeColoredTeaser_teaserTextWrapper .sysmexMoleculeColoredTeaser_content p:not(:last-child) {
                margin-bottom: 24px;
            }
.sysmexMoleculeColoredTeaser_coloredTeaser .sysmexMoleculeColoredTeaser_teaserTextWrapper .sysmexMoleculeColoredTeaser_content.sysmexMoleculeColoredTeaser_color-blue p {
                    color: #005BAC;
                }
.sysmexMoleculeColoredTeaser_coloredTeaser .sysmexMoleculeColoredTeaser_teaserTextWrapper.sysmexMoleculeColoredTeaser_bg-turquoise {
            background-color: #1CB5C8;
        }
.sysmexMoleculeColoredTeaser_coloredTeaser .sysmexMoleculeColoredTeaser_teaserTextWrapper.sysmexMoleculeColoredTeaser_bg-green {
            background-color: #AACD9B;
        }
.sysmexMoleculeColoredTeaser_coloredTeaser .sysmexMoleculeColoredTeaser_teaserTextWrapper.sysmexMoleculeColoredTeaser_bg-purple {
            background-color: #B38DB9;
        }
.sysmexMoleculeColoredTeaser_coloredTeaser .sysmexMoleculeColoredTeaser_teaserTextWrapper .sysmexMoleculeColoredTeaser_link-icon {
            position: absolute;
            right: 0;
            bottom: 0;
            border-radius: 0;
        }

/* stylelint-disable */
.sysmexMoleculeContentPopup_layer {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1500;
    background-color: rgba(0,0,0,0.6)
}
.sysmexMoleculeContentPopup_layer[data-popupopened="1"] {
        display: block
    }
.sysmexMoleculeContentPopup_layer[data-popupopened="1"][data-hasloadingspinner="1"] .sysmexMoleculeContentPopup_loadingSpinner {
                opacity: 1;
            }
.sysmexMoleculeContentPopup_layer[data-popupopened="1"][data-hasloadingspinner="0"] .sysmexMoleculeContentPopup_loadingSpinner {
                -webkit-animation: fade-out 0.5s both;
                        animation: fade-out 0.5s both;
            }
.sysmexMoleculeContentPopup_popup {
    position: relative;
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 72px 0;
    margin: 3vh auto 0
}
.sysmexMoleculeContentPopup_popup.sysmexMoleculeContentPopup_hasSpacing {
        padding: 32px 0;
    }
.sysmexMoleculeContentPopup_popup.sysmexMoleculeContentPopup_hasSpacing .sysmexMoleculeContentPopup_content {
            padding: 0 32px
        }
.sysmexMoleculeContentPopup_popup.sysmexMoleculeContentPopup_hasSpacing .sysmexMoleculeContentPopup_content::before {
                content: "";
                float: right;
                height: 32px;
                width: 24px;
            }
.sysmexMoleculeContentPopup_popup.sysmexMoleculeContentPopup_hasSpacing .sysmexMoleculeContentPopup_content .sysmexMoleculeContentPopup_close {
                position: absolute;
                top: calc(4px + 8px);
                right: 16px;
            }
@media only screen and (min-width: 768px) {
.sysmexMoleculeContentPopup_popup {
        width: auto;
        max-width: 90vw;
        margin-left: 50vw;
        margin-top: 50vh;
        transform: translateX(-50%) translateY(-50%)
}
    }
.sysmexMoleculeContentPopup_content {
    overflow-x: auto;
    height: calc(90vh - 16px - 24px);
}
.sysmexMoleculeContentPopup_content h1 {
        font-style: normal;
        font-weight: 400;
        line-height: 48px;
        margin-bottom: 24px;
        color: #333333;
        text-transform: none;
        font-size: 40px;
        /* font-family: "Source Sans Pro", sans-serif; */

        padding-bottom: 0;
        border-bottom: 0;
        max-width: calc(100% - 40px);
    }
.sysmexMoleculeContentPopup_content img {
        max-width: 100%;
        height: auto;
    }
@media only screen and (min-width: 768px) {
.sysmexMoleculeContentPopup_content {
        height: 100%;
        max-height: calc(90vh - 16px - 24px)
}
    }
.sysmexMoleculeContentPopup_content::-webkit-scrollbar {
        width: 8px;
    }
.sysmexMoleculeContentPopup_content::-webkit-scrollbar-track {
        background: #EDF3F9;
    }
.sysmexMoleculeContentPopup_content::-webkit-scrollbar-thumb {
        background: rgba(210,210,210,0.8);
    }
.sysmexMoleculeContentPopup_content::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
.sysmexMoleculeContentPopup_content .sysmexMoleculeContentPopup_loadingSpinner {
        opacity: 0;
        transition-timing-function: ease-in;
    }
.sysmexMoleculeContentPopup_content .sysmexMoleculeContentPopup_innerContent[data-fadeincontent="1"] {
            -webkit-animation: fade-in 1s both;
                    animation: fade-in 1s both;
            transition-timing-function: ease-in;
        }
.sysmexMoleculeContentPopup_close {
    position: absolute;
    top: 8px;
    right: calc(4px + 8px);
    cursor: pointer;
    z-index: 1;
}
.sysmexMoleculeContentPopup_close > i {
        font-size: 4rem;
    }
@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

/* stylelint-disable */
.sysmexMoleculeCookieConsent_background {
    position: static;
    max-height: 0;
    overflow: hidden;
    transition: all 1s;
    transform: none;
    background-color: #005BAC;
    z-index: 451;
    width: 100%
}
.sysmexMoleculeCookieConsent_background.sysmexMoleculeCookieConsent_active {
        max-height: 150px;
    }
@media only screen and (min-width: 992px) {
.sysmexMoleculeCookieConsent_background {
        position: fixed;
        bottom: 0;
        transform: translateY(200%)
}

        .sysmexMoleculeCookieConsent_background.sysmexMoleculeCookieConsent_active {
            max-height: 100px;
            transform: none;
        }
    }
.sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container {
        display: block;
        padding: 16px 0 24px 0;
        text-align: center;

        max-width: 1200px;

        width: 100%;

        margin-left: auto;

        margin-right: auto
    }
@media only screen and (min-width: 768px) {

    .sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container {
            padding: 24px 0
    }
        }
.sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_text {
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 14px;
            /* font-family: "Source Sans Pro", sans-serif; */

            display: block;
            color: #FFFFFF;
            font-size: 12px;
            line-height: 12px;
            margin: 0 24px 16px 24px
        }
@media only screen and (min-width: 1280px) {

        .sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_text {
                margin: 0 16px 0 0
        }
            }
.sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_text a {
                color: #FFFFFF;
                text-decoration: underline
            }
.sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_text a:hover {
                    color: #00B8EE;
                    text-decoration: none;
                }
.sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_buttons {
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 14px;
            /* font-family: "Source Sans Pro", sans-serif; */

            display: block;
            margin-left: auto;
            margin-bottom: 0;
            position: relative;
            left: 50%
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_buttons {
                position: static
        }
            }
.sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_buttons span {
                margin-right: 24px;
                color: #FFFFFF;
                cursor: pointer;
                white-space: nowrap;
                display: flex;
                flex-direction: row;
                align-items: center
            }
@media only screen and (min-width: 992px) {

            .sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_buttons span {
                    margin-right: 16px
            }
                }
.sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_buttons span:hover {
                    color: #00B8EE;
                }
.sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_buttons span i {
                    font-size: 32px;
                    padding-left: 8px
                }
.sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_buttons span i:hover {
                        text-decoration: none;
                    }
@media only screen and (min-width: 768px) {

    .sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container {
            display: flex;
            align-items: center;
            text-align: left;
            padding: 16px 0;
    }

            .sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_text,
            .sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_buttons {
                max-width: 100%; /* IE10 */
            }

            .sysmexMoleculeCookieConsent_background .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_text {
                padding-right: 80px;
            }
        }
.sysmexMoleculeCookieConsent_background.sysmexMoleculeCookieConsent_noMaxWidth .sysmexMoleculeCookieConsent_container {
            max-width: 100%;
        }
.sysmexMoleculeCookieConsent_background.sysmexMoleculeCookieConsent_noMaxWidth .sysmexMoleculeCookieConsent_container .sysmexMoleculeCookieConsent_buttons {
                margin-left: 0;
            }
.sysmexMoleculeCookieConsent_background.sysmexMoleculeCookieConsent_reversed-position {
        position: fixed;
        bottom: 0;
        transform: translateY(200%);
        max-height: none
    }
.sysmexMoleculeCookieConsent_background.sysmexMoleculeCookieConsent_reversed-position.sysmexMoleculeCookieConsent_active {
            transform: none;
        }
@media only screen and (min-width: 992px) {
.sysmexMoleculeCookieConsent_background.sysmexMoleculeCookieConsent_reversed-position {
            position: static;
            max-height: 0;
            overflow: hidden;
            transform: none;
            z-index: 451
    }

            .sysmexMoleculeCookieConsent_background.sysmexMoleculeCookieConsent_reversed-position.sysmexMoleculeCookieConsent_active {
                max-height: 150px;
            }
        }

/* stylelint-disable */
.sysmexMoleculeCookieOptin_wrapper {
    position: fixed;
    bottom: 0;
    z-index: 2000;
    display: none
}
.sysmexMoleculeCookieOptin_wrapper.sysmexMoleculeCookieOptin_active {
        display: block;
    }
.sysmexMoleculeCookieOptin_wrapper.sysmexMoleculeCookieOptin_details--open {
        top: 0;
        bottom: auto;
        background: #FFFFFF;

        /* https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
        height: calc(1vh * 100);
        height: calc(var(--sysmexMoleculeCookieOptin_Vh, 1vh) * 100);
    }
.sysmexMoleculeCookieOptin_wrapper.sysmexMoleculeCookieOptin_details--open .sysmexMoleculeCookieOptin_details {
            display: block;
        }
.sysmexMoleculeCookieOptin_wrapper.sysmexMoleculeCookieOptin_details--open .sysmexMoleculeCookieOptin_customize {
            display: none;
        }
.sysmexMoleculeCookieOptin_details {
    display: none;
}
.sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_inner {
        padding: 32px 24px 0 24px;
        height: calc(100vh - 260px);
        overflow-y: scroll;
    }
.sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_label {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        margin-bottom: 8px;
    }
.sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_description {
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 16px;
        /* font-family: "Source Sans Pro", sans-serif; */

        font-weight: 300;
        margin-bottom: 32px;
    }
.sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_header {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        background: rgba(0,91,172,0.82);
        height: 96px;
        align-items: center;
        padding: 0 24px;
        color: #FFFFFF;
        display: flex;
        justify-content: space-between;
    }
.sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_header .sysmexMoleculeCookieOptin_closeDetails {
            cursor: pointer;
            position: relative;
            right: -16px;
        }
.sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_detailsFooter {
        padding: 8px 24px 48px 24px;
        position: fixed;
        width: 100%;
        bottom: 0;
        box-sizing: border-box;
        background: #FFFFFF;
        border-top: 1px solid #D2D2D2;
    }
.sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_detailsFooter .sysmexMoleculeCookieOptin_metaNav {
            flex-direction: column-reverse;
        }
.sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_detailsFooter .sysmexMoleculeCookieOptin_metaNav .sysmexMoleculeCookieOptin_closeDetails {
                margin-bottom: 24px;
                width: 100%;
            }
.sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_detailsFooter .sysmexMoleculeCookieOptin_metaNav .sysmexMoleculeCookieOptin_cookiesOverviewLink {
                margin-right: 0;
            }
.sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_detailsFooter .sysmexMoleculeCookieOptin_metaNav .sysmexMoleculeCookieOptin_cookiesOverviewLink a {
                    color: #333333;
                }
.sysmexMoleculeCookieOptin_customize {
    background-color: #005BAC;
    padding: 32px 24px;
    text-align: center;
}
.sysmexMoleculeCookieOptin_customizeHeadline {
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 24px;
    color: #333333;
    text-transform: none;
    font-size: 27px;
    /* font-family: "Source Sans Pro", sans-serif; */

    color: #FFFFFF;
}
.sysmexMoleculeCookieOptin_customizeText {
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
    color: #333333;
    text-transform: none;
    font-size: 16px;
    /* font-family: "Source Sans Pro", sans-serif; */

    color: #FFFFFF;
    margin-bottom: 32px;
}
.sysmexMoleculeCookieOptin_customizeBtns {
    margin: 24px 0;
}
.sysmexMoleculeCookieOptin_btn {
    margin-bottom: 8px
}
.sysmexMoleculeCookieOptin_btn:last-child {
        margin-bottom: 0;
    }
.sysmexMoleculeCookieOptin_customizeAcceptAllBtn,
.sysmexMoleculeCookieOptin_customizeStatisticBtn,
.sysmexMoleculeCookieOptin_customizeEssentialBtn {
    border: 1px solid #00B8EE;
    background: transparent;
    color: #00B8EE;
}
.sysmexMoleculeCookieOptin_customizeEssentialInfoText,
.sysmexMoleculeCookieOptin_metaNav a,
.sysmexMoleculeCookieOptin_metaNav li {
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0;
    color: #333333;
    text-transform: none;
    font-size: 16px;
    /* font-family: "Source Sans Pro", sans-serif; */

    color: #00B8EE;
}
.sysmexMoleculeCookieOptin_customizeEssentialInfoText {
    margin-bottom: 24px;
}
.sysmexMoleculeCookieOptin_metaNav {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sysmexMoleculeCookieOptin_metaNav li {
        margin-right: 24px
    }
.sysmexMoleculeCookieOptin_metaNav li:last-child {
            margin-right: 0;
        }
.sysmexMoleculeCookieOptin_metaNav a {
        text-decoration: underline
    }
.sysmexMoleculeCookieOptin_metaNav a:active,
        .sysmexMoleculeCookieOptin_metaNav a:visited {
            color: #00B8EE;
            text-decoration: underline;
        }
@media only screen and (min-width: 992px) {
    .sysmexMoleculeCookieOptin_wrapper {
        width: 82%;
        left: 9%;
        bottom: 80px;
        height: auto;
        border-radius: 8px;
        overflow: hidden
    }

        .sysmexMoleculeCookieOptin_wrapper.sysmexMoleculeCookieOptin_details--open {
            bottom: 80px;
            height: auto;
            background: transparent;
            top: auto;
        }

            .sysmexMoleculeCookieOptin_wrapper.sysmexMoleculeCookieOptin_details--open .sysmexMoleculeCookieOptin_customize {
                display: block;
            }

            .sysmexMoleculeCookieOptin_wrapper.sysmexMoleculeCookieOptin_details--open .sysmexMoleculeCookieOptin_details {
                max-height: 1000px;
                transition: max-height 1s ease-in-out;
            }

            .sysmexMoleculeCookieOptin_wrapper.sysmexMoleculeCookieOptin_details--open .sysmexMoleculeCookieOptin_detailsOpener a {
                color: #FFFFFF;
            }

    .sysmexMoleculeCookieOptin_details {
        background-color: #005BAC;
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        display: block;
        position: relative;
    }

        .sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_inner {
            padding: 32px 120px;
            height: auto;
            overflow: visible;
        }

        .sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_label {
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #00B8EE;
            margin-bottom: 8px;
        }

        .sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_description {
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 14px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #00B8EE;
            margin-bottom: 32px;
        }

        .sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_header {
            display: none;
        }

        .sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_detailsFooter {
            position: relative;
            padding: 0 0 48px 0;
            height: auto;
            background: transparent;
            border: 0 none;
        }

            .sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_detailsFooter .sysmexMoleculeCookieOptin_metaNav {
                flex-direction: row;
            }

                .sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_detailsFooter .sysmexMoleculeCookieOptin_metaNav li:first-child::before {
                    display: none;
                }

                .sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_detailsFooter .sysmexMoleculeCookieOptin_metaNav .sysmexMoleculeCookieOptin_closeDetails {
                    color: #FFFFFF;
                    text-decoration: underline;
                    margin-bottom: 0;
                    background-color: transparent;
                    width: auto;
                }

                    .sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_detailsFooter .sysmexMoleculeCookieOptin_metaNav .sysmexMoleculeCookieOptin_closeDetails button {
                        padding: 0;
                        font-weight: 400;
                        text-decoration: underline;
                    }

                .sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_detailsFooter .sysmexMoleculeCookieOptin_metaNav .sysmexMoleculeCookieOptin_cookiesOverviewLink {
                    margin-right: 24px;
                }

                    .sysmexMoleculeCookieOptin_details .sysmexMoleculeCookieOptin_detailsFooter .sysmexMoleculeCookieOptin_metaNav .sysmexMoleculeCookieOptin_cookiesOverviewLink a {
                        color: #00B8EE;
                    }

    .sysmexMoleculeCookieOptin_customizeText {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #FFFFFF;
        max-width: 50%;
        margin: 0 auto;
    }

    .sysmexMoleculeCookieOptin_customizeBtns {
        display: flex;
        justify-content: center;
        margin: 32px 0;
    }

        .sysmexMoleculeCookieOptin_customizeBtns .sysmexMoleculeCookieOptin_btn {
            margin-bottom: 0;
            margin-right: 16px;
            width: auto
        }

            .sysmexMoleculeCookieOptin_customizeBtns .sysmexMoleculeCookieOptin_btn:last-child {
                margin-right: 0;
            }

    .sysmexMoleculeCookieOptin_customizeEssentialInfoText {
        margin-bottom: 0;
    }

    .sysmexMoleculeCookieOptin_customizeFooter {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .sysmexMoleculeCookieOptin_customizeHeadline {
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 24px;
        color: #333333;
        text-transform: none;
        font-size: 22px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #FFFFFF;
    }

    .sysmexMoleculeCookieOptin_customizeEssentialInfoText,
    .sysmexMoleculeCookieOptin_metaNav a,
    .sysmexMoleculeCookieOptin_metaNav li {
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 14px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #00B8EE;
    }

    .sysmexMoleculeCookieOptin_metaNav {
        margin-left: 24px;
    }

        .sysmexMoleculeCookieOptin_metaNav li {
            position: relative;
            padding-left: 16px
        }

            .sysmexMoleculeCookieOptin_metaNav li::before {
                content: "";
                height: 18px;
                position: absolute;
                width: 1px;
                left: -4px;
                background-color: rgba(237,243,249,0.6);
            }
}
@media only screen and (min-width: 992px) and (max-height: 700px) {
        .sysmexMoleculeCookieOptin_wrapper.sysmexMoleculeCookieOptin_details--open {
            max-height: 90vh;
            overflow-y: auto;
            top: 80px;
        }

            .sysmexMoleculeCookieOptin_wrapper.sysmexMoleculeCookieOptin_details--open .sysmexMoleculeCookieOptin_details {
                transition: max-height 1s ease-in-out;
            }
}
.styleguide .sysmexMoleculeCookieOptin_wrapper {
    display: block;
    height: 270px;
    position: relative;
    top: 0;
}

/* stylelint-disable */
.sysmexMoleculeDocumentDownload_documentDownload {
    margin-top: 32px;
    margin-bottom: 32px;
}
.sysmexMoleculeDocumentDownload_documentDownload .sysmexMoleculeDocumentDownload_file-download {
        display: flex;
        align-items: flex-start;
    }
.sysmexMoleculeDocumentDownload_documentDownload .sysmexMoleculeDocumentDownload_file-download .sysmexMoleculeDocumentDownload_icon {
            margin-right: 8px;
        }
.sysmexMoleculeDocumentDownload_documentDownload .sysmexMoleculeDocumentDownload_file-download .sysmexMoleculeDocumentDownload_file-download-title {
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            word-break: break-all;
        }
.sysmexMoleculeDocumentDownload_documentDownload .sysmexMoleculeDocumentDownload_file-download .sysmexMoleculeDocumentDownload_file-download-details {
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 14px;
            /* font-family: "Source Sans Pro", sans-serif; */
        }

/* stylelint-disable */
.sysmexMoleculeDownloadCard_downloadCard {
    background: #FFFFFF;
    padding: 24px;
    text-align: center;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.sysmexMoleculeDownloadCard_downloadCard .sysmexMoleculeDownloadCard_tags {
        margin: 8px 0 16px;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
.sysmexMoleculeDownloadCard_downloadCard .sysmexMoleculeDownloadCard_tags li {
            list-style: none;
            margin: 0 0.2em 0.4em;
            flex-shrink: 0;
        }
.sysmexMoleculeDownloadCard_downloadCard .sysmexMoleculeDownloadCard_tag {
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 14px;
        /* font-family: "Source Sans Pro", sans-serif; */

        display: inline-block;
        padding: 8px 24px;
        color: #FFFFFF;
    }
.sysmexMoleculeDownloadCard_downloadCard .sysmexMoleculeDownloadCard_title {
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 23px;
        /* font-family: "Source Sans Pro", sans-serif; */

        font-family: Sysmex, "Source Sans Pro", sans-serif;
        word-wrap: break-word;
    }
.sysmexMoleculeDownloadCard_downloadCard:not(.sysmexMoleculeDownloadCard_hasTags) .sysmexMoleculeDownloadCard_title {
            margin-bottom: 16px;
        }
.sysmexMoleculeDownloadCard_downloadCard .sysmexMoleculeDownloadCard_link {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        display: inline-block;
        margin-top: auto;
    }

/* stylelint-disable */
.sysmexMoleculeDownloadCardNew_downloadCardNew {
    background: #FFFFFF;
    padding: 24px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.sysmexMoleculeDownloadCardNew_downloadCardNew .sysmexMoleculeDownloadCardNew_tags {
        margin-bottom: 16px;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 2px;
        line-height: 1.6;
        text-transform: uppercase;
    }
.sysmexMoleculeDownloadCardNew_downloadCardNew .sysmexMoleculeDownloadCardNew_title {
        font-size: 20px;
        line-height: 28px;
        font-family: Sysmex, "Source Sans Pro", sans-serif;
        word-wrap: break-word;
    }
.sysmexMoleculeDownloadCardNew_downloadCardNew:not(.sysmexMoleculeDownloadCardNew_hasTags) .sysmexMoleculeDownloadCardNew_title {
            margin-bottom: 16px;
        }
.sysmexMoleculeDownloadCardNew_downloadCardNew .sysmexMoleculeDownloadCardNew_info {
        margin-top: auto;
        padding: 18px 0;
        font-weight: 300;
        font-size: 14px;
    }

/* stylelint-disable */
.sysmexMoleculeFlyoutLink_flyoutLink {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid rgba(0,0,0,0.1);
    border-color: transparent;
    border-radius: 36px 0 0 36px;
    transform: translateX(calc(28%));
    transition: all 0.5s;
    margin-top: 8px
}
.sysmexMoleculeFlyoutLink_flyoutLink:hover {
        transform: translateX(0);
        background-color: #FFFFFF;
        border-color: rgba(0,0,0,0.1);
    }
.sysmexMoleculeFlyoutLink_flyoutLink a {
        padding: 8px 16px;

        font-style: normal;

        font-weight: 600;

        line-height: 24px;

        margin-bottom: 0;

        color: #333333;

        text-transform: none;

        font-size: 18px;

        /* font-family: "Source Sans Pro", sans-serif; */

        color: #000000
    }
.sysmexMoleculeFlyoutLink_flyoutLink a:hover {
            color: #00B8EE;
        }
.sysmexMoleculeFlyoutLink_flyoutLink a:not(:last-child) {
            border-right: 1px solid rgba(0,0,0,0.1);
        }
.sysmexMoleculeFlyoutLink_flyoutLink .sysmexMoleculeFlyoutLink_icon {
        font-size: 4rem;
        margin: 1px;
        box-sizing: border-box;
    }

/* stylelint-disable */
.sysmexMoleculeFlyoutLinkContainer_flyoutLinkContainer {
    display: none;
    position: fixed;
    right: 0;
    bottom: 50%;
    z-index: 100
}
@media only screen and (min-width: 768px) {
.sysmexMoleculeFlyoutLinkContainer_flyoutLinkContainer {
        display: block
}
    }

/* stylelint-disable */
.sysmexMoleculeFooterMeta_footerMeta {
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
    color: #333333;
    text-transform: none;
    font-size: 14px;
    /* font-family: "Source Sans Pro", sans-serif; */

    background: #FFFFFF;
    position: relative;
}
.sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_bot {
        height: 56px;
        background: rgba(210,210,210,0.1);
        display: flex;
        text-align: center;
        align-items: center;
    }
.sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_navigation i {
            display: none;
        }
.sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_desktop-social {
        display: none;
    }
.sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_mobile-social {
        margin: 40px 0;
    }
.sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_desktop-navigation {
        display: none;
    }
.sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_copyrightText {
        padding-left: 24px;
        text-align: left
    }
@media only screen and (min-width: 1280px) {

    .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_copyrightText {
            width: 100%;
            padding-left: 0
    }
        }
.sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_contentWrapper {
        max-width: 1200px;
        width: auto;
        margin-left: 0;
        margin-right: 0;

        margin-left: 24px;
        margin-right: 24px;
    }
.sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_wrapper {
        width: 100%;
    }
@media only screen and (min-width: 992px) {
        .sysmexMoleculeFooterMeta_footerMeta.sysmexMoleculeFooterMeta_hasBorderTop {
            border-top: 1px solid #D2D2D2;
        }

        .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_top {
            height: 56px;
            background: #FFFFFF;
            display: flex;
            justify-content: center;
        }

        .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_bot {
            height: 48px;
        }

        .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_wrapper {
            margin: 0 auto;
            position: relative;
        }

        .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_hasMaxWidth {
            max-width: 1200px;
        }

        .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_mobile-social {
            display: none;
        }

        .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_desktop-social {
            display: block;
            position: absolute;
            top: -8px;
            right: 0;
        }

        .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_desktop-navigation {
            display: block;
        }

        .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_mobile-navigation {
            display: none;
        }

        .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_navigation {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

            .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_navigation li {
                margin-right: 24px;
                position: relative;
                height: 24px;
                display: flex;
                border-left: 1px solid #D2D2D2;
                padding-left: 24px
            }

                .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_navigation li:last-child {
                    border-right: 1px solid #D2D2D2;
                    padding-right: 24px;
                }

                .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_navigation li.sysmexMoleculeFooterMeta_hasIcon {
                    padding-right: 8px;
                }

                .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_navigation li a {
                    display: flex;
                    align-items: center;
                    position: relative
                }

                    .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_navigation li a:hover {
                        color: #00B8EE;
                    }

                .sysmexMoleculeFooterMeta_footerMeta .sysmexMoleculeFooterMeta_navigation li i {
                    display: block;
                    color: #005BAC;
                    font-size: 32px;
                    margin-left: 16px;
                }
    }

/* stylelint-disable */
.sysmexMoleculeFormField_formField {
    margin-bottom: 16px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}
.sysmexMoleculeFormField_formField .sysmexMoleculeFormField_header.sysmexMoleculeFormField_has--description {
            width: calc(100% - 48px);
        }
.sysmexMoleculeFormField_formField.sysmexMoleculeFormField_is--centered .sysmexMoleculeFormField_container {
            max-width: 300px
        }
@media only screen and (min-width: 768px) {
        .sysmexMoleculeFormField_formField.sysmexMoleculeFormField_is--centered .sysmexMoleculeFormField_container {
                min-width: 320px;
                max-width: 500px
        }
            }
@media only screen and (min-width: 992px) {
        .sysmexMoleculeFormField_formField.sysmexMoleculeFormField_is--centered .sysmexMoleculeFormField_container {
                max-width: 600px
        }
            }
@media only screen and (min-width: 1280px) {
        .sysmexMoleculeFormField_formField.sysmexMoleculeFormField_is--centered .sysmexMoleculeFormField_container {
                max-width: unset
        }
            }
.sysmexMoleculeFormField_formField .sysmexMoleculeFormField_label {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        display: block;
        margin-bottom: 8px;
    }
.sysmexMoleculeFormField_formField .sysmexMoleculeFormField_errorMessage {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #E7413E;
        display: none;
        margin-top: -5px;
        margin-bottom: 5px;
    }
.sysmexMoleculeFormField_formField.sysmexMoleculeFormField_error label {
            color: #E7413E;
        }
.sysmexMoleculeFormField_formField.sysmexMoleculeFormField_error .sysmexMoleculeFormField_errorMessage {
            display: block;
        }
.sysmexMoleculeFormField_formField .sysmexMoleculeFormField_labelWrap {
        display: flex;
        justify-content: space-between;
    }
.sysmexMoleculeFormField_formField .sysmexMoleculeFormField_infoLink {
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 14px;
        /* font-family: "Source Sans Pro", sans-serif; */

        text-decoration: underline;
        line-height: unset
    }
.sysmexMoleculeFormField_formField .sysmexMoleculeFormField_infoLink:hover {
            color: #00B8EE;
        }
.sysmexMoleculeFormField_formField.sysmexMoleculeFormField_conditional {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.3s;
    }
.sysmexMoleculeFormField_formField.sysmexMoleculeFormField_conditional .sysmexMoleculeFormField_inner {
            overflow: hidden;
        }
.sysmexMoleculeFormField_formField.sysmexMoleculeFormField_conditional-show {
        grid-template-rows: 1fr;
    }
.sysmexMoleculeFormField_formField fieldset[data-condition-field] {
        padding: 0;
        margin: 0;
        border-width: 0;
    }
.sysmexMoleculeFormField_formField fieldset[data-condition-field] legend {
            display: none;
        }

/* stylelint-disable */
.sysmexMoleculeGallerySlider_gallerySlider {
    width: auto;
    position: relative;
    max-width: 1440px;
    overflow-x: hidden;
    flex: 1 0 auto
}
@media only screen and (min-width: 1340px) {
.sysmexMoleculeGallerySlider_gallerySlider {
        overflow-x: visible
}
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-outer {
        padding: 0 !important;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-outer [hidden] {
        display: none !important;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-outer > button {
        display: none;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-outer [aria-controls],
    .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-outer [data-action] {
        cursor: pointer;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-slider {
        transition: all 0s;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-slider > .sysmexMoleculeGallerySlider_tns-item {
        box-sizing: border-box;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-horizontal {
        display: flex;
        align-items: center;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-horizontal.sysmexMoleculeGallerySlider_tns-subpixel {
        white-space: nowrap;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-horizontal.sysmexMoleculeGallerySlider_tns-subpixel > .sysmexMoleculeGallerySlider_tns-item {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-horizontal.sysmexMoleculeGallerySlider_tns-no-subpixel::after {
        content: '';
        display: table;
        clear: both;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-horizontal.sysmexMoleculeGallerySlider_tns-no-subpixel > .sysmexMoleculeGallerySlider_tns-item {
        float: left;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-horizontal.sysmexMoleculeGallerySlider_tns-carousel.sysmexMoleculeGallerySlider_tns-no-subpixel > .sysmexMoleculeGallerySlider_tns-item {
        margin-right: -100%;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-no-calc {
        position: relative;
        left: 0;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-gallery {
        position: relative;
        left: 0;
        min-height: 1px;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-gallery > .sysmexMoleculeGallerySlider_tns-item {
        position: absolute;
        left: -100%;
        transition: transform 0s, opacity 0s;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-gallery > .sysmexMoleculeGallerySlider_tns-slide-active {
        position: relative;
        left: auto !important;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-gallery > .sysmexMoleculeGallerySlider_tns-moving {
        transition: all 0.25s;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-autowidth {
        display: inline-block;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-lazy-img {
        transition: opacity 0.6s;
        opacity: 0.6;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-lazy-img.sysmexMoleculeGallerySlider_tns-complete {
        opacity: 1;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-ah {
        transition: height 0s;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-ovh {
        overflow: hidden;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-visually-hidden {
        position: absolute;
        left: -10000em;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-transparent {
        opacity: 0;
        visibility: hidden;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-fadeIn {
        opacity: 1;
        filter: alpha(opacity=100);
        z-index: 0;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-normal,
    .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-fadeOut {
        opacity: 0;
        filter: alpha(opacity=0);
        z-index: -1;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-vpfix {
        white-space: nowrap;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-vpfix > div,
    .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-vpfix > li {
        display: inline-block;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-t-subp2 {
        margin: 0 auto;
        width: 310px;
        position: relative;
        height: 10px;
        overflow: hidden;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-t-ct {
        width: 2333.3333%;
        width: calc(100% * 70 / 3);
        position: absolute;
        right: 0;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-t-ct::after {
        content: '';
        display: table;
        clear: both;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_tns-t-ct > div {
        width: 1.4286%;
        width: calc(100% / 70);
        height: 10px;
        float: left;
    }
/* Buttons Start */
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_carousel-prev,
    .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_carousel-next {
        -webkit-user-select: none;
           -moz-user-select: none;
                user-select: none;
        outline: none;
        position: absolute;
        z-index: 2;
        text-decoration: none;
        text-shadow: 1px 1px 3px rgba(150, 150, 150, 1);
        left: -24px;
        border: 0;
        top: calc(50% - 50px);
        cursor: pointer;
        color: #fff;
        opacity: 1;
        line-height: 1;
        transition:
            opacity 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67), color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67)
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_carousel-prev:active, .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_carousel-next:active {
            color: #595959;
        }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_carousel-prev i, .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_carousel-next i {
            font-size: 100px;
        }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_carousel-next {
        right: -24px;
        left: auto;
    }
/* Thumb Buttons Start */
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_thumb-carousel-prev,
    .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_thumb-carousel-next {
        -webkit-user-select: none;
           -moz-user-select: none;
                user-select: none;
        outline: none;
        position: absolute;
        z-index: 2;
        text-decoration: none;
        text-shadow: 1px 1px 3px rgba(150, 150, 150, 1);
        top: -9px;
        border: 0;
        left: calc(50% - 28px);
        cursor: pointer; /* not in hover state? */
        color: #fff;
        opacity: 1;
        line-height: 1;
        transition:
            opacity 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67), color 0.5s cubic-bezier(0.17, 0.67, 0.83, 0.67)
    }
/* Buttons End */
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_thumb-carousel-prev:active, .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_thumb-carousel-next:active {
            color: #595959;
        }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_thumb-carousel-prev i, .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_thumb-carousel-next i {
            font-size: 50px;
        }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_thumb-carousel-next {
        top: auto;
        bottom: 0;
    }
/* Thumb Buttons End */
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_carousel-next[aria-disabled='true'],
    .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_carousel-prev[aria-disabled='true'],
    .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_thumb-carousel-next[aria-disabled='true'],
    .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_thumb-carousel-prev[aria-disabled='true'] {
        opacity: 0.25;
        color: #666;
        cursor: default;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_gallery-wrapper {
        display: flex;
    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_gallery-wrapper .sysmexMoleculeGallerySlider_thumbnavi-wrapper {
            position: relative;
            overflow: hidden;
            height: 520px;
            display: none;
            flex-shrink: 0
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_gallery-wrapper .sysmexMoleculeGallerySlider_thumbnavi-wrapper {
                display: block
        }
            }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_gallery-wrapper .sysmexMoleculeGallerySlider_thumbnavi-wrapper .sysmexMoleculeGallerySlider_gallery-thumbnavi {
                width: 100px;
                margin-right: 8px;
            }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_gallery-wrapper .sysmexMoleculeGallerySlider_thumbnavi-wrapper .sysmexMoleculeGallerySlider_gallery-thumbnavi div {
                    opacity: 0.6;
                    transition: opacity 0.3s;
                    background-image: (/typo3conf/ext/sitepackage/Resources/Public/Images/Frontend/hero--products.png);
                    background-size: cover;
                    background-position: center;
                }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_gallery-wrapper .sysmexMoleculeGallerySlider_thumbnavi-wrapper .sysmexMoleculeGallerySlider_gallery-thumbnavi div img {
                        width: 100px;
                        height: 100px;

                        /* change back to cover if non-square images need to be uploaded. Or set special class to image if image is transparent product image. TBD */
                        -o-object-fit: contain;
                           object-fit: contain;
                        display: block;
                    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_gallery-wrapper .sysmexMoleculeGallerySlider_thumbnavi-wrapper .sysmexMoleculeGallerySlider_gallery-thumbnavi div:hover {
                        cursor: pointer;
                    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_gallery-wrapper .sysmexMoleculeGallerySlider_thumbnavi-wrapper .sysmexMoleculeGallerySlider_gallery-thumbnavi div.sysmexMoleculeGallerySlider_is--current {
                        opacity: 1;
                    }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_gallery-wrapper .sysmexMoleculeGallerySlider_carousel-wrapper {
            position: relative;
            width: 100%;
            overflow: hidden;
            /* stylelint-disable-next-line function-url-quotes */
            background-image: url(/typo3conf/ext/sitepackage/Resources/Public/Images/Frontend/hero--products.png);
            background-size: cover;
            background-position: center;
        }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_gallery-wrapper .sysmexMoleculeGallerySlider_carousel-wrapper .sysmexMoleculeGallerySlider_slides-info {
                font-style: normal;
                font-weight: 400;
                line-height: 12px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 10px;
                /* font-family: "Source Sans Pro", sans-serif; */

                background-color: rgba(0, 0, 0, 0.8);
                color: #FFFFFF;
                height: 20px;
                line-height: 20px;
                text-align: center;
            }
.sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_gallery-wrapper .sysmexMoleculeGallerySlider_carousel-wrapper img {
                display: block;
                width: 100%;
                height: auto;
                margin: 0 auto;
                max-height: 500px;

                /* change back to cover if non-square images need to be uploaded. Or set special class to image if image is transparent product image. TBD */
                -o-object-fit: contain;
                   object-fit: contain
            }
@media only screen and (min-width: 768px) {

            .sysmexMoleculeGallerySlider_gallerySlider .sysmexMoleculeGallerySlider_gallery-wrapper .sysmexMoleculeGallerySlider_carousel-wrapper img {
                    width: 500px;
                    height: 500px
            }
                }

/* stylelint-disable */
.sysmexMoleculeGraphic3D_graphic3D {
    position: relative;
    margin: 0;
}
.sysmexMoleculeGraphic3D_graphic3D .sysmexMoleculeGraphic3D_container {
        cursor: -webkit-grab;
        cursor: grab
    }
.sysmexMoleculeGraphic3D_graphic3D .sysmexMoleculeGraphic3D_container.sysmexMoleculeGraphic3D_background-white {
            background-color: #FFFFFF;
        }
.sysmexMoleculeGraphic3D_graphic3D .sysmexMoleculeGraphic3D_container.sysmexMoleculeGraphic3D_background-blue {
            background-color: rgba(0,91,172,0.1);
        }
.sysmexMoleculeGraphic3D_graphic3D .sysmexMoleculeGraphic3D_container.sysmexMoleculeGraphic3D_noMobileControls {
            pointer-events: none
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeGraphic3D_graphic3D .sysmexMoleculeGraphic3D_container.sysmexMoleculeGraphic3D_noMobileControls {
                pointer-events: all
        }
            }
.sysmexMoleculeGraphic3D_graphic3D .sysmexMoleculeGraphic3D_container:active {
            cursor: -webkit-grabbing;
            cursor: grabbing;
        }
.sysmexMoleculeGraphic3D_graphic3D .sysmexMoleculeGraphic3D_loader {
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 24px;
        color: #333333;
        text-transform: none;
        font-size: 22px;
        /* font-family: "Source Sans Pro", sans-serif; */

        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        color: #005BAC;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        background-color: #D2D2D2
    }
.sysmexMoleculeGraphic3D_graphic3D .sysmexMoleculeGraphic3D_loader.sysmexMoleculeGraphic3D_is--active {
            display: flex;
        }
.sysmexMoleculeGraphic3D_graphic3D .sysmexMoleculeGraphic3D_loader {

        @-webkit-keyframes pulse {
            0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(0, 91, 172, 0.7);
            }

            70% {
                transform: scale(1);
                box-shadow: 0 0 0 10px rgba(0, 91, 172, 0);
            }

            100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(0, 91, 172, 0);
            }
        }
    }
.sysmexMoleculeGraphic3D_graphic3D .sysmexMoleculeGraphic3D_loader .sysmexMoleculeGraphic3D_loader-inner::after {
                content: '';
                display: block;
                background: #005BAC;
                border-radius: 50%;
                margin: 10px auto;
                height: 20px;
                width: 20px;
                box-shadow: 0 0 0 0 rgba(0, 91, 172, 1);
                transform: scale(1);
                -webkit-animation: pulse 2s infinite;
                        animation: pulse 2s infinite;
            }
@-webkit-keyframes pulse {
            0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(0, 91, 172, 0.7);
            }

            70% {
                transform: scale(1);
                box-shadow: 0 0 0 10px rgba(0, 91, 172, 0);
            }

            100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(0, 91, 172, 0);
            }
        }
@keyframes pulse {
            0% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(0, 91, 172, 0.7);
            }

            70% {
                transform: scale(1);
                box-shadow: 0 0 0 10px rgba(0, 91, 172, 0);
            }

            100% {
                transform: scale(0.95);
                box-shadow: 0 0 0 0 rgba(0, 91, 172, 0);
            }
        }
.sysmexMoleculeGraphic3D_graphic3D .sysmexMoleculeGraphic3D_caption {
        font-weight: 300;
        margin-top: 0
    }
@media only screen and (min-width: 480px) {

    .sysmexMoleculeGraphic3D_graphic3D .sysmexMoleculeGraphic3D_caption {
            text-align: center
    }
        }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeGraphic3D_graphic3D .sysmexMoleculeGraphic3D_caption {
            text-align: left
    }
        }

/* stylelint-disable */
.sysmexMoleculeHero_hero {
    position: relative;
    height: 370px;
    -webkit-font-smoothing: antialiased
}
@media only screen and (min-width: 992px) {
        .sysmexMoleculeHero_hero.sysmexMoleculeHero_isMySysmex {
            height: 605px;
        }

            .sysmexMoleculeHero_hero.sysmexMoleculeHero_isMySysmex .sysmexMoleculeHero_inner .sysmexMoleculeHero_left {
                padding-bottom: 155px;
            }
    }
.sysmexMoleculeHero_hero .sysmexMoleculeHero_heroBackground {
        background: linear-gradient(84.16deg, #005bac 37.95%, #1bbbe9 100%);
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
.sysmexMoleculeHero_hero .sysmexMoleculeHero_inner {
        padding-top: 56px
    }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeHero_hero .sysmexMoleculeHero_inner {
            padding-top: 72px;
            display: flex;
    }

            .sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_left,
            .sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_right {
                flex-basis: 50%;
            }

            .sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_left {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding-bottom: 80px;
            }
        }
.sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_title {
            margin-bottom: 24px;
            font-size: 26px;
            font-family: Sysmex, "Source Sans Pro", sans-serif
        }
@media only screen and (min-width: 992px) {

        .sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_title {
                font-size: 36px;
                line-height: 42px;
                font-weight: 600;
                margin-bottom: 0
        }
            }
.sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_description {
            margin-bottom: 40px
        }
@media only screen and (min-width: 992px) {

        .sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_description {
                font-size: 28px;
                line-height: 32px;
                margin-bottom: 56px
        }
            }
.sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_title,
        .sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_description,
        .sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_sectionLabel {
            color: #FFFFFF;
            font-weight: 600;
            max-width: 450px;
            display: block;
        }
@media only screen and (min-width: 992px) {

        .sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_sectionLabel {
                font-size: 34px;
                line-height: 40px
        }
            }
.sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_dashboardAction {
            border: 1px solid;
            width: auto
        }
@media only screen and (min-width: 992px) {

        .sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_dashboardAction {
                padding: 16px 24px 16px 32px;
                font-size: 20px
        }
            }
.sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_dashboardAction i {
                font-size: 2rem;
            }
.sysmexMoleculeHero_hero .sysmexMoleculeHero_inner .sysmexMoleculeHero_dashboardAction ~ .sysmexMoleculeHero_sectionLabel {
                font-size: 22px;
                line-height: 26px;
                padding-top: 64px;
            }

/* stylelint-disable */
/* Better completely rewrite this component when possible */
.sysmexMoleculeHeroTeaser_heroTeaser {
    position: relative;
    margin: 0 auto
}
.sysmexMoleculeHeroTeaser_heroTeaser.sysmexMoleculeHeroTeaser_isHiddenInMobile {
        display: none;
    }
@media only screen and (min-width: 768px) {
.sysmexMoleculeHeroTeaser_heroTeaser {
        height: auto;
        overflow: hidden
}

        .sysmexMoleculeHeroTeaser_heroTeaser.sysmexMoleculeHeroTeaser_isHiddenInMobile {
            display: block;
        }
    }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_background {
        position: relative;
        z-index: 0;
        height: 100%;
    }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_background .sysmexMoleculeHeroTeaser_backgroundImage {
            height: 100%;
            max-width: 100%;
            overflow: hidden;
        }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_background .sysmexMoleculeHeroTeaser_backgroundImage img {
                display: block;
                width: 100%;
                margin-bottom: -3px;
            }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_background.sysmexMoleculeHeroTeaser_video {
            position: relative;
            padding-bottom: 80.25%;
            padding-top: 0;
            height: 0;
            overflow: hidden
        }
@media only screen and (min-width: 992px) {

        .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_background.sysmexMoleculeHeroTeaser_video {
                padding-bottom: 40.25%
        }
            }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_background.sysmexMoleculeHeroTeaser_video iframe {
                border: none;
                position: absolute;
                top: 0;
                left: 0;
                width: 198%;
                height: 100%;
                transform: translateX(-25%)
            }
@media only screen and (min-width: 992px) {

            .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_background.sysmexMoleculeHeroTeaser_video iframe {
                    width: 100%;
                    transform: translateX(0)
            }
                }
@media only screen and (min-width: 768px) {

    .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_background {
            height: auto;
    }

            .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_background .sysmexMoleculeHeroTeaser_backgroundImage {
                height: auto;
                max-width: none;
                overflow: visible;
            }

                .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_background .sysmexMoleculeHeroTeaser_backgroundImage img {
                    width: 100%;
                    height: auto;
                    margin-bottom: 0;
                }
        }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground {
        max-width: 1200px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        position: absolute;
        z-index: 1;
        top: 49%;
        bottom: auto;
        transform: translateY(-50%);
        min-width: 100%;
    }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_foreground__content .sysmexMoleculeHeroTeaser_foreground__contenttext {
                display: flex;
                flex-direction: column;
            }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_foreground__content .sysmexMoleculeHeroTeaser_title,
            .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_foreground__content .sysmexMoleculeHeroTeaser_subtitle {
                font-style: get(fontStyles_heading-hero-fluid_font-style);
                font-weight: 400;
                line-height: 1.2;
                margin-bottom: 0;
                color: #333333;
                text-transform: get(fontStyles_heading-hero-fluid_text-transform);
                font-size: clamp(2rem,4vw,4rem);
                /* font-family: "Source Sans Pro", sans-serif; */

                font-family: Sysmex, "Source Sans Pro", sans-serif;
                margin: 0;
                padding: 0;
            }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_foreground__content .sysmexMoleculeHeroTeaser_linktext {
                font-style: get(fontStyles_link-hero-fluid_font-style);
                font-weight: 400;
                line-height: 1.2;
                margin-bottom: 0;
                color: #333333;
                text-transform: get(fontStyles_link-hero-fluid_text-transform);
                font-size: clamp(1.6rem,3.2vw,3.2rem);
                /* font-family: "Source Sans Pro", sans-serif; */

                font-family: Sysmex, "Source Sans Pro", sans-serif;
                margin-top: 12px;
            }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_foreground__content {
                padding: 0 24px;
                text-align: left;
                position: relative
        }
            }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_foreground__content.sysmexMoleculeHeroTeaser_hasBox {
                background-color: rgba(0,184,238,0.8);
                box-sizing: border-box;
                width: 45%;
            }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_foreground__content.sysmexMoleculeHeroTeaser_hasBox .sysmexMoleculeHeroTeaser_title,
                .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_foreground__content.sysmexMoleculeHeroTeaser_hasBox .sysmexMoleculeHeroTeaser_subtitle {
                    font-style: normal;
                    font-weight: 100;
                    line-height: 36px;
                    margin-bottom: 24px;
                    color: #333333;
                    text-transform: none;
                    font-size: 28px;
                    /* font-family: "Source Sans Pro", sans-serif; */

                    color: #FFFFFF;
                    margin-bottom: 8px;
                }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_foreground__content.sysmexMoleculeHeroTeaser_hasBox .sysmexMoleculeHeroTeaser_linktext {
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    margin-bottom: 0;
                    color: #333333;
                    text-transform: uppercase;
                    font-size: 18px;
                    /* font-family: "Source Sans Pro", sans-serif; */

                    color: #FFFFFF;
                    margin-top: 12px;
                }
@media only screen and (min-width: 992px) {

            .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_foreground__content.sysmexMoleculeHeroTeaser_hasBox {
                    width: 33.4%
            }
                }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_dark .sysmexMoleculeHeroTeaser_title,
            .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_dark .sysmexMoleculeHeroTeaser_subtitle,
            .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_dark .sysmexMoleculeHeroTeaser_linktext {
                color: #333333;
            }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_bright .sysmexMoleculeHeroTeaser_title,
            .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_bright .sysmexMoleculeHeroTeaser_subtitle,
            .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground .sysmexMoleculeHeroTeaser_bright .sysmexMoleculeHeroTeaser_linktext {
                color: #FFFFFF;
            }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground.sysmexMoleculeHeroTeaser_isCenter .sysmexMoleculeHeroTeaser_foreground__content {
                text-align: center;
                padding: 8px 24px
            }
@media only screen and (min-width: 992px) {
            .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground.sysmexMoleculeHeroTeaser_isCenter .sysmexMoleculeHeroTeaser_foreground__content {
                    left: 50%;
                    transform: translateX(-50%);
                    padding: 32px 64px 40px
            }
                }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground.sysmexMoleculeHeroTeaser_isRight .sysmexMoleculeHeroTeaser_foreground__content {
                left: 100%;
                transform: translateX(-100%);
                position: relative;
                text-align: right;
                padding: 8px 24px
            }
@media only screen and (min-width: 992px) {
            .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground.sysmexMoleculeHeroTeaser_isRight .sysmexMoleculeHeroTeaser_foreground__content {
                    padding: 32px 96px 40px 56px
            }
                }
@media only screen and (min-width: 480px) {
            .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground.sysmexMoleculeHeroTeaser_isRight .sysmexMoleculeHeroTeaser_foreground__content {
                    max-width: 50%
            }
                }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground.sysmexMoleculeHeroTeaser_isRight .sysmexMoleculeHeroTeaser_hasBox {
                text-align: left;
            }
.sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground.sysmexMoleculeHeroTeaser_isLeft .sysmexMoleculeHeroTeaser_foreground__content {
                left: 0;
                transform: translateX(0);
                position: relative;
                text-align: left;
                padding: 8px 24px
            }
@media only screen and (min-width: 992px) {
            .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground.sysmexMoleculeHeroTeaser_isLeft .sysmexMoleculeHeroTeaser_foreground__content {
                    padding: 32px 96px 40px
            }
                }
@media only screen and (min-width: 480px) {
            .sysmexMoleculeHeroTeaser_heroTeaser .sysmexMoleculeHeroTeaser_foreground.sysmexMoleculeHeroTeaser_isLeft .sysmexMoleculeHeroTeaser_foreground__content {
                    max-width: 50%
            }
                }
.sysmexMoleculeHeroTeaser_heroTeaserS .sysmexMoleculeHeroTeaser_foreground {
        top: 49%;
    }

/* stylelint-disable */
.sysmexMoleculeIframe_iFrame {
    position: relative;
}
.sysmexMoleculeIframe_iFrame .sysmexMoleculeIframe_frameContainer {
        /* Default values. Defined in CSS for easy override. */
        width: 100%;
        height: 300px;
        min-height: 0;
        max-height: none;
        min-width: 0;
        max-width: none;
        overflow: hidden;
        z-index: 20
    }
@media only screen and (max-width: 1023px) {

        .sysmexMoleculeIframe_iFrame .sysmexMoleculeIframe_frameContainer.sysmexMoleculeIframe_mobileLarge {
                width: 100% !important; /* must override inline width */
                height: 80vh !important /* must override inline height */
        }
            }
.sysmexMoleculeIframe_iFrame .sysmexMoleculeIframe_frameContainer.sysmexMoleculeIframe_centered {
            margin: 0 auto;
        }
.sysmexMoleculeIframe_iFrame .sysmexMoleculeIframe_frameContainer .sysmexMoleculeIframe_iFrameWindow {
            border: none;
            width: 100%;
            height: 100%;
        }
.sysmexMoleculeIframe_iFrame .sysmexMoleculeIframe_controls {
        position: absolute;
        z-index: 21;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
    }

/* stylelint-disable */
@-webkit-keyframes lum-noop {
    0% { zoom: 1; }
}
@keyframes lum-noop {
    0% { zoom: 1; }
}
@-webkit-keyframes lum-fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes lum-fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@-webkit-keyframes lum-fade-zoom {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes lum-fade-zoom {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@-webkit-keyframes lum-loader-rotate {
    0% {
        transform: translate(-50%, -50%) rotate(0);
    }

    50% {
        transform: translate(-50%, -50%) rotate(-180deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}
@keyframes lum-loader-rotate {
    0% {
        transform: translate(-50%, -50%) rotate(0);
    }

    50% {
        transform: translate(-50%, -50%) rotate(-180deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}
@-webkit-keyframes lum-loader-before {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.2) translateX(6px);
    }

    25% {
        transform: scale(1.3) translateX(8px);
    }

    40% {
        transform: scale(1.2) translateX(6px);
    }

    50% {
        transform: scale(1);
    }

    60% {
        transform: scale(0.8) translateX(6px);
    }

    75% {
        transform: scale(0.7) translateX(8px);
    }

    90% {
        transform: scale(0.8) translateX(6px);
    }

    100% {
        transform: scale(1);
    }
}
@keyframes lum-loader-before {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.2) translateX(6px);
    }

    25% {
        transform: scale(1.3) translateX(8px);
    }

    40% {
        transform: scale(1.2) translateX(6px);
    }

    50% {
        transform: scale(1);
    }

    60% {
        transform: scale(0.8) translateX(6px);
    }

    75% {
        transform: scale(0.7) translateX(8px);
    }

    90% {
        transform: scale(0.8) translateX(6px);
    }

    100% {
        transform: scale(1);
    }
}
@-webkit-keyframes lum-loader-after {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.2) translateX(-6px);
    }

    25% {
        transform: scale(1.3) translateX(-8px);
    }

    40% {
        transform: scale(1.2) translateX(-6px);
    }

    50% {
        transform: scale(1);
    }

    60% {
        transform: scale(0.8) translateX(-6px);
    }

    75% {
        transform: scale(0.7) translateX(-8px);
    }

    90% {
        transform: scale(0.8) translateX(-6px);
    }

    100% {
        transform: scale(1);
    }
}
@keyframes lum-loader-after {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.2) translateX(-6px);
    }

    25% {
        transform: scale(1.3) translateX(-8px);
    }

    40% {
        transform: scale(1.2) translateX(-6px);
    }

    50% {
        transform: scale(1);
    }

    60% {
        transform: scale(0.8) translateX(-6px);
    }

    75% {
        transform: scale(0.7) translateX(-8px);
    }

    90% {
        transform: scale(0.8) translateX(-6px);
    }

    100% {
        transform: scale(1);
    }
}
.sysmexMoleculeImageLightbox_galleryWrapper figure a {
        display: block;
        position: relative
    }
.sysmexMoleculeImageLightbox_galleryWrapper figure a::before {
            display: block;
            position: absolute;
            /* stylelint-disable font-family-name-quotes */
            font-family: 'sysmexicons', sans-serif !important;
            /* stylelint-enable font-family-name-quotes */
            content: "\ea2e";
            font-size: 3em;
            bottom: 0;
            right: 0;
            background-color: rgba(0,0,0,0.8);
            color: #FFFFFF;
            line-height: 1em;
        }
.sysmexMoleculeImageLightbox_lum-lightbox {
    position: fixed;
    display: none;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000000;
    z-index: 1002;
}
.sysmexMoleculeImageLightbox_lum-closing,
.sysmexMoleculeImageLightbox_lum-opening {
    -webkit-animation: lum-noop 0.001s;
            animation: lum-noop 0.001s;
}
.sysmexMoleculeImageLightbox_lum-lightbox.sysmexMoleculeImageLightbox_lum-open {
    display: block;
}
.sysmexMoleculeImageLightbox_lum-lightbox-inner {
    position: absolute;
    top: 50%;
    right: 0;
    bottom: auto;
    left: 0;
    overflow: visible;
    transform: translateY(-50%)
}
@media only screen and (min-width: 992px) {
.sysmexMoleculeImageLightbox_lum-lightbox-inner {
        overflow: hidden;
        top: 2.5%;
        bottom: 2.5%;
        transform: translateY(0)
}
    }
.sysmexMoleculeImageLightbox_lum-lightbox-title,
.sysmexMoleculeImageLightbox_lum-lightbox-caption {
    color: #fff;
}
.sysmexMoleculeImageLightbox_lum-lightbox-title {
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
    color: #333333;
    text-transform: none;
    font-size: 14px;
    /* font-family: "Source Sans Pro", sans-serif; */

    color: #FFFFFF;
}
.sysmexMoleculeImageLightbox_lum-lightbox-caption {
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
    color: #333333;
    text-transform: none;
    font-size: 14px;
    /* font-family: "Source Sans Pro", sans-serif; */

    color: #FFFFFF;
    margin-bottom: 16px;
}
.sysmexMoleculeImageLightbox_lum-lightbox-loader {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 66px;
    height: 20px;
    -webkit-animation: lum-loader-rotate 1.8s infinite linear;
            animation: lum-loader-rotate 1.8s infinite linear;
}
.sysmexMoleculeImageLightbox_lum-loading .sysmexMoleculeImageLightbox_lum-lightbox-loader {
    display: block;
}
.sysmexMoleculeImageLightbox_lum-lightbox-image-wrapper {
    display: table-cell;
    vertical-align: middle;
    height: auto !important; /* luminous miscalculation fix */
    max-height: none !important
}
@media only screen and (min-width: 992px) {
.sysmexMoleculeImageLightbox_lum-lightbox-image-wrapper {
        vertical-align: top
}
    }
.sysmexMoleculeImageLightbox_lum-lightbox-loader::before,
.sysmexMoleculeImageLightbox_lum-lightbox-loader::after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.9);
}
.sysmexMoleculeImageLightbox_lum-lightbox-loader::before {
    left: 0;
    -webkit-animation: lum-loader-before 1.8s infinite linear;
            animation: lum-loader-before 1.8s infinite linear;
}
.sysmexMoleculeImageLightbox_lum-lightbox-loader::after {
    right: 0;
    -webkit-animation: lum-loader-after 1s infinite linear;
            animation: lum-loader-after 1s infinite linear;
    -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
}
.sysmexMoleculeImageLightbox_lum-opening {
    -webkit-animation: lum-fade 0.18s ease-out;
            animation: lum-fade 0.18s ease-out;
}
.sysmexMoleculeImageLightbox_lum-opening .sysmexMoleculeImageLightbox_lum-lightbox-inner {
    -webkit-animation: lum-fade-zoom 0.18s ease-out;
            animation: lum-fade-zoom 0.18s ease-out;
}
.sysmexMoleculeImageLightbox_lum-closing {
    -webkit-animation: lum-fade 0.3s ease-in;
            animation: lum-fade 0.3s ease-in;
    animation-direction: reverse;
}
.sysmexMoleculeImageLightbox_lum-closing .sysmexMoleculeImageLightbox_lum-lightbox-inner {
    -webkit-animation: lum-fade-zoom 0.3s ease-in;
            animation: lum-fade-zoom 0.3s ease-in;
    animation-direction: reverse;
}
.sysmexMoleculeImageLightbox_lum-img {
    transition: opacity 0.12s ease-out;
    width: 100vw;
    max-width: 100%;
    max-height: calc(100vh - 140px);
    -o-object-fit: contain;
       object-fit: contain;
}
.sysmexMoleculeImageLightbox_lum-loading .sysmexMoleculeImageLightbox_lum-img {
    opacity: 0;
}
.sysmexMoleculeImageLightbox_lum-close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    color: #fff;
    font-size: 60px;
    cursor: pointer;
    text-shadow: 2px 4px 0 rgba(0, 0, 0, 0.25);
}
.sysmexMoleculeImageLightbox_lum-lightbox-infoLayer {
    margin: auto;
    height: 80px;
    bottom: 0;
    position: relative;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 90% !important;
    text-align: center;
}
.sysmexMoleculeImageLightbox_lum-previous-button {
    left: -28px;
}
.sysmexMoleculeImageLightbox_lum-next-button {
    right: -38px;
}
.sysmexMoleculeImageLightbox_lum-gallery-button {
    position: absolute;
    color: #fff;
    font-size: 140px;
    cursor: pointer;
    display: block;
    top: 40%;
    text-shadow: 2px 4px 5px rgba(0, 0, 0, 0.5)
}
@media only screen and (min-width: 992px) {
.sysmexMoleculeImageLightbox_lum-gallery-button {
        top: 35%
}
    }
@media (max-width: 460px) {
    .sysmexMoleculeImageLightbox_lum-lightbox-image-wrapper {
        display: flex;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

    .sysmexMoleculeImageLightbox_lum-lightbox-position-helper {
        margin: auto;
        left: 0;
    }

    .sysmexMoleculeImageLightbox_lum-lightbox-inner img {
        max-width: none;
        max-height: none;
        width: 100%;
    }

    .sysmexMoleculeImageLightbox_lum-gallery-button {
        font-size: 120px;
        top: 38%;
    }

    .sysmexMoleculeImageLightbox_lum-next-button {
        right: -30px;
    }

    .sysmexMoleculeImageLightbox_lum-previous-button {
        left: -30px;
    }

    .sysmexMoleculeImageLightbox_lum-lightbox-infoLayer {
        height: auto;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 100%);
        padding-top: 8px;
    }
}

/* stylelint-disable */
.sysmexMoleculeJobCard_jobCardWrapper {
    height: 100%;
    display: flex;
    flex-direction: column
}
@media only screen and (min-width: 992px) {
.sysmexMoleculeJobCard_jobCardWrapper {
        min-height: 435px
}
    }
.sysmexMoleculeJobCard_jobCard-link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-right: 16px;
}
.sysmexMoleculeJobCard_jobCard {
    padding: 24px;
    text-align: left;
    height: 100%;
    display: block;
    background: #fff;
    margin-bottom: 32px;
}
.sysmexMoleculeJobCard_jobCard a:hover {
        color: #333333;
    }
.sysmexMoleculeJobCard_jobCard .sysmexMoleculeJobCard_datRef {
        font-style: normal;
        font-weight: 900;
        line-height: 16px;
        margin-bottom: 16px;
        color: #333333;
        text-transform: none;
        font-size: 14px;
    }
/* font-family: "Source Sans Pro", sans-serif; */
.sysmexMoleculeJobCard_jobCard .sysmexMoleculeJobCard_datRef .sysmexMoleculeJobCard_divider {
            display: inline-block;
            padding: 0 4px;
        }
.sysmexMoleculeJobCard_jobCard .sysmexMoleculeJobCard_title {
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 23px;
        /* font-family: "Source Sans Pro", sans-serif; */

        font-family: Sysmex, "Source Sans Pro", sans-serif;
        word-wrap: break-word;
    }
.sysmexMoleculeJobCard_jobCard .sysmexMoleculeJobCard_fact {
        margin: 1em 0 1.5em;
    }
.sysmexMoleculeJobCard_jobCard .sysmexMoleculeJobCard_fact .sysmexMoleculeJobCard_fact-title-label {
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 14px;
            /* font-family: "Source Sans Pro", sans-serif; */

            display: flex;
            align-items: center;
            margin: 0;
        }
.sysmexMoleculeJobCard_jobCard .sysmexMoleculeJobCard_fact .sysmexMoleculeJobCard_fact-title-label .sysmexMoleculeJobCard_fact-title-label_text {
                opacity: 0.8;
            }
.sysmexMoleculeJobCard_jobCard .sysmexMoleculeJobCard_fact .sysmexMoleculeJobCard_fact-title-label i {
                font-size: 32px;
                transform: translate(-5px, 12px);
            }
.sysmexMoleculeJobCard_jobCard .sysmexMoleculeJobCard_fact .sysmexMoleculeJobCard_fact-text {
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            margin-left: 32px;
            margin-top: -2px;
            display: block;
        }
.sysmexMoleculeJobCard_jobCard .sysmexMoleculeJobCard_linkText {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #000000;
        margin: 0;
        padding: 8px 0 8px 20px;
        text-align: right
    }
.sysmexMoleculeJobCard_jobCard .sysmexMoleculeJobCard_linkText:hover {
            color: #00B8EE;
        }

/* stylelint-disable */
.sysmexMoleculeLanguageMenu_languageMenu {
    max-width: 1200px;
    margin: 0 auto;
    padding: 48px 32px;
}
.sysmexMoleculeLanguageMenu_languageMenu h2 {
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        margin-bottom: 24px;
        color: #333333;
        text-transform: none;
        font-size: 34px;
        /* font-family: "Source Sans Pro", sans-serif; */
    }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne {
        display: flex;
        flex-direction: column;
        justify-content: space-between
    }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne {
            flex-direction: row
    }
        }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLeft {
            flex-basis: 33.3%;
        }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuRight {
            flex-basis: 66.6%;
        }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuRight .sysmexMoleculeLanguageMenu_menuLevelTwo {
                -moz-column-count: 1;
                     column-count: 1;
            }
@media only screen and (min-width: 768px) {
                .sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuRight .sysmexMoleculeLanguageMenu_menuLevelTwo {
                    -moz-column-count: 2;
                         column-count: 2;
                }
            }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne > div {
            flex: 1 1 0
        }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne > div:not(:last-child) {
                margin-bottom: 32px
            }
@media only screen and (min-width: 992px) {

            .sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne > div:not(:last-child) {
                    margin-right: 16px;
                    margin-bottom: 0
            }
                }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelOneTitle {
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            margin-bottom: 24px;
            color: #333333;
            text-transform: none;
            font-size: 27px;
            /* font-family: "Source Sans Pro", sans-serif; */
        }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper {
            padding-bottom: 32px
        }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper:not(:first-child) {
                padding-top: 32px;
            }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo {
                padding-left: 0;
                margin: 0;
                list-style: none;
            }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo li {
                    margin-bottom: 16px;
                    page-break-inside: avoid;
                    -moz-column-break-inside: avoid;
                         break-inside: avoid;
                }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo li a,
                    .sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo li a:visited {
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        margin-bottom: 0;
                        color: #333333;
                        text-transform: none;
                        font-size: 18px;
                        /* font-family: "Source Sans Pro", sans-serif; */

                        margin-bottom: 8px
                    }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo li a.sysmexMoleculeLanguageMenu_active, .sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo li a:visited.sysmexMoleculeLanguageMenu_active {
                            color: #005BAC;
                        }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo li a:hover, .sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo li a:visited:hover {
                            color: #00B8EE;
                        }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo li .sysmexMoleculeLanguageMenu_menuLevelThree a {
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px;
                            margin-bottom: 0;
                            color: #333333;
                            text-transform: none;
                            font-size: 14px;
                            /* font-family: "Source Sans Pro", sans-serif; */

                            color: #595959
                        }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo li .sysmexMoleculeLanguageMenu_menuLevelThree a.sysmexMoleculeLanguageMenu_active {
                                color: #005BAC;
                            }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo li .sysmexMoleculeLanguageMenu_menuLevelThree a i {
                                font-size: 1rem
                            }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo li .sysmexMoleculeLanguageMenu_menuLevelThree a i::before {
                                    vertical-align: middle;
                                }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo li .sysmexMoleculeLanguageMenu_menuLevelThree a:not(:last-child) {
                                margin-right: 16px
                            }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_menuLevelOne .sysmexMoleculeLanguageMenu_menuLevelTwoWrapper .sysmexMoleculeLanguageMenu_menuLevelTwo li .sysmexMoleculeLanguageMenu_menuLevelThree a:not(:last-child)::after {
                                    content: "|";
                                    padding-left: 10px;
                                }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_disclaimer {
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 14px;
        /* font-family: "Source Sans Pro", sans-serif; */

        text-align: right;
    }
.sysmexMoleculeLanguageMenu_languageMenu .sysmexMoleculeLanguageMenu_disclaimer a {
            text-decoration: underline;
        }

/* stylelint-disable */
.sysmexMoleculeMaps_maps {
    position: relative;
}
.sysmexMoleculeMaps_maps .sysmexMoleculeMaps_mapContainer {
        z-index: 1;
    }
.sysmexMoleculeMaps_maps .sysmexMoleculeMaps_navigationWrapper {
        background: #EDF3F9;
        position: absolute;
        left: 0;
        width: 350px;
        top: 0;
        bottom: 0;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        z-index: 1;
    }
.sysmexMoleculeMaps_maps .sysmexMoleculeMaps_navigationWrapper .sysmexMoleculeMaps_inputArea input {
                border-radius: 0;
                border-left: 0;
                border-right: 0;
            }
.sysmexMoleculeMaps_maps .sysmexMoleculeMaps_navigationWrapper .sysmexMoleculeMaps_inputArea .sysmexMoleculeMaps_routeCalc,
            .sysmexMoleculeMaps_maps .sysmexMoleculeMaps_navigationWrapper .sysmexMoleculeMaps_inputArea .sysmexMoleculeMaps_routeClear {
                position: absolute;
                cursor: pointer;
                top: 0;
                right: 0;
                background: none;
                margin: 0;
                width: auto;
                color: #005BAC;
            }
.sysmexMoleculeMaps_maps .sysmexMoleculeMaps_navigationWrapper .sysmexMoleculeMaps_inputArea .sysmexMoleculeMaps_routeClear {
                display: none;
            }
.sysmexMoleculeMaps_maps .sysmexMoleculeMaps_navigationWrapper .sysmexMoleculeMaps_inputArea .sysmexMoleculeMaps_externalLink {
                position: absolute;
                top: 47px;
                right: 0;
                color: #005BAC;
                display: none;
            }
.sysmexMoleculeMaps_maps .sysmexMoleculeMaps_navigationWrapper .sysmexMoleculeMaps_scrollArea {
            overflow: auto;
            height: 396px;
            margin-top: 8px;
        }

/* stylelint-disable */
@media only screen and (min-width: 992px) {
.sysmexMoleculeMiniDropdown_wrapper {
        position: relative
}
    }
.sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_opener {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center
    }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_opener {
            position: relative
    }
        }
.sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_opener {

        z-index: 203;
    }
.sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_opener .sysmexMoleculeMiniDropdown_icon {
            font-size: 40px;
            color: #005BAC
        }
.sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_opener .sysmexMoleculeMiniDropdown_icon:hover {
                color: #00B8EE;
            }
.sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_opener.sysmexMoleculeMiniDropdown_smallIcon .sysmexMoleculeMiniDropdown_icon {
                font-size: 32px;
            }
.sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_opener .sysmexMoleculeMiniDropdown_label {
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 14px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #000000;
            margin-right: 10px;
        }
.sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_headline {
        padding: 16px;
        border-bottom: 1px solid #D2D2D2;

        font-style: normal;

        font-weight: 600;

        line-height: 16px;

        margin-bottom: 0;

        color: #333333;

        text-transform: none;

        font-size: 14px;

        /* font-family: "Source Sans Pro", sans-serif; */

        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        background: #FFFFFF
    }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_headline {
            position: static
    }
        }
.sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_content {
        padding: 16px;

        font-style: normal;

        font-weight: 600;

        line-height: 16px;

        margin-bottom: 0;

        color: #333333;

        text-transform: none;

        font-size: 14px;

        /* font-family: "Source Sans Pro", sans-serif; */

        text-align: center;
        overflow-y: scroll;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        justify-content: center
    }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_content {
            height: auto;
            overflow: auto;
            display: block
    }
        }
.sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown.sysmexMoleculeMiniDropdown_--open {
            display: block;
        }
.sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown {

        display: none;
        position: fixed;
        background: #FFFFFF;
        z-index: 1006;
        width: 100%;
        height: 100vh;

        /* https://css-tricks.com/the-trick-to-viewport-units-on-mobile/ */
        height: calc(1vh * 100);
        height: calc(var(--sysmexMoleculeCookieOptin, 1vh) * 100);
        left: 0;
        top: 0;
    }
.sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown .sysmexMoleculeMiniDropdown_closeDetails {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1007;
        }
@media only screen and (min-width: 992px) {
            .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown.sysmexMoleculeMiniDropdown_--open {
                opacity: 1;
                z-index: 204;
            }

    .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown {

            opacity: 0;
            display: block;
            position: absolute;
            width: 200px;
            left: -200px;
            border: 1px solid #D2D2D2;
            z-index: -9999;
            height: auto;
            top: 40px;
    }

            .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown .sysmexMoleculeMiniDropdown_arrow {
                display: inline-block;
                height: 8px;
                position: absolute;
                width: 8px;
                transform: rotate(225deg);
                top: -4px;
                left: 47.5%
            }

                .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown .sysmexMoleculeMiniDropdown_arrow::before,
                .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown .sysmexMoleculeMiniDropdown_arrow::after {
                    content: '';
                    display: inline-block;
                    left: 0;
                    position: absolute;
                    top: 0;
                    border-style: solid;
                    height: 0;
                    width: 0;
                    border-color: transparent;
                }

                .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown .sysmexMoleculeMiniDropdown_arrow::before {
                    border-width: 0 0 9px 9px;
                    border-bottom-color: #D2D2D2;
                }

                .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown .sysmexMoleculeMiniDropdown_arrow::after {
                    border-width: 0 0 8px 8px;
                    border-bottom-color: #FFFFFF;
                }
                .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown.sysmexMoleculeMiniDropdown_fixedPosArrowVertical .sysmexMoleculeMiniDropdown_arrow {
                    bottom: -4px;
                    transform: rotate(45deg);
                    top: auto;
                }
                .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown.sysmexMoleculeMiniDropdown_fixedPosHorizontalArrowRight .sysmexMoleculeMiniDropdown_arrow {
                    right: 5px;
                    left: auto;
                }
                .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown.sysmexMoleculeMiniDropdown_fixedPosHorizontalArrowLeft .sysmexMoleculeMiniDropdown_arrow {
                    left: 14px;
                    right: auto;
                }

            .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown.sysmexMoleculeMiniDropdown_hasOffset {
                margin-top: -6px;
            }

            .sysmexMoleculeMiniDropdown_wrapper .sysmexMoleculeMiniDropdown_dropdown .sysmexMoleculeMiniDropdown_closeDetails {
                display: none;
            }
        }

/* stylelint-disable */
.sysmexMoleculeMiniDropdownMenu_actionMenu {
    padding: 0;
    margin: 16px 0;
}
.sysmexMoleculeMiniDropdownMenu_actionMenu li {
        list-style: none;
        margin-bottom: 24px
    }
.sysmexMoleculeMiniDropdownMenu_actionMenu li:last-child {
            margin-bottom: 0;
        }
.sysmexMoleculeMiniDropdownMenu_actionMenu li a,
        .sysmexMoleculeMiniDropdownMenu_actionMenu li .sysmexMoleculeMiniDropdownMenu_actionMenuItem {
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 14px;
            /* font-family: "Source Sans Pro", sans-serif; */

            cursor: pointer
        }
.sysmexMoleculeMiniDropdownMenu_actionMenu li a:hover, .sysmexMoleculeMiniDropdownMenu_actionMenu li .sysmexMoleculeMiniDropdownMenu_actionMenuItem:hover {
                color: #00B8EE;
            }

/* stylelint-disable */
.sysmexMoleculeModalContainer_background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: -1;
    opacity: 0;
    display: none;
}
.sysmexMoleculeModalContainer_background .sysmexMoleculeModalContainer_modalContentWrapper {
        width: 80%;
        position: fixed;
        z-index: calc(1002 + 1);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFFFFF;
        max-height: 100vh;
        overflow-y: auto
    }
@media only screen and (min-width: 1441px) {

    .sysmexMoleculeModalContainer_background .sysmexMoleculeModalContainer_modalContentWrapper {
            width: 60%
    }
        }
.sysmexMoleculeModalContainer_background .sysmexMoleculeModalContainer_modalContentWrapper .sysmexMoleculeModalContainer_close i {
                position: absolute;
                right: 16px;
                top: 16px;
                cursor: pointer;
            }
.sysmexMoleculeModalContainer_background .sysmexMoleculeModalContainer_hasFullWidth {
        width: 100vw;
        height: 100vh;
    }
.sysmexMoleculeModalContainer_background.sysmexMoleculeModalContainer_modalContainer__active {
        opacity: 1;
        z-index: 1002;
        display: block;
        -webkit-animation-name: modal-fade-in;
                animation-name: modal-fade-in;
        -webkit-animation-duration: 0.5s;
                animation-duration: 0.5s;
    }
@-webkit-keyframes modal-fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@keyframes modal-fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/* stylelint-disable */
.sysmexMoleculeModalContent_modalContent {
    padding: 24px;
}
.sysmexMoleculeModalContent_modalContent img {
        width: 100%;
        margin-bottom: 16px;
    }
.sysmexMoleculeModalContent_modalContent .sysmexMoleculeModalContent_main-heading-2 {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 22px;
        /* font-family: "Source Sans Pro", sans-serif; */
    }
.sysmexMoleculeModalContent_modalContent .sysmexMoleculeModalContent_main-copy {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */
    }

/* stylelint-disable */
.sysmexMoleculeMultiDownload_main {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: left;

    font-style: normal;

    font-weight: 400;

    line-height: 24px;

    margin-bottom: 0;

    color: #333333;

    text-transform: none;

    font-size: 16px;

    /* font-family: "Source Sans Pro", sans-serif; */

    margin-bottom: 0;
    box-sizing: border-box;
    width: 100%;
}
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_toggler {
        width: 100%;
        text-align: left;
        height: 48px;
        padding: 12px 65px 12px 16px;
        border: 1px solid rgba(0,0,0,0.3);
        border-radius: 3px;
        background: #FFFFFF;

        font-style: normal;

        font-weight: 400;

        line-height: 24px;

        margin-bottom: 0;

        color: #333333;

        text-transform: none;

        font-size: 16px;

        /* font-family: "Source Sans Pro", sans-serif; */

        cursor: pointer
    }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_toggler:disabled {
            border-color: rgba(0,91,172,0.1);
            background: rgba(0,91,172,0.3);
            cursor: not-allowed;
        }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_toggler .sysmexMoleculeMultiDownload_title {
            -webkit-user-select: none;
               -moz-user-select: none;
                    user-select: none;
            height: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #333333;
        }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_toggler::after {
            font-size: 3rem;
            content: "\e956";
            -webkit-font-smoothing: antialiased;
            font-family: sysmexicons, sans-serif !important;
            font-feature-settings: normal;
            font-style: normal;
            font-variant: normal;
            font-weight: 400;
            line-height: 1;
            speak: none;
            text-transform: none;
            right: 0;
            top: -1px;
            position: absolute;
        }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_clear-button--all {
        background: transparent;
        border: 0;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_clear-button--confirm {
        border-color: #E7413E;
        background: #E7413E;
        max-width: none;
    }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_download-button--all {
        margin-top: 1rem;
        flex: 1 1 100%;
        max-width: none;
    }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_buttons {
        padding: 12px;
        display: flex;
        justify-content: end;
        flex-wrap: wrap;
    }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_buttons button,
        .sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_buttons a {
            max-width: none;
        }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_confirmationPopup {
        display: flex;
        justify-content: space-between;
        grid-gap: 1rem;
        gap: 1rem;
        flex-basis: 100%;
    }
.sysmexMoleculeMultiDownload_main.sysmexMoleculeMultiDownload_open .sysmexMoleculeMultiDownload_dropdown {
            display: block;
        }
.sysmexMoleculeMultiDownload_main.sysmexMoleculeMultiDownload_open .sysmexMoleculeMultiDownload_toggler::after {
            content: "\e95c";
            top: 1px;
        }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_dropdown {
        background-color: #FFFFFF;
        border-top: none;
        box-sizing: border-box;
        display: none;
        list-style: none;
        overflow: auto;
        width: 100%;
        z-index: 1000;
        border: 1px solid rgba(0,0,0,0.3);
        top: calc(100% - 1px);
        margin-top: 1px;
        max-height: 430px;
        position: absolute;
    }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_dropdown .sysmexMoleculeMultiDownload_item-list {
            position: relative;
            -webkit-user-select: none;
               -moz-user-select: none;
                    user-select: none;
            list-style: none;
            margin: 0;
            padding: 0 0 11px;
            overflow-y: auto;
        }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_dropdown li {
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            font-size: 16px;
            line-height: 22px;
            padding: 10px 16px;
            margin-bottom: 0;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center
        }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_dropdown li.sysmexMoleculeMultiDownload_active {
                color: #005BAC;
            }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_dropdown li:hover {
                color: #005BAC;
                background-color: rgba(0,91,172,0.1);
            }
.sysmexMoleculeMultiDownload_main .sysmexMoleculeMultiDownload_dropdown button {
            height: auto;
        }
.sysmexMoleculeMultiDownload_delete-icon {
    display: inline-block;
    padding: 0.5rem;
    cursor: pointer
}
.sysmexMoleculeMultiDownload_delete-icon::after {
        content: url('data:image/svg+xml,<svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.77143 10.6648L6 8.43627L8.22857 10.6648L8.83521 10.0582L6.60664 7.82963L8.83521 5.60106L8.22857 4.99441L6 7.22299L3.77143 4.99441L3.16479 5.60106L5.39336 7.82963L3.16479 10.0582L3.77143 10.6648ZM2.24186 14.2582C1.84729 14.2582 1.51786 14.1261 1.25357 13.8618C0.989286 13.5975 0.857143 13.2681 0.857143 12.8735V2.2582H0V1.40106H3.42857V0.741699H8.57143V1.40106H12V2.2582H11.1429V12.8735C11.1429 13.2681 11.0107 13.5975 10.7464 13.8618C10.4821 14.1261 10.1527 14.2582 9.75814 14.2582H2.24186ZM10.2857 2.2582H1.71429V12.8735C1.71429 13.0055 1.76921 13.1264 1.87907 13.2363C1.98893 13.3461 2.10986 13.4011 2.24186 13.4011H9.75814C9.89014 13.4011 10.0111 13.3461 10.1209 13.2363C10.2308 13.1264 10.2857 13.0055 10.2857 12.8735V2.2582Z" fill="currentColor"/></svg>');
    }

/* stylelint-disable */
.sysmexMoleculeNavigation_navigation {
    background-color: #FFFFFF;
    display: none;
    padding: 8px 0;
    border-bottom: 1px solid rgba(51,51,51,0.2);
    position: relative;
    font-family: Sysmex, "Source Sans Pro", sans-serif
}
@media only screen and (min-width: 1280px) {
.sysmexMoleculeNavigation_navigation {
        display: grid
}
    }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            position: relative;
        }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem {
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 22px;
                /* font-family: "Source Sans Pro", sans-serif; */

                align-items: center;
                border-right: 1px solid rgba(51,51,51,0.2)
            }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem:nth-child(1) {
                    border-left: 1px solid rgba(51,51,51,0.2);
                }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationSubList > li {
                    flex: 1;
                    padding-right: 16px
                }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationSubList > li.sysmexMoleculeNavigation_navigationDivided {
                        padding-right: 0;
                    }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationSubList > li {

                    /* &:nth-child(1) {
                        a {
                            padding-right: get(sizes_regular-s);
                        }
                    } */
                }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationLink {
                    display: inline-block;
                    padding: 0 40px;
                    line-height: 2em;
                }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationLink .sysmexMoleculeNavigation_label {
                        padding-right: 4px;
                    }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationLink.sysmexMoleculeNavigation_active {
                        color: #005BAC;
                    }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationSubList {
                    display: none;
                    list-style: none;
                    position: absolute;
                    z-index: 20;
                    background: #FFFFFF;
                    justify-content: space-between;
                    padding: 40px 32px 32px 32px;
                    box-sizing: border-box;
                    width: calc(100% + 200px);
                    left: -100px;
                }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationSubList a {
                        padding: 0;
                        color: #333333
                    }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationSubList a:hover {
                            color: #00B8EE;
                        }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationSubList .sysmexMoleculeNavigation_navigationSubSubList {
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        margin-bottom: 0;
                        color: #333333;
                        text-transform: none;
                        font-size: 18px;
                        /* font-family: "Source Sans Pro", sans-serif; */

                        font-weight: 100;
                        list-style: none;
                        padding: 0;
                        padding-top: 8px;
                        margin-top: 16px;
                        border-top: 2px solid #005BAC;
                        line-height: 40px;
                    }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationSubList .sysmexMoleculeNavigation_navigationSubSubList > li:not(:last-child) {
                            margin-bottom: 8px;
                        }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationSubList .sysmexMoleculeNavigation_navigationSubSubList a {
                            line-height: 1.4;
                        }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem:hover > .sysmexMoleculeNavigation_navigationLink {
                        color: #00B8EE;
                    }
.sysmexMoleculeNavigation_navigation .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem:hover .sysmexMoleculeNavigation_navigationSubList {
                        display: flex;
                        justify-content: space-between;
                        border: 1px solid rgba(51,51,51,0.2);
                        border-top: none;
                    }
.sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex {
        min-height: 62px;
        border-bottom-color: #D6D6D6;
    }
.sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_outerWrap {
            position: absolute;
            left: 0;
            right: 0;
            top: 8px;
        }
.sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList {
            height: 46px;
            justify-content: center;
        }
.sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationSubList {
                opacity: 0;
                pointer-events: none;
            }
.sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem {
                position: relative;
                border: 0;
                font-size: 18px;
                flex: 1;
                background-color: transparent;
                z-index: 33
            }
.sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem:first-of-type > .sysmexMoleculeNavigation_navigationLink {
                    border-left: 1px solid #D6D6D6;
                }
.sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem > .sysmexMoleculeNavigation_navigationLink {
                    border-right: 1px solid #D6D6D6;
                    display: flex;
                    padding: 0 4px;
                    text-align: center;
                    line-height: inherit;
                    align-items: center;
                    justify-content: center;
                    height: 46px;
                }
.sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationSubList {
                    display: block;
                    line-height: 50px;
                    left: 0;
                    top: 8px;
                    position: relative;
                    background: transparent;
                    border: 0;
                    width: auto;
                    text-align: center;
                    padding: 0;
                    min-height: 215px;
                    border-top: 1px solid #D6D6D6;
                }
.sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationSubList > li {
                        padding-right: 0;
                        z-index: 1;
                        display: flex;
                        min-height: 50px;
                        align-items: center;
                    }
.sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList .sysmexMoleculeNavigation_navigationItem .sysmexMoleculeNavigation_navigationSubList > li a {
                            font-size: 16px;
                            line-height: normal;
                            flex: 1;
                            padding: 0 4px;
                        }
.sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList:hover,
            .sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList.sysmexMoleculeNavigation_is-active {
                height: auto;
            }
.sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList:hover .sysmexMoleculeNavigation_navigationItem, .sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList.sysmexMoleculeNavigation_is-active .sysmexMoleculeNavigation_navigationItem {
                    border-bottom: 1px solid #D6D6D6;
                    padding-bottom: 8px;
                    background-color: #FFFFFF;
                }
.sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList:hover .sysmexMoleculeNavigation_navigationSubList, .sysmexMoleculeNavigation_navigation.sysmexMoleculeNavigation_mysysmex .sysmexMoleculeNavigation_navigationWrapper .sysmexMoleculeNavigation_navigationList.sysmexMoleculeNavigation_is-active .sysmexMoleculeNavigation_navigationSubList {
                    opacity: 1;
                    pointer-events: all;
                }

/* stylelint-disable */
.sysmexMoleculeNavigationMicrosite_navigation {
    background-color: #FFFFFF;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 500;
    font-family: Sysmex, "Source Sans Pro", sans-serif
}
@media only screen and (min-width: 992px) {
.sysmexMoleculeNavigationMicrosite_navigation {
        position: relative
}
    }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin: 24px
    }
@media only screen and (min-width: 1280px) {

    .sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_inner {
            margin: 24px 0
    }
        }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_logo {
        flex-shrink: 1;
    }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_logo .sysmexMoleculeNavigationMicrosite_logo-image {
            line-height: 0;
        }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_logo img {
            height: auto;
            max-width: 100%;
        }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_navigationList {
        flex-basis: 100%;
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.3s
    }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_navigationList {
            flex-basis: auto;
            display: block
    }
        }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_navigationList > div {
            overflow: hidden;
        }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_navigationList ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 32px 0;
            flex-direction: column
        }
@media only screen and (min-width: 992px) {

        .sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_navigationList ul {
                flex-direction: row;
                padding: 0;
                grid-gap: 24px;
                gap: 24px
        }
            }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_navigationList .sysmexMoleculeNavigationMicrosite_navigationItem .sysmexMoleculeNavigationMicrosite_navigationLink {
                display: inline-block;
                color: #005BAC;
                letter-spacing: 0.1em;
                padding: 16px 32px
            }
@media only screen and (min-width: 992px) {
            .sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_navigationList .sysmexMoleculeNavigationMicrosite_navigationItem .sysmexMoleculeNavigationMicrosite_navigationLink {
                    padding: 24px 0
            }
                }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_navigationList .sysmexMoleculeNavigationMicrosite_navigationItem .sysmexMoleculeNavigationMicrosite_navigationLink.sysmexMoleculeNavigationMicrosite_active {
                    color: #00B8EE;
                }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_navigationList .sysmexMoleculeNavigationMicrosite_navigationItem:hover > .sysmexMoleculeNavigationMicrosite_navigationLink {
                    color: #00B8EE;
                }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_menu-toggler {
        background-color: transparent;
        border: 0;
        padding: 10px 6px;
        flex-shrink: 0;
        cursor: pointer
    }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_menu-toggler {
            display: none
    }
        }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_menu-toggler > div {
            position: relative;
            width: 28px;
            height: 20px;
        }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_menu-toggler span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            opacity: 1;
            left: 0;
            top: 50%;
            margin-top: -1px;
            background: #005BAC;
            transition: 0.25s ease-in-out
        }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_menu-toggler span:nth-child(1) {
                top: 0;
            }
.sysmexMoleculeNavigationMicrosite_navigation .sysmexMoleculeNavigationMicrosite_menu-toggler span:nth-child(4) {
                top: 100%;
            }
.sysmexMoleculeNavigationMicrosite_is--open {
    height: 100vh;
    /* stylelint-disable */
    height: 100dvh;
    /* stylelint-enable */
    position: fixed;
    top: 0;
    width: 100%;
}
.sysmexMoleculeNavigationMicrosite_is--open .sysmexMoleculeNavigationMicrosite_menu-toggler span:nth-child(1),
            .sysmexMoleculeNavigationMicrosite_is--open .sysmexMoleculeNavigationMicrosite_menu-toggler span:nth-child(4) {
                width: 0%;
                left: 50%;
            }
.sysmexMoleculeNavigationMicrosite_is--open .sysmexMoleculeNavigationMicrosite_menu-toggler span:nth-child(2) {
                transform: rotate(45deg);
            }
.sysmexMoleculeNavigationMicrosite_is--open .sysmexMoleculeNavigationMicrosite_menu-toggler span:nth-child(3) {
                transform: rotate(-45deg);
            }
.sysmexMoleculeNavigationMicrosite_is--open .sysmexMoleculeNavigationMicrosite_navigationList {
        grid-template-rows: 1fr;
    }

/* stylelint-disable */
.sysmexMoleculeNotificationsToast_wrapper {
    box-sizing: border-box;
    padding: 32px 32px;
    border-radius: 3px;
    background-color: #0DAC67;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
    max-width: 100%;
    position: fixed;
    bottom: -100%;
    right: 0;
    z-index: 1005;
    transition: all 0.5s;
}
.sysmexMoleculeNotificationsToast_wrapper--green {
        background-color: #0DAC67;
    }
.sysmexMoleculeNotificationsToast_wrapper--red {
        background-color: #E7413E;
    }
.sysmexMoleculeNotificationsToast_wrapper--blue {
        background-color: #005BAC;
    }
.sysmexMoleculeNotificationsToast_wrapper--orange {
        background-color: #F5AC3C;
    }
.sysmexMoleculeNotificationsToast_wrapper.sysmexMoleculeNotificationsToast_wrapperVisible {
        bottom: 0;
        transition: all 0.5s;
    }
.sysmexMoleculeNotificationsToast_wrapper.sysmexMoleculeNotificationsToast_wrapper--isGuide {
        background-color: #864B99;
        display: none
    }
@media only screen and (min-width: 1280px) {
.sysmexMoleculeNotificationsToast_wrapper.sysmexMoleculeNotificationsToast_wrapper--isGuide {
            display: block
    }
        }
@media (max-width: 767px) {
        .sysmexMoleculeNotificationsToast_wrapper img {
            width: 50%;
            margin: 0 auto;
        }
    }
@media (max-height: 420px) and (orientation: landscape) {
.sysmexMoleculeNotificationsToast_wrapper {
        right: 0;
        width: 50% !important;
}

        .sysmexMoleculeNotificationsToast_wrapper img {
            width: 35%;
            margin: 0 auto;
        }
    }
@media only screen and (min-width: 768px) {
.sysmexMoleculeNotificationsToast_wrapper {
        padding: 32px;
        width: auto;
        max-width: none
}
    }
@media only screen and (min-width: 992px) {
.sysmexMoleculeNotificationsToast_wrapper {
        padding: 24px;
        width: 35vw;
        right: 32px;
        text-align: left
}

        .sysmexMoleculeNotificationsToast_wrapper.sysmexMoleculeNotificationsToast_wrapperVisible {
            bottom: 40px;
            transition: all 0.5s;
        }
    }
@media only screen and (min-width: 1280px) {
.sysmexMoleculeNotificationsToast_wrapper {
        width: 352px;
        right: 40px
}

        .sysmexMoleculeNotificationsToast_wrapper.sysmexMoleculeNotificationsToast_wrapperVisible {
            bottom: 48px;
        }
    }
.sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_header {
        display: flex;
    }
.sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_header .sysmexMoleculeNotificationsToast_title {
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 22px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #FFFFFF;
            margin-bottom: 16px;
            flex: 1 1 auto
        }
@media only screen and (min-width: 1280px) {

        .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_header .sysmexMoleculeNotificationsToast_title {
                margin-right: 24px
        }
            }
.sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_header .sysmexMoleculeNotificationsToast_closeIcon {
            position: absolute;
            top: 0;
            right: 0
        }
@media only screen and (min-width: 1280px) {

        .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_header .sysmexMoleculeNotificationsToast_closeIcon {
                display: block;
                right: 24px;
                top: 24px;
                cursor: pointer;
                transform: translate(20px, -13px)
        }
            }
.sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content .sysmexMoleculeNotificationsToast_frame {
            margin: 0;
        }
.sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content {

        font-style: normal;

        font-weight: 400;

        line-height: 16px;

        margin-bottom: 0;

        color: #333333;

        text-transform: none;

        font-size: 14px;

        color: #FFFFFF;
        margin-bottom: 0;
}
/* font-family: "Source Sans Pro", sans-serif; */
.sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content a {
            color: #FFFFFF;
            text-decoration: underline;
        }
.sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content h1,
        .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content h2,
        .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content h3,
        .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content h4,
        .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content h5,
        .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content h6 {
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 22px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #FFFFFF;
            margin-bottom: 16px
        }
@media only screen and (min-width: 1280px) {

        .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content h1,
        .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content h2,
        .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content h3,
        .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content h4,
        .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content h5,
        .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content h6 {
                margin-right: 24px
        }
            }
.sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content p {
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 14px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #FFFFFF;
            margin-bottom: 16px;
        }
.sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_content.sysmexMoleculeNotificationsToast_buttonStyleOnLastLink .sysmexMoleculeNotificationsToast_button {
                text-decoration: none;
            }
.sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_button {
        margin-top: 16px;
    }
.sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_closeLabel {
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 14px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: rgba(255,255,255,0.6);
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 40px
    }
.sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_closeLabel:hover {
            color: #FFFFFF;
        }
@media only screen and (min-width: 1280px) {

    .sysmexMoleculeNotificationsToast_wrapper .sysmexMoleculeNotificationsToast_closeLabel {
            display: none
    }
        }

/* stylelint-disable */
.sysmexMoleculeOffCanvasNavigation_navigation {
    border-top: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 40px;
    font-family: Sysmex, "Source Sans Pro", sans-serif;
}
.sysmexMoleculeOffCanvasNavigation_navigation a,
    .sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_navigationBackLink {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        margin-bottom: 0;
        color: #005BAC;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        cursor: pointer
    }
.sysmexMoleculeOffCanvasNavigation_navigation a.sysmexMoleculeOffCanvasNavigation_hasChevron, .sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_navigationBackLink.sysmexMoleculeOffCanvasNavigation_hasChevron {
            padding: 6px 0;
        }
.sysmexMoleculeOffCanvasNavigation_navigation a:hover, .sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_navigationBackLink:hover {
            color: #00B8EE;
        }
.sysmexMoleculeOffCanvasNavigation_navigation a i, .sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_navigationBackLink i {
            font-size: 32px;
            color: #333333
        }
.sysmexMoleculeOffCanvasNavigation_navigation a i:hover, .sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_navigationBackLink i:hover {
                color: #333333;
            }
.sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_menueLabel {
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 24px;
        color: #333333;
        text-transform: none;
        font-size: 27px;
        /* font-family: "Source Sans Pro", sans-serif; */

        font-weight: 300;
        margin-bottom: 16px;
    }
.sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_navigationBackLink {
        background-color: rgba(0,91,172,0.06);
        padding-right: 16px;
    }
.sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_navigationSubList,
    .sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_navigationSubSubList {
        position: absolute;
        height: auto;
        min-height: 100vh;
        top: 0;
        right: -100%;
        width: 100%;
        background-color: #FFFFFF;
        z-index: 2;
        transition: right 0.6s ease-out;
    }
.sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_navigationSubSubList {
        z-index: 3;
    }
.sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_navigationItem,
    .sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_navigationSubList {
        transition: right 0.6s ease-out;
    }
.sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_navigationItemActive + .sysmexMoleculeOffCanvasNavigation_navigationSubList,
    .sysmexMoleculeOffCanvasNavigation_navigation .sysmexMoleculeOffCanvasNavigation_navigationItemActive + .sysmexMoleculeOffCanvasNavigation_navigationSubSubList {
        right: 0;
    }

.sysmexMoleculePagination_pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    padding: 4px;
}

    .sysmexMoleculePagination_pagination .sysmexMoleculePagination_pages {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .sysmexMoleculePagination_pagination .sysmexMoleculePagination_pages > li {
            margin-bottom: 0;
        }

    .sysmexMoleculePagination_pagination .sysmexMoleculePagination_pages .sysmexMoleculePagination_page {
            display: inline-block;
            min-width: 16px;
            padding: 2px 4px;
            text-align: center;
        }

    .sysmexMoleculePagination_pagination .sysmexMoleculePagination_pages .sysmexMoleculePagination_page--current {
                background: #005BAC;
                color: #FFFFFF;
                border-radius: 3px;
            }

    .sysmexMoleculePagination_pagination .sysmexMoleculePagination_icon {
        font-size: 2rem;
    }

.sysmexMoleculePaginationAjax_paginationAjax {
    display: flex;
    align-items: center;
    justify-content: center;
}

    .sysmexMoleculePaginationAjax_paginationAjax div[role="button"] {
        cursor: pointer;
        line-height: 0;
    }

    .sysmexMoleculePaginationAjax_paginationAjax .sysmexMoleculePaginationAjax_icon {
        font-size: 2rem;
    }

    .sysmexMoleculePaginationAjax_paginationAjax .sysmexMoleculePaginationAjax_counter {
        display: block;
        white-space: nowrap;
        line-height: 2rem;
    }

.sysmexMoleculePerPage_perPage {
    display: flex;
    align-items: center;
    justify-content: end;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    flex-wrap: wrap;
}

    .sysmexMoleculePerPage_perPage .sysmexMoleculePerPage_label {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
    }

    .sysmexMoleculePerPage_perPage input {
        display: none;
    }

    .sysmexMoleculePerPage_perPage .sysmexMoleculePerPage_radiogroup label {
            cursor: pointer;
            display: inline-block;
        }

    .sysmexMoleculePerPage_perPage .sysmexMoleculePerPage_active {
        font-weight: 600;
    }

/* stylelint-disable */
.sysmexMoleculePressDownload_press-download {
    height: 100%;
}
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner {
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        -ms-flex-negative: 0;
        background-color: #FFFFFF;
        height: 100%;
    }
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner .sysmexMoleculePressDownload_type {
            position: absolute;
            top: 0;
            left: 0;
            text-transform: uppercase;
            padding: 8px 24px;
            background-color: #005BAC;
            color: white;
        }
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner > div {
            -ms-flex-negative: 0;
        }
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner figure {
            margin: 0;
        }
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner img {
            display: block;
            -o-object-fit: cover;
               object-fit: cover;
            width: 100%;
            height: auto;
            aspect-ratio: 1.77777778;
        }
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner .sysmexMoleculePressDownload_teaserTextWrapper {
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            z-index: 1;
            bottom: 0;
            padding: 24px;
            background-color: #FFFFFF;
            text-align: left;
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
            -ms-flex-negative: 0;
            justify-content: space-between;
            margin-top: 0;
            min-height: 96px;
            box-sizing: border-box;
        }
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner .sysmexMoleculePressDownload_teaserTextWrapper .sysmexMoleculePressDownload_content {
                overflow: hidden;
            }
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner .sysmexMoleculePressDownload_teaserTextWrapper p {
                margin-bottom: 24px;
            }
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner .sysmexMoleculePressDownload_teaserTextWrapper ul {
                margin: 0;
            }
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner .sysmexMoleculePressDownload_teaserTextWrapper ul li {
                    margin-bottom: 16px;
                }
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner .sysmexMoleculePressDownload_teaserTextWrapper .sysmexMoleculePressDownload_category {
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 16px;
                /* font-family: "Source Sans Pro", sans-serif; */

                margin-bottom: 16px;
                text-transform: uppercase;
            }
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner .sysmexMoleculePressDownload_teaserTextWrapper .sysmexMoleculePressDownload_title {
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 18px;
                /* font-family: "Source Sans Pro", sans-serif; */

                word-wrap: break-word;
                margin: 0 0 16px 0;
                color: #000000;
            }
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner .sysmexMoleculePressDownload_teaserTextWrapper .sysmexMoleculePressDownload_link {
                display: flex;
                align-items: center;
                justify-content: end;
                color: #005BAC;
            }
.sysmexMoleculePressDownload_press-download .sysmexMoleculePressDownload_teaserInner .sysmexMoleculePressDownload_teaserTextWrapper .sysmexMoleculePressDownload_icon {
                font-size: 40px;
            }

/* stylelint-disable */
.sysmexMoleculeProductTeaser_productTeaser {
    position: relative;
    max-width: 1440px;
    margin: 0 auto 20px;
}
.sysmexMoleculeProductTeaser_productTeaser .sysmexMoleculeProductTeaser_backgroundImage img {
            width: 100%;
            height: 90vw;
            -o-object-fit: cover;
               object-fit: cover
        }
@media only screen and (min-width: 768px) {
        .sysmexMoleculeProductTeaser_productTeaser .sysmexMoleculeProductTeaser_backgroundImage img {
                height: auto
        }
            }
.sysmexMoleculeProductTeaser_productTeaser .sysmexMoleculeProductTeaser_content {
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center
    }
@media only screen and (min-width: 768px) {

    .sysmexMoleculeProductTeaser_productTeaser .sysmexMoleculeProductTeaser_content {
            flex-direction: row
    }
        }
.sysmexMoleculeProductTeaser_productTeaser .sysmexMoleculeProductTeaser_content > div {
            box-sizing: border-box;
        }
.sysmexMoleculeProductTeaser_productTeaser .sysmexMoleculeProductTeaser_content > div img {
                width: 100%;
                height: auto;
            }
.sysmexMoleculeProductTeaser_productTeaser .sysmexMoleculeProductTeaser_content .sysmexMoleculeProductTeaser_image {
            flex-basis: 60%;
        }
.sysmexMoleculeProductTeaser_productTeaser .sysmexMoleculeProductTeaser_content .sysmexMoleculeProductTeaser_text {
            flex-basis: 40%;
            padding: 0 64px;
            color: #000000;
        }
@media only screen and (min-width: 768px) {
            .sysmexMoleculeProductTeaser_productTeaser.sysmexMoleculeProductTeaser_position--left .sysmexMoleculeProductTeaser_content {
                flex-direction: row-reverse;
            }
        }
.sysmexMoleculeProductTeaser_productTeaser.sysmexMoleculeProductTeaser_is--fullsize .sysmexMoleculeProductTeaser_content {
            flex-direction: column-reverse;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
.sysmexMoleculeProductTeaser_productTeaser.sysmexMoleculeProductTeaser_is--fullsize .sysmexMoleculeProductTeaser_content .sysmexMoleculeProductTeaser_text,
            .sysmexMoleculeProductTeaser_productTeaser.sysmexMoleculeProductTeaser_is--fullsize .sysmexMoleculeProductTeaser_content .sysmexMoleculeProductTeaser_image {
                flex-basis: auto;
            }
.sysmexMoleculeProductTeaser_productTeaser.sysmexMoleculeProductTeaser_is--fullsize .sysmexMoleculeProductTeaser_content .sysmexMoleculeProductTeaser_image {
                max-height: 75%;
            }
.sysmexMoleculeProductTeaser_productTeaser.sysmexMoleculeProductTeaser_is--fullsize .sysmexMoleculeProductTeaser_content .sysmexMoleculeProductTeaser_image img {
                    width: 100%;
                    height: 100%;
                    -o-object-fit: contain;
                       object-fit: contain;
                }

/* stylelint-disable */
.sysmexMoleculeProfessionalityConsent_professionalityConsent {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1005;
    background-color: rgba(0,0,0,0.6);
}
.sysmexMoleculeProfessionalityConsent_professionalityConsent .sysmexMoleculeProfessionalityConsent_content {
        background-color: #FFFFFF;
        width: 1200px;
        box-sizing: border-box;
        max-width: 90vw;
        padding: 24px;
        margin-left: 50vw;
        margin-top: 50vh;
        transform: translateX(-50%) translateY(-50%);
    }
.sysmexMoleculeProfessionalityConsent_professionalityConsent .sysmexMoleculeProfessionalityConsent_content .sysmexMoleculeProfessionalityConsent_denied {
            display: none;
        }
.sysmexMoleculeProfessionalityConsent_professionalityConsent .sysmexMoleculeProfessionalityConsent_content .sysmexMoleculeProfessionalityConsent_title {
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 22px;
            /* font-family: "Source Sans Pro", sans-serif; */

            margin-bottom: 16px;
        }
.sysmexMoleculeProfessionalityConsent_professionalityConsent .sysmexMoleculeProfessionalityConsent_content .sysmexMoleculeProfessionalityConsent_interactions button {
                align-self: stretch;
                margin-bottom: 16px;
            }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeProfessionalityConsent_professionalityConsent .sysmexMoleculeProfessionalityConsent_content .sysmexMoleculeProfessionalityConsent_interactions {
                display: flex;
                justify-content: center;
        }

                .sysmexMoleculeProfessionalityConsent_professionalityConsent .sysmexMoleculeProfessionalityConsent_content .sysmexMoleculeProfessionalityConsent_interactions button:not(:last-child) {
                    margin-right: 16px;
                }
            }
.sysmexMoleculeProfessionalityConsent_professionalityConsent .sysmexMoleculeProfessionalityConsent_content .sysmexMoleculeProfessionalityConsent_footnote {
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 14px;
            /* font-family: "Source Sans Pro", sans-serif; */
        }
.sysmexMoleculeProfessionalityConsent_professionalityConsent.sysmexMoleculeProfessionalityConsent_is--denied .sysmexMoleculeProfessionalityConsent_content .sysmexMoleculeProfessionalityConsent_query {
                display: none;
            }
.sysmexMoleculeProfessionalityConsent_professionalityConsent.sysmexMoleculeProfessionalityConsent_is--denied .sysmexMoleculeProfessionalityConsent_content .sysmexMoleculeProfessionalityConsent_denied {
                display: block;
            }

/* stylelint-disable */
.sysmexMoleculePromotionBox_promotionBox {
    position: relative;
    margin-bottom: auto
}
@media only screen and (min-width: 768px) {
.sysmexMoleculePromotionBox_promotionBox {
        display: flex;
        height: 100%
}
    }
.sysmexMoleculePromotionBox_promotionBox .dialogProviderContainerStyle {
        margin-bottom: 32px;
    }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_titleWrapper {
        position: relative;
    }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_titleWrapper .sysmexMoleculePromotionBox_title {
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #FFFFFF;
            display: inline-block;
            width: 100%
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_titleWrapper .sysmexMoleculePromotionBox_title {
                position: absolute;
                top: 50%;
                transform: translateY(-50%)
        }
            }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_titleWrapper .sysmexMoleculePromotionBox_icon {
            display: none;
        }
@media only screen and (min-width: 480px) {
            .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_titleWrapper .sysmexMoleculePromotionBox_icon {
                display: inline-block;
                margin-right: 16px;
            }
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_introText {
        margin: 16px 0;
        color: #FFFFFF;
    }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_login,
    .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter {
        height: 100%;
        background: #00B8EE;
        padding: 32px;
        color: #FFFFFF
    }
@media only screen and (min-width: 480px) {

    .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_login,
    .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter {
            padding: 40px 48px
    }
        }
@media only screen and (min-width: 992px) {

    .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_login,
    .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter {
            padding: 32px 80px
    }
        }
@media only screen and (min-width: 992px) {

    .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter {
            padding: 32px 80px 0
    }
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter {

        background: #005BAC;
    }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter .sysmexMoleculePromotionBox_newsletterForm form > div:last-child {
            margin: 0;
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter .sysmexMoleculePromotionBox_newsletterForm input[type='checkbox'] {
            margin-left: 0;
            width: 20px;
            height: 20px;
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter .sysmexMoleculePromotionBox_newsletterForm form * div {
            padding-left: 0 !important;
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter form .lp-form-field {
            margin-bottom: 16px;
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter span {
            font-style: normal;
            font-size: 16px;
            font-family: 'Source Sans Pro', sans-serif !important;
            font-weight: 600;
            color: #FFFFFF !important;
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter span label {
                font-size: 18px;
                font-weight: 600;
            }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='text'],
        .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='email'],
        .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='date'] {
            padding: 12px 65px 12px 16px;
            border: 1px solid rgba(0, 0, 0, 0.3);
            border-radius: 3px;
            line-height: 24px;
            margin-top: 6px;
            display: block;
            font-weight: 300;
            font-size: 18px;
            font-family: 'Source Sans Pro', sans-serif;
            color: #333333
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='text']::-moz-placeholder, .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='email']::-moz-placeholder, .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='date']::-moz-placeholder {
                color: #909DA8;
                font-style: italic;
            }
input[type='text']::-moz-placeholder, input[type='email']::-moz-placeholder, input[type='date']::-moz-placeholder {
                color: #909DA8;
                font-style: italic;
            }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='text']::placeholder, .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='email']::placeholder, .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='date']::placeholder {
                color: #909DA8;
                font-style: italic;
            }
@media only screen and (max-width: 1023px) {

        .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='text'],
        .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='email'],
        .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='date'] {
                max-width: none
        }
            }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='email'] {
            width: 49.45% !important
        }
@media only screen and (max-width: 1023px) {

        .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='email'] {
                width: 100% !important
        }
            }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter input[type='date'] {
            max-width: 170px;
            padding-right: 15px;
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter form *[data-layout="true"] {
            max-width: none;
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter form .marketing-field input[type='checkbox'] {
                padding: 0;
                margin: 6px 8px 3px 0;
                height: 14px;
            }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter form .marketing-field input[type='checkbox'] ~ label {
                    font-size: 16px;
                }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter a {
            color: #FFFFFF;
            text-decoration: underline;
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter .columnContainer {
            padding: 0 10px;
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter .columnContainer p {
                color: #FFFFFF;
                margin-bottom: 16px;
            }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter .columnContainer .dividerWrapper td {
                border-bottom-color: #FFFFFF !important;
            }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter .emptyContainer {
            padding: 24px 10px 10px;
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter button {
            display: inline-block;
            box-sizing: border-box;
            border: 1px solid #FFFFFF;
            border-radius: 3px;
            background: #005BAC;
            max-width: 367px;

            font-style: normal;

            font-weight: 600;

            line-height: 24px;

            margin-bottom: 0;

            color: #333333;

            text-transform: none;

            font-size: 18px;

            /* font-family: "Source Sans Pro", sans-serif; */

            color: #FFFFFF;
            margin: 32px 0;
            padding: 24px;
            width: 100%;
            align-self: center;
            text-align: center;
            cursor: pointer;
            transition: color 0.12s, border 0.12s, background-color 0.12s
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter button:hover {
                color: #005BAC;
                background: #FFFFFF;
            }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter .sysmexMoleculePromotionBox_iframe {
            height: 500px;
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletter .marketing-subscription-list {
            margin-bottom: 8px;
        }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_select i {
        color: #005BAC;
    }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_formField label {
        color: #FFFFFF;
    }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_loginSubmit.sysmexMoleculePromotionBox_isOutlined,
    .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_newsletterSubmit {
        margin-top: 24px;
    }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_legalText {
        display: block;
        color: #FFFFFF;
        margin-top: 16px;
    }
.sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_legalText a {
            color: #FFFFFF;
            text-decoration: underline;
        }
@media only screen and (min-width: 992px) {

    .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_legalText {
            margin-top: 0;
            margin-bottom: 24px
    }
        }
@media only screen and (min-width: 992px) {
            .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_fullBox .sysmexMoleculePromotionBox_formField {
                max-width: 49.5%;
                display: inline-block
            }

                .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_fullBox .sysmexMoleculePromotionBox_formField:last-of-type {
                    float: right;
                }

            .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_fullBox .sysmexMoleculePromotionBox_loginSubmit.sysmexMoleculePromotionBox_isOutlined,
            .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_fullBox .sysmexMoleculePromotionBox_newsletterSubmit {
                display: block;
                float: right;
                margin-bottom: 16px;
            }

            .sysmexMoleculePromotionBox_promotionBox .sysmexMoleculePromotionBox_fullBox .sysmexMoleculePromotionBox_legalText {
                float: right;
                width: 100%;
                text-align: right;
            }
        }
.sysmexMoleculePromotionBox_promotionBox:not(.sysmexMoleculePromotionBox_fullBox) .sysmexMoleculePromotionBox_newsletter .sysmexMoleculePromotionBox_iframe {
                height: 500px
            }
@media only screen and (min-width: 768px) {
            .sysmexMoleculePromotionBox_promotionBox:not(.sysmexMoleculePromotionBox_fullBox) .sysmexMoleculePromotionBox_newsletter .sysmexMoleculePromotionBox_iframe {
                    height: 350px
            }
                }
@media only screen and (min-width: 992px) {
            .sysmexMoleculePromotionBox_promotionBox:not(.sysmexMoleculePromotionBox_fullBox) .sysmexMoleculePromotionBox_newsletter .sysmexMoleculePromotionBox_iframe {
                    height: 400px
            }
                }
@media only screen and (min-width: 1280px) {
            .sysmexMoleculePromotionBox_promotionBox:not(.sysmexMoleculePromotionBox_fullBox) .sysmexMoleculePromotionBox_newsletter .sysmexMoleculePromotionBox_iframe {
                    height: 350px
            }
                }
@media only screen and (min-width: 1441px) {
            .sysmexMoleculePromotionBox_promotionBox:not(.sysmexMoleculePromotionBox_fullBox) .sysmexMoleculePromotionBox_newsletter .sysmexMoleculePromotionBox_iframe {
                    height: 320px
            }
                }

/* stylelint-disable */
.sysmexMoleculeQuizModule_quizModule {
    background-color: #d0e1ef;
    position: relative;
    overflow: hidden;
}
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_inner {
        display: flex;
        padding: 0 24px;
        overflow-x: auto;

        /* Hide scrollbar */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none  /* Firefox */
    }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_inner::-webkit-scrollbar {
            display: none; /* Chrome, Safari and Opera */
        }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_progressBar {
        display: flex;
        padding: 16px 0;
    }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_progressBar .sysmexMoleculeQuizModule_progressItem {
            display: flex;
            position: relative;
        }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_progressBar .sysmexMoleculeQuizModule_progressItem .sysmexMoleculeQuizModule_inner {
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 14px;
                /* font-family: "Source Sans Pro", sans-serif; */

                color: #FFFFFF;
                background-color: #005BAC;
                padding: 8px;
                padding-left: 16px;
                display: flex;
                align-items: center;
            }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_progressBar .sysmexMoleculeQuizModule_progressItem .sysmexMoleculeQuizModule_inner .sysmexMoleculeQuizModule_numerator {
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    margin-bottom: 0;
                    color: #333333;
                    text-transform: none;
                    font-size: 18px;
                    /* font-family: "Source Sans Pro", sans-serif; */

                    color: #FFFFFF;
                    width: 32px;
                    height: 32px;
                    border: 1px solid #FFFFFF;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 8px;
                }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_progressBar .sysmexMoleculeQuizModule_progressItem::before {
                content: '';
                display: block;
                position: absolute;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                border-left: 10px solid #d0e1ef;
            }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_progressBar .sysmexMoleculeQuizModule_progressItem::after {
                content: '';
                display: block;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
                border-left: 10px solid #005BAC;
            }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_progressBar .sysmexMoleculeQuizModule_progressItem.sysmexMoleculeQuizModule_is--done .sysmexMoleculeQuizModule_inner .sysmexMoleculeQuizModule_numerator {
                        background-color: #00B8EE;
                        border-color: #00B8EE;
                    }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_progressBar .sysmexMoleculeQuizModule_progressItem.sysmexMoleculeQuizModule_is--active .sysmexMoleculeQuizModule_inner .sysmexMoleculeQuizModule_numerator {
                        background-color: #FFFFFF;
                        color: #005BAC;
                    }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionWrapper {
        position: absolute;
        left: 0;
        padding: 32px;
        background-color: #FFFFFF;
    }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem.sysmexMoleculeQuizModule_is--measured {
            display: none;
        }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem.sysmexMoleculeQuizModule_is--active {
            display: block;
        }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 16px 0
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea {
                flex-direction: row;
        }

                .sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea > div {
                    width: calc(50% - 16px);
                }
            }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_answers {
                margin-bottom: 16px;
            }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_answers .sysmexMoleculeQuizModule_answer .sysmexMoleculeQuizModule_radio,
                    .sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_answers .sysmexMoleculeQuizModule_answer .sysmexMoleculeQuizModule_checkbox {
                        width: 100%;
                        box-sizing: border-box;
                        transition: all 0.2s;
                    }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_answers .sysmexMoleculeQuizModule_answer .sysmexMoleculeQuizModule_radio label, .sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_answers .sysmexMoleculeQuizModule_answer .sysmexMoleculeQuizModule_checkbox label {
                            width: 100%;
                        }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_answers .sysmexMoleculeQuizModule_answer .sysmexMoleculeQuizModule_radio:hover, .sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_answers .sysmexMoleculeQuizModule_answer .sysmexMoleculeQuizModule_checkbox:hover {
                            cursor: pointer;
                            background-color: rgba(0,91,172,0.1);
                            color: #005BAC;
                        }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_answers .sysmexMoleculeQuizModule_answer.sysmexMoleculeQuizModule_is--correct .sysmexMoleculeQuizModule_radio,
                        .sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_answers .sysmexMoleculeQuizModule_answer.sysmexMoleculeQuizModule_is--correct .sysmexMoleculeQuizModule_checkbox {
                            background-color: #0DAC67;
                            color: #FFFFFF;
                        }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_answers .sysmexMoleculeQuizModule_answer.sysmexMoleculeQuizModule_is--false .sysmexMoleculeQuizModule_radio,
                        .sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_answers .sysmexMoleculeQuizModule_answer.sysmexMoleculeQuizModule_is--false .sysmexMoleculeQuizModule_checkbox {
                            background-color: #E7413E;
                            color: #FFFFFF;
                        }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_explanation {
                opacity: 0;
                transition: opacity 0.5s;
            }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem.sysmexMoleculeQuizModule_is--solved .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_answers .sysmexMoleculeQuizModule_answer .sysmexMoleculeQuizModule_radio,
                        .sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem.sysmexMoleculeQuizModule_is--solved .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_answers .sysmexMoleculeQuizModule_answer .sysmexMoleculeQuizModule_checkbox {
                            pointer-events: none;
                        }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_questionItem.sysmexMoleculeQuizModule_is--solved .sysmexMoleculeQuizModule_dividedArea .sysmexMoleculeQuizModule_explanation {
                    opacity: 1;
                }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_navArea {
        display: flex;
        justify-content: space-between;
        background-color: #FFFFFF;
        padding: 32px;
    }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_navArea button {
            max-width: 200px;
            margin: 4px
        }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_navArea button.sysmexMoleculeQuizModule_is--disabled {
                opacity: 0.6;
                pointer-events: none;
                cursor: not-allowed;
            }
.sysmexMoleculeQuizModule_quizModule .sysmexMoleculeQuizModule_successMessage {
        display: none;
    }
.sysmexMoleculeQuizModule_quizModule.sysmexMoleculeQuizModule_is--finished .sysmexMoleculeQuizModule_progressBar,
        .sysmexMoleculeQuizModule_quizModule.sysmexMoleculeQuizModule_is--finished .sysmexMoleculeQuizModule_questionItem,
        .sysmexMoleculeQuizModule_quizModule.sysmexMoleculeQuizModule_is--finished .sysmexMoleculeQuizModule_navArea {
            display: none;
        }
.sysmexMoleculeQuizModule_quizModule.sysmexMoleculeQuizModule_is--finished .sysmexMoleculeQuizModule_successMessage {
            display: block;
            text-align: center;
            padding: 32px;
            color: #FFFFFF;
            background-color: #00B8EE;
        }
.sysmexMoleculeQuizModule_quizModule.sysmexMoleculeQuizModule_special-scrollytelling {
        background-color: #ecc6f3;
    }
.sysmexMoleculeQuizModule_quizModule.sysmexMoleculeQuizModule_special-scrollytelling .sysmexMoleculeQuizModule_progressBar .sysmexMoleculeQuizModule_progressItem .sysmexMoleculeQuizModule_inner {
                    background-color: #bc95c3;
                }
.sysmexMoleculeQuizModule_quizModule.sysmexMoleculeQuizModule_special-scrollytelling .sysmexMoleculeQuizModule_progressBar .sysmexMoleculeQuizModule_progressItem::before {
                    border-left: 10px solid #ecc6f3;
                }
.sysmexMoleculeQuizModule_quizModule.sysmexMoleculeQuizModule_special-scrollytelling .sysmexMoleculeQuizModule_progressBar .sysmexMoleculeQuizModule_progressItem::after {
                    border-left: 10px solid #bc95c3;
                }
.sysmexMoleculeQuizModule_quizModule.sysmexMoleculeQuizModule_special-scrollytelling .sysmexMoleculeQuizModule_progressBar .sysmexMoleculeQuizModule_progressItem.sysmexMoleculeQuizModule_is--active .sysmexMoleculeQuizModule_inner .sysmexMoleculeQuizModule_numerator {
                            color: #bc95c3;
                        }
.sysmexMoleculeQuizModule_quizModule.sysmexMoleculeQuizModule_special-scrollytelling .sysmexMoleculeQuizModule_progressBar .sysmexMoleculeQuizModule_progressItem.sysmexMoleculeQuizModule_is--done .sysmexMoleculeQuizModule_inner .sysmexMoleculeQuizModule_numerator {
                            background-color: #ecc6f3;
                            border-color: #fff;
                        }
.sysmexMoleculeQuizModule_quizModule.sysmexMoleculeQuizModule_special-scrollytelling .sysmexMoleculeQuizModule_successMessage {
            background-color: #bc95c3;
        }
.sysmexMoleculeQuizModule_quizModule.sysmexMoleculeQuizModule_special-scrollytelling .sysmexMoleculeQuizModule_quizBack,
        .sysmexMoleculeQuizModule_quizModule.sysmexMoleculeQuizModule_special-scrollytelling .sysmexMoleculeQuizModule_quizNext {
            background-color: #bc95c3;
            border: 1px solid #bc95c3;
        }

/* stylelint-disable */
.sysmexMoleculeRadioGroup_radioGroup {
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 0;
    color: #333333;
    text-transform: none;
    font-size: 18px;
}
/* font-family: "Source Sans Pro", sans-serif; */
.sysmexMoleculeRadioGroup_radioGroup label {
        margin-right: 8px;
    }
.sysmexMoleculeRadioGroup_radioGroup.sysmexMoleculeRadioGroup_error input {
            border-color: #E7413E;
        }

/* stylelint-disable */
.sysmexMoleculeReagentCard_reagentCard {
    background: #FFFFFF;
    padding: 24px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    flex-direction: column;
    text-decoration: none;
}
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardHeader .sysmexMoleculeReagentCard_coloredLabel {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            transform: translateX(-28px);
        }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardHeader .sysmexMoleculeReagentCard_coloredLabel li {
                list-style: none;
                margin: 0 0.2em 0.2em;
            }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardHeader .sysmexMoleculeReagentCard_label {
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 14px;
            /* font-family: "Source Sans Pro", sans-serif; */

            min-width: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 8px 16px
        }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardHeader .sysmexMoleculeReagentCard_label.sysmexMoleculeReagentCard_textDark {
                color: #000000;
            }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardHeader .sysmexMoleculeReagentCard_label.sysmexMoleculeReagentCard_textWhite {
                color: #FFFFFF;
            }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardBody {
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 14px;
    }
/* font-family: "Source Sans Pro", sans-serif; */
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardBody .sysmexMoleculeReagentCard_title {
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            margin-bottom: 24px;
            color: #333333;
            text-transform: none;
            font-size: 22px;
            /* font-family: "Source Sans Pro", sans-serif; */

            margin-bottom: 16px;
            display: block
        }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardBody .sysmexMoleculeReagentCard_title:hover {
                color: #005BAC;
            }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardBody .sysmexMoleculeReagentCard_properties {
            margin-bottom: 32px;
        }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardBody .sysmexMoleculeReagentCard_properties table {
                width: 100%;
                border-collapse: separate;
                border-spacing: 1px;
            }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardBody .sysmexMoleculeReagentCard_properties table tbody {
                    width: 100%;
                    height: 100%;
                }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardBody .sysmexMoleculeReagentCard_properties table tbody tr td {
                            width: 50%;
                            padding-top: 8px;
                            padding-bottom: 8px;
                            vertical-align: top;
                        }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardFooter {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        color: #005BAC;
        margin-top: auto;
    }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardFooter .sysmexMoleculeReagentCard_links {
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
            color: #005BAC;
        }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardFooter .sysmexMoleculeReagentCard_links a:visited {
                color: #005BAC;
            }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardFooter .sysmexMoleculeReagentCard_links .sysmexMoleculeReagentCard_addToCardLink {
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 18px;
                /* font-family: "Source Sans Pro", sans-serif; */
            }
.sysmexMoleculeReagentCard_reagentCard .sysmexMoleculeReagentCard_cardFooter {

        word-wrap: break-word;
}

/* stylelint-disable */
.sysmexMoleculeRoundedTeaser_roundedTeaser {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
}
.sysmexMoleculeRoundedTeaser_roundedTeaser p,
    .sysmexMoleculeRoundedTeaser_roundedTeaser a {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        font-family: Sysmex, "Source Sans Pro", sans-serif;
        line-height: 32px;
        text-align: center;
    }
.sysmexMoleculeRoundedTeaser_roundedTeaser .sysmexMoleculeRoundedTeaser_pretitle p {
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            margin-bottom: 24px;
            color: #333333;
            text-transform: none;
            font-size: 22px;
            /* font-family: "Source Sans Pro", sans-serif; */

            text-transform: uppercase;
        }
.sysmexMoleculeRoundedTeaser_roundedTeaser .sysmexMoleculeRoundedTeaser_title p {
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
            margin-bottom: 24px;
            color: #333333;
            text-transform: none;
            font-size: 27px;
            /* font-family: "Source Sans Pro", sans-serif; */
        }
.sysmexMoleculeRoundedTeaser_roundedTeaser .sysmexMoleculeRoundedTeaser_icon {
        padding: 16px;
        font-size: 120px;
        box-sizing: border-box;
        margin-bottom: 16px 0;
    }
.sysmexMoleculeRoundedTeaser_roundedTeaser .sysmexMoleculeRoundedTeaser_roundedImage {
        margin-bottom: 32px;
    }
.sysmexMoleculeRoundedTeaser_roundedTeaser .sysmexMoleculeRoundedTeaser_roundedImage img {
            border-radius: 50%;
        }
.sysmexMoleculeRoundedTeaser_roundedTeaser.sysmexMoleculeRoundedTeaser_teaser--isPreview {
        opacity: 0.3;
    }
.sysmexMoleculeRoundedTeaser_roundedTeaser a {
        color: #005BAC;
    }

/* stylelint-disable */
.sysmexMoleculeScrollToTop_scrollToTop {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-bottom: 2px solid #005BAC;
    z-index: 430;
    transform: translateY(300%);
    transition: transform 0.3s ease-out;
    pointer-events: none
}
.sysmexMoleculeScrollToTop_scrollToTop.sysmexMoleculeScrollToTop_visible {
        transform: translateY(0);
    }
.sysmexMoleculeScrollToTop_scrollToTop .sysmexMoleculeScrollToTop_icon {
        position: relative;
        display: inline-block;
        left: 50%;
        transform: translateX(-50%);
        padding: 5px 8px;
        border-radius: 50% 50% 0 0;
        background-color: #005BAC;
        color: #FFFFFF;
        font-size: 48px;
        cursor: pointer;
        pointer-events: all;
    }
.sysmexMoleculeScrollToTop_scrollToTop.sysmexMoleculeScrollToTop_is--NotBottom {
        bottom: 200px;
    }

.sysmexMoleculeSocialMediaBar_socialMediaBar {
    display: inline-block;
    margin-bottom: 16px;
}

    .sysmexMoleculeSocialMediaBar_socialMediaBar ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-block;
        height: 100%
    }

    @media only screen and (min-width: 1280px) {

    .sysmexMoleculeSocialMediaBar_socialMediaBar ul {
            display: flex
    }
        }

    .sysmexMoleculeSocialMediaBar_socialMediaBar ul li {
            margin-right: 4px;
            background-color: #005BAC;
            height: 40px;
            display: inline-block
        }

    @media only screen and (min-width: 1280px) {

        .sysmexMoleculeSocialMediaBar_socialMediaBar ul li {
                margin-right: 8px
        }
            }

    .sysmexMoleculeSocialMediaBar_socialMediaBar ul li i {
                color: #FFFFFF;
                font-size: 40px;
            }

/* stylelint-disable */
.sysmexMoleculeSolrResultCard_solrResultCard {
    background: #FFFFFF;
    padding: 24px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    flex-direction: column;
    text-decoration: none
}
.sysmexMoleculeSolrResultCard_solrResultCard:hover {
        text-decoration: none;
    }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_cardHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_cardHeader .sysmexMoleculeSolrResultCard_tags {
            margin: 8px 0 16px;
            padding: 0;
            display: flex;
            justify-content: center;
            transform: translateX(-28px);
        }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_cardHeader .sysmexMoleculeSolrResultCard_tags li {
                list-style: none;
                margin: 0 0.2em 0.2em;
            }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_cardHeader .sysmexMoleculeSolrResultCard_tag {
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            margin-bottom: 24px;
            color: #333333;
            text-transform: none;
            font-size: 22px;
            /* font-family: "Source Sans Pro", sans-serif; */

            display: flex;
            align-items: center;
            padding: 8px 24px;
            color: #FFFFFF;
        }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_cardHeader i {
            font-size: 32px;
            transform: translateX(-8px);
        }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_cardHeader .sysmexMoleculeSolrResultCard_searchInformation {
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 14px;
            /* font-family: "Source Sans Pro", sans-serif; */

            font-weight: bold;
            color: #0DAC67;
        }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_abstract {
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 14px;
        /* font-family: "Source Sans Pro", sans-serif; */
    }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_title {
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 24px;
        color: #333333;
        text-transform: none;
        font-size: 22px;
        /* font-family: "Source Sans Pro", sans-serif; */

        word-wrap: break-word;
    }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_teaserText {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */
    }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_furtherContent {
        padding-top: 16px;
    }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_furtherContent .sysmexMoleculeSolrResultCard_furtherHeadline {
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            margin-bottom: 24px;
            color: #333333;
            text-transform: none;
            font-size: 22px;
            /* font-family: "Source Sans Pro", sans-serif; */

            margin-bottom: 8px;
        }
.sysmexMoleculeSolrResultCard_solrResultCard:not(.sysmexMoleculeSolrResultCard_hasTags) .sysmexMoleculeSolrResultCard_title {
            margin-bottom: 16px;
        }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_facts {
        margin: 0 0 3em;
    }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_fact {
        display: flex;
        align-items: center;
    }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_fact .sysmexMoleculeSolrResultCard_fact-title-label {
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            margin-bottom: 24px;
            color: #333333;
            text-transform: none;
            font-size: 22px;
            /* font-family: "Source Sans Pro", sans-serif; */

            display: flex;
            align-items: center;
            margin: 0;
        }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_fact .sysmexMoleculeSolrResultCard_fact-title-label .sysmexMoleculeSolrResultCard_fact-title-label_text {
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 18px;
                /* font-family: "Source Sans Pro", sans-serif; */

                width: 200px;
            }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_fact .sysmexMoleculeSolrResultCard_fact-title-label i {
                font-size: 32px;
            }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_fact .sysmexMoleculeSolrResultCard_fact-text {
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            margin-left: 32px;
            margin-top: -2px;
            display: block;
        }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_links {
        display: flex;
        justify-content: flex-end;
    }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_link {
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: uppercase;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        display: inline-block;
        margin-top: auto
    }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_link.sysmexMoleculeSolrResultCard_linkUnobtrusive {
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            margin-right: 24px;
        }
.sysmexMoleculeSolrResultCard_solrResultCard .sysmexMoleculeSolrResultCard_link .sysmexMoleculeSolrResultCard_linkInfos {
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 14px;
            /* font-family: "Source Sans Pro", sans-serif; */
        }

/* stylelint-disable */
.sysmexMoleculeStandardContentElement_standardContentElement {
    margin: 0 24px
}
@media only screen and (min-width: 768px) {
.sysmexMoleculeStandardContentElement_standardContentElement {
        margin: 0 16px
}
    }
@media only screen and (min-width: 992px) {
.sysmexMoleculeStandardContentElement_standardContentElement {
        margin: 0
}
    }
.sysmexMoleculeStandardContentElement_standardContentElement h1 {
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        margin-bottom: 24px;
        color: #333333;
        text-transform: none;
        font-size: 34px;
        /* font-family: "Source Sans Pro", sans-serif; */
    }
.sysmexMoleculeStandardContentElement_standardContentElement h2 {
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        margin-bottom: 24px;
        color: #333333;
        text-transform: none;
        font-size: 27px;
        /* font-family: "Source Sans Pro", sans-serif; */
    }
.sysmexMoleculeStandardContentElement_standardContentElement h3 {
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 24px;
        color: #333333;
        text-transform: none;
        font-size: 22px;
        /* font-family: "Source Sans Pro", sans-serif; */
    }
.sysmexMoleculeStandardContentElement_standardContentElement p,
    .sysmexMoleculeStandardContentElement_standardContentElement ul,
    .sysmexMoleculeStandardContentElement_standardContentElement li {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */
    }
.sysmexMoleculeStandardContentElement_standardContentElement p,
    .sysmexMoleculeStandardContentElement_standardContentElement ul {
        margin-bottom: 24px;
    }
.sysmexMoleculeStandardContentElement_standardContentElement a {
        text-decoration: none;
        color: #005BAC
    }
.sysmexMoleculeStandardContentElement_standardContentElement a:hover {
            text-decoration: underline;
        }
.sysmexMoleculeStandardContentElement_standardContentElement a:active,
        .sysmexMoleculeStandardContentElement_standardContentElement a .sysmexMoleculeStandardContentElement_active {
            color: #00B8EE;
        }
@media only screen and (min-width: 480px) {

    .sysmexMoleculeStandardContentElement_standardContentElement .sysmexMoleculeStandardContentElement_imageWrapper {
            text-align: center
    }
        }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeStandardContentElement_standardContentElement .sysmexMoleculeStandardContentElement_imageWrapper {
            text-align: left;
            text-align: initial
    }
        }
.sysmexMoleculeStandardContentElement_standardContentElement img {
        max-width: 100%;
        height: auto;
        margin-bottom: 24px
    }
@media only screen and (min-width: 480px) {

    .sysmexMoleculeStandardContentElement_standardContentElement img {
            max-width: 60vw
    }
        }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeStandardContentElement_standardContentElement img {
            max-width: 100%
    }
        }
.sysmexMoleculeStandardContentElement_standardContentElement .sysmexMoleculeStandardContentElement_imageDescription {
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 16px;
        /* font-family: "Source Sans Pro", sans-serif; */

        margin: 0;
        transform: translateY(-16px)
    }
@media only screen and (min-width: 480px) {

    .sysmexMoleculeStandardContentElement_standardContentElement .sysmexMoleculeStandardContentElement_imageDescription {
            text-align: center
    }
        }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeStandardContentElement_standardContentElement .sysmexMoleculeStandardContentElement_imageDescription {
            text-align: left
    }
        }
.sysmexMoleculeStandardContentElement_standardContentElement hr {
        margin-bottom: 32px;
        border: 0;
        border: 1px solid rgba(0,0,0,0.1);
    }

/* stylelint-disable */
.sysmexMoleculeSubscriptionCenter_subscriptionCenter {
    width: 100%;
    height: 100%;
    background-color: #005BAC;
    padding: 32px 0 0;
}
.sysmexMoleculeSubscriptionCenter_subscriptionCenter .dialogProviderContainerStyle {
        margin-bottom: 32px;
    }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter .columnContainer * {
        font-family: 'Source Sans Pro', sans-serif;
        color: #FFFFFF;
    }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter .columnContainer * p {
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 600;
        }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter .columnContainer * input[type='checkbox'] {
            margin-top: 8px;
            margin-bottom: 4px;
        }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter .columnContainer * input[type='checkbox'] ~ label,
            .sysmexMoleculeSubscriptionCenter_subscriptionCenter .columnContainer * input[type='checkbox'] ~ span {
                position: relative;
                top: -4px;
                font-weight: 600;
                line-height: 24px;
            }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter .columnContainer td {
        border-bottom-color: #FFFFFF !important;
    }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter span * {
        font-family: 'Source Sans Pro', sans-serif !important;
    }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter span:first-child {
        font-style: normal;
        line-height: 24px;
        margin-bottom: 0;
        text-transform: none;
        font-size: 18px;
        font-family: 'Source Sans Pro', sans-serif;
        width: 100%;
    }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter span.lp-required {
        color: #FFFFFF !important;
        font-size: 18px;
    }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter form input {
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        color: #333333 !important
    }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter form input::-moz-placeholder {
            color: #909DA8;
            font-style: italic;
        }
form input::-moz-placeholder {
            color: #909DA8;
            font-style: italic;
        }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter form input::placeholder {
            color: #909DA8;
            font-style: italic;
        }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter button {
        display: inline-block;
        box-sizing: border-box;
        border: 1px solid #FFFFFF !important;
        border-radius: 3px;
        color: #FFFFFF;
        background-color: #005BAC !important;
        margin: 16px 0 0 !important;
        padding: 24px !important;
        width: 100%;
        height: auto !important;
        text-align: center;
        cursor: pointer;
        transition: color 0.12s, border 0.12s, background-color 0.12s
    }
@media only screen and (min-width: 768px) {

    .sysmexMoleculeSubscriptionCenter_subscriptionCenter button {
            margin: 32px 0 !important
    }
        }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter button:hover {
            color: #005BAC;
            background-color: #FFFFFF !important;
        }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter button.onFormSubmittedFeedbackButton:hover {
                background-color: #00B8EE !important;
            }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter div[data-editorblocktype="Field-checkbox"] {
        padding: 10px;
    }
.sysmexMoleculeSubscriptionCenter_subscriptionCenter .marketing-field {
        color: white;
    }
@media (min-width: 661px) {
    .sysmexMoleculeSubscriptionCenter_subscriptionCenter {
        display: grid;
        align-content: center;
        justify-content: center;
        place-content: center;
    }
}

/* stylelint-disable */
.sysmexMoleculeSurveyModal_surveyModal .sysmexMoleculeSurveyModal_headline {
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 24px;
        color: #333333;
        text-transform: none;
        font-size: 22px;
        /* font-family: "Source Sans Pro", sans-serif; */

        display: block;
        color: #FFFFFF;
    }
.sysmexMoleculeSurveyModal_surveyModal .sysmexMoleculeSurveyModal_closeButton {
        width: 50%
    }
.sysmexMoleculeSurveyModal_surveyModal .sysmexMoleculeSurveyModal_closeButton:hover {
            background: #FFFFFF;
            color: #0DAC67;
        }
.sysmexMoleculeSurveyModal_surveyModal iframe {
        display: block;
    }
.sysmexMoleculeSurveyModal_surveyModal .sysmexMoleculeContentPopup_close {
        color: #005BAC;
    }

/* stylelint-disable */
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper {
        position: relative;
        padding: 24px 0;
        border-top: 1px solid rgba(0,0,0,0.1);
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_indicator {
            position: absolute;
            overflow: hidden;
            top: 0;
            height: calc(100% - 22px);
            padding-top: 22px;
            color: rgba(0,0,0,0.1);
            background-color: #f0f5fa;
            opacity: 0;
            transition: opacity 0.3s, background-color 0.5s;
            width: 24px;
            z-index: 1
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_indicator {
                background-color: transparent
        }
            }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_indicator.sysmexMoleculeTabContainer_has--indicators {
                opacity: 1;
            }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_indicator i {
                color: #D2D2D2;
                transition: color 1s;
                font-size: 32px;
                pointer-events: none;
            }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_indicator.sysmexMoleculeTabContainer_before {
                left: 0;
                transform: translateX(-8px)
            }
@media only screen and (min-width: 992px) {

            .sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_indicator.sysmexMoleculeTabContainer_before {
                    transform: translateX(-24px)
            }
                }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_indicator.sysmexMoleculeTabContainer_before i {
                    margin-left: -5px;
                }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_indicator.sysmexMoleculeTabContainer_after {
                right: 0;
                transform: translateX(8px)
            }
@media only screen and (min-width: 992px) {

            .sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_indicator.sysmexMoleculeTabContainer_after {
                    transform: translateX(24px)
            }
                }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_indicator.sysmexMoleculeTabContainer_is--visible i {
                    color: #333333;
                }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_indicator.sysmexMoleculeTabContainer_is--visible:hover {
                    cursor: pointer;
                }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_indicator.sysmexMoleculeTabContainer_is--visible:hover i {
                        color: #00B8EE;
                    }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_titles {
            overflow-x: auto;
            overflow-y: hidden;
            scrollbar-width: none;
            -ms-overflow-style: none;
            width: 90%;
            margin: 0 auto
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_titles {
                width: 95%
        }
            }
@media only screen and (min-width: 992px) {

        .sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_titles {
                width: 100%
        }
            }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_titles::-webkit-scrollbar {
                height: 0;
            }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_innerTitles {
            display: flex;
            position: relative;
        }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_underlineWrapper .sysmexMoleculeTabContainer_underline {
                height: 4px;
                background-color: #005BAC;
                transition: all 0.5s;
                z-index: 10;
                position: relative;
            }
.sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper .sysmexMoleculeTabContainer_underlineHoverWrapper .sysmexMoleculeTabContainer_underlineHover {
                height: 4px;
                background-color: #00B8EE;
                transition: all 0.5s;
                transform: translateY(-4px);
                z-index: 0;
                position: relative;
            }
@media only screen and (min-width: 1280px) {
        .sysmexMoleculeTabContainer_tabContainer .sysmexMoleculeTabContainer_outerWrapper {
            padding-top: 0;
            border-top: none;
        }
    }

/* stylelint-disable */
.sysmexMoleculeTable_title {
    padding: 8px 16px;
    border-top: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;

    font-style: normal;

    font-weight: 600;

    line-height: 24px;

    margin-bottom: 0;

    color: #333333;

    text-transform: none;

    font-size: 18px

    /* font-family: "Source Sans Pro", sans-serif; */
}
.sysmexMoleculeTable_title.sysmexMoleculeTable_titleDark {
        background-color: rgba(0,91,172,0.1);
    }
.sysmexMoleculeTable_table {
    display: block;
    border-collapse: collapse;
    width: 100%;
    overflow-x: auto
}
@media (max-width: 767px) {
.sysmexMoleculeTable_table {
        white-space: nowrap
}
    }
@media (max-width: 1199px) {
.sysmexMoleculeTable_table {
        width: auto !important;
        height: auto !important
}
    }
@media only screen and (min-width: 1280px) {
.sysmexMoleculeTable_table {
        max-width: 1200px
}
    }
.sysmexMoleculeTable_table .sysmexMoleculeTable_title {
        padding: 0;
        border-top: none;
        border-bottom: none
    }
.sysmexMoleculeTable_table .sysmexMoleculeTable_title.sysmexMoleculeTable_titleDark {
            background-color: rgba(0,91,172,0);
        }
.sysmexMoleculeTable_table {

    font-style: normal;

    font-weight: 400;

    line-height: 26px;

    margin-bottom: 0;

    color: #333333;

    text-transform: none;

    font-size: 18px;
}
/* font-family: "Source Sans Pro", sans-serif; */
.sysmexMoleculeTable_table tr {
        display: table-row;
    }
.sysmexMoleculeTable_table tr td {
            -webkit-hyphens: none;
                    hyphens: none;
            display: table-cell;
            vertical-align: top;
        }
.sysmexMoleculeTable_table tr td hr {
                border-color: rgba(218, 218, 218, 0.4);
                margin: 20px 0;
            }
.sysmexMoleculeTable_table thead,
    .sysmexMoleculeTable_table tbody {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        width: 100%;
        display: table;
    }
.sysmexMoleculeTable_table thead tr td p, .sysmexMoleculeTable_table tbody tr td p {
                    padding-bottom: 0;
                    margin-bottom: 16px;
                }
.sysmexMoleculeTable_table thead tr td a, .sysmexMoleculeTable_table tbody tr td a {
                    color: #005BAC
                }
.sysmexMoleculeTable_table thead tr td a:hover, .sysmexMoleculeTable_table tbody tr td a:hover {
                        color: #00B8EE;
                        text-decoration: none;
                    }
.sysmexMoleculeTable_table thead tr td strong, .sysmexMoleculeTable_table tbody tr td strong {
                    font-weight: 600;
                }
.sysmexMoleculeTable_table thead > tr:nth-child(even), .sysmexMoleculeTable_table tbody > tr:nth-child(even) {
                background-color: rgba(0,91,172,0.1);
            }
.sysmexMoleculeTable_table thead > tr:nth-child(odd), .sysmexMoleculeTable_table tbody > tr:nth-child(odd) {
                background-color: #FFFFFF;
            }
.sysmexMoleculeTable_table thead > tr > td,
            .sysmexMoleculeTable_table thead > tr th,
            .sysmexMoleculeTable_table tbody > tr > td,
            .sysmexMoleculeTable_table tbody > tr th {
                text-align: left;
                padding: 16px
            }
@media only screen and (min-width: 992px) {

            .sysmexMoleculeTable_table thead > tr > td,
            .sysmexMoleculeTable_table thead > tr th,
            .sysmexMoleculeTable_table tbody > tr > td,
            .sysmexMoleculeTable_table tbody > tr th {
                    padding: 16px 32px;
                    width: auto
            }
                }
.sysmexMoleculeTable_table thead > tr > td table, .sysmexMoleculeTable_table thead > tr th table, .sysmexMoleculeTable_table tbody > tr > td table, .sysmexMoleculeTable_table tbody > tr th table {
                    width: calc(100% - 32px);
                }
.sysmexMoleculeTable_table thead > tr > th, .sysmexMoleculeTable_table tbody > tr > th {
                font-weight: 600;
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
                padding: 16px 32px;
            }
.sysmexMoleculeTable_table.sysmexMoleculeTable_headerColumn tbody > tr > th {
                    border: none;
                }
.sysmexMoleculeTable_table.sysmexMoleculeTable_dark tbody > tr:nth-child(even) {
                    background-color: rgba(0,91,172,0.2);
                }
.sysmexMoleculeTable_table.sysmexMoleculeTable_dark tbody > tr:nth-child(odd) {
                    background-color: rgba(0,91,172,0.1);
                }
.sysmexMoleculeTable_table.sysmexMoleculeTable_dark tbody > tr td.sysmexMoleculeTable_summary-table-first-col {
                    font-weight: 400;
                }

/* stylelint-disable */
.sysmexMoleculeTags_tags {
    margin: 8px 0 16px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.sysmexMoleculeTags_tags li {
        list-style: none;
        margin: 0 0.2em 0.2em 0;
    }
.sysmexMoleculeTags_tags li .sysmexMoleculeTags_tag {
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 14px;
            /* font-family: "Source Sans Pro", sans-serif; */

            display: block;
            padding: 8px 24px;
            color: #FFFFFF;
        }
.sysmexMoleculeTags_tags li .sysmexMoleculeTags_tag--isSmall {
                padding: 8px;
            }

/* stylelint-disable */
.sysmexMoleculeTeaser_teaser {
    height: 100%;
    position: relative;
}
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner {
        position: relative;
        text-align: center;
        display: flex;
        flex-direction: column;
        -ms-flex-negative: 0;
        background-color: #FFFFFF;
        height: 100%;
    }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_preview {
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            color: #FFFFFF;
            background-color: #005BAC;
            padding: 8px;
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 8px;
            line-height: 1em;
        }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner > div {
            -ms-flex-negative: 0;
        }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner img {
            display: block;
            -o-object-fit: cover;
               object-fit: cover;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_icon {
            padding: 8px;
            font-size: 120px;
        }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserTextWrapper {
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            z-index: 1;
            bottom: 0;
            padding: 24px;
            background-color: #FFFFFF;
            text-align: left;
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
            -ms-flex-negative: 0;
            justify-content: space-between;
            margin-top: 0;
            min-height: 96px;
            box-sizing: border-box;
        }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserTextWrapper .sysmexMoleculeTeaser_content {
                overflow: hidden;
            }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserTextWrapper p {
                margin-bottom: 24px;
            }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserTextWrapper ul {
                margin: 0;
            }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserTextWrapper ul li {
                    margin-bottom: 16px;
                }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserTextWrapper .sysmexMoleculeTeaser_linkText {
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 0;
                color: #333333;
                text-transform: none;
                font-size: 18px;
                /* font-family: "Source Sans Pro", sans-serif; */

                color: #000000;
                margin: 0
            }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserTextWrapper .sysmexMoleculeTeaser_linkText:hover {
                    color: #00B8EE;
                }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserTextWrapper .sysmexMoleculeTeaser_title {
                text-transform: uppercase;
                margin-bottom: 16px;
                font-family: Sysmex, "Source Sans Pro", sans-serif;
            }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserTextWrapper .sysmexMoleculeTeaser_title p {
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    margin-bottom: 0;
                    color: #333333;
                    text-transform: uppercase;
                    font-size: 18px;
                    /* font-family: "Source Sans Pro", sans-serif; */

                    margin: 0 0 8px 0;
                    color: #000000;
                }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner.sysmexMoleculeTeaser_teaser--isPreview img,
            .sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner.sysmexMoleculeTeaser_teaser--isPreview .sysmexMoleculeTeaser_teaserTextWrapper {
                opacity: 0.3;
            }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner.sysmexMoleculeTeaser_teaser--isPreview .sysmexMoleculeTeaser_teaserTextWrapper .sysmexMoleculeTeaser_title:hover, .sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner.sysmexMoleculeTeaser_teaser--isPreview .sysmexMoleculeTeaser_teaserTextWrapper .sysmexMoleculeTeaser_linkText:hover {
                        color: #00B8EE;
                    }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner.sysmexMoleculeTeaser_teaser--isCenter .sysmexMoleculeTeaser_teaserTextWrapper {
                text-align: center;
                margin-top: 0;
                height: 56px;
            }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_teaserInner.sysmexMoleculeTeaser_teaser--isCenter .sysmexMoleculeTeaser_teaserTextWrapper a {
                    margin-top: 0;
                }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_button {
        align-self: auto;
    }
.sysmexMoleculeTeaser_teaser:not(.sysmexMoleculeTeaser_isImageLeft):not(.sysmexMoleculeTeaser_isImageRight) .sysmexMoleculeTeaser_button {
        max-width: none;
    }
.sysmexMoleculeTeaser_teaser .sysmexMoleculeTeaser_body {
        flex-grow: 1;
    }
.sysmexMoleculeTeaser_layout--product .sysmexMoleculeTeaser_teaserImage {
        overflow: hidden;
    }
.sysmexMoleculeTeaser_layout--product .sysmexMoleculeTeaser_teaserImage img {
            transform: scale(0.9);
            transition: 0.5s transform;
            aspect-ratio: 1;
            -o-object-fit: contain;
               object-fit: contain;
            will-change: transform;
        }
.sysmexMoleculeTeaser_layout--product:hover .sysmexMoleculeTeaser_teaserImage img {
                transform: scale(1);
            }
@media only screen and (min-width: 768px) {
    .sysmexMoleculeTeaser_isImageLeft .sysmexMoleculeTeaser_teaserInner {
            flex-direction: row
    }
        }
.sysmexMoleculeTeaser_isImageLeft .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserTextWrapper .sysmexMoleculeTeaser_linkText {
                text-align: right;
            }
@media only screen and (min-width: 768px) {
    .sysmexMoleculeTeaser_isImageRight .sysmexMoleculeTeaser_teaserInner {
            flex-direction: row-reverse
    }
        }
.sysmexMoleculeTeaser_isImageRight .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserTextWrapper .sysmexMoleculeTeaser_linkText {
                text-align: left;
            }
.sysmexMoleculeTeaser_isImageLeft .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserImage, .sysmexMoleculeTeaser_isImageRight .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserImage {
            flex-basis: 33%;
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeTeaser_isImageLeft .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserTextWrapper, .sysmexMoleculeTeaser_isImageRight .sysmexMoleculeTeaser_teaserInner .sysmexMoleculeTeaser_teaserTextWrapper {
                flex-basis: 66%
        }
            }

/* stylelint-disable */
.sysmexMoleculeTeaserTile_teaserTile {
    display: flex;
    flex-direction: column;
    position: relative;
}
.sysmexMoleculeTeaserTile_teaserTile > div {
        -ms-flex-negative: 0;
    }
.sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_image {
        width: 100%;
        order: 1
    }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_image.sysmexMoleculeTeaserTile_is--rightImage {
                order: 2
        }
            }
.sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_imageWrap {
            height: 100%;
        }
.sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_imageWrap img {
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                   object-fit: cover;
            }
.sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_content {
        width: 100%;
        position: static;
        bottom: 0;
        padding: 24px 0;
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: -16px;
        margin-bottom: 16px;
        order: 2
    }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_content.sysmexMoleculeTeaserTile_is--rightImage {
                order: 1
        }
            }
@media only screen and (min-width: 768px) {

    .sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_content {
            position: absolute;
            margin-top: 0;
            margin-bottom: 0
    }
        }
.sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_content .sysmexMoleculeTeaserTile_title {
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            margin-bottom: 24px;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            font-size: 14px;
            text-transform: uppercase;
            color: #FFFFFF;
            padding-left: 24px;
            padding-right: 24px;
            margin-bottom: 16px;
            white-space: normal;
            overflow: hidden;
            text-overflow: ellipsis;
        }
.sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_content .sysmexMoleculeTeaserTile_text p {
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 23px;
            /* font-family: "Source Sans Pro", sans-serif; */

            font-family: Sysmex, "Source Sans Pro", sans-serif;
            color: #FFFFFF;
            padding-left: 24px;
            padding-right: 24px;
            margin-bottom: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_content .sysmexMoleculeTeaserTile_text p {
                max-height: 140px;
                white-space: normal;
                overflow: hidden
        }
            }
.sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_content .sysmexMoleculeTeaserTile_link {
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 16px;
            /* font-family: "Source Sans Pro", sans-serif; */

            font-weight: 300;
            color: #FFFFFF;
            padding-left: 24px;
            padding-right: 24px;
            margin-bottom: 4px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis
        }
.sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_content .sysmexMoleculeTeaserTile_link:hover {
                text-decoration: underline;
            }
.sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_isDarkColor .sysmexMoleculeTeaserTile_content .sysmexMoleculeTeaserTile_title,
            .sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_isDarkColor .sysmexMoleculeTeaserTile_content .sysmexMoleculeTeaserTile_text,
            .sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_isDarkColor .sysmexMoleculeTeaserTile_content .sysmexMoleculeTeaserTile_link {
                color: #005BAC;
            }
.sysmexMoleculeTeaserTile_teaserTile.sysmexMoleculeTeaserTile_teaser--isBig {
        flex-direction: column;
    }
.sysmexMoleculeTeaserTile_teaserTile.sysmexMoleculeTeaserTile_teaser--isBig .sysmexMoleculeTeaserTile_image {
            width: 100%;
        }
.sysmexMoleculeTeaserTile_teaserTile.sysmexMoleculeTeaserTile_teaser--isBig .sysmexMoleculeTeaserTile_content {
            text-align: center;
            width: 100%;
            padding: 24px 0;
            order: 2;
            margin-top: -8px;
            margin-bottom: 16px
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeTeaserTile_teaserTile.sysmexMoleculeTeaserTile_teaser--isBig .sysmexMoleculeTeaserTile_content {
                text-align: left;
                margin-top: 0;
                margin-bottom: 0;
                position: absolute;
                bottom: 0
        }
            }
@media only screen and (min-width: 768px) {

            .sysmexMoleculeTeaserTile_teaserTile.sysmexMoleculeTeaserTile_teaser--isBig .sysmexMoleculeTeaserTile_content .sysmexMoleculeTeaserTile_text {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis
            }
                }
.sysmexMoleculeTeaserTile_teaserTile.sysmexMoleculeTeaserTile_teaser--isSingleTeaser {
        height: auto;
    }
.sysmexMoleculeTeaserTile_teaserTile.sysmexMoleculeTeaserTile_teaser--isSingleTeaser img {
            display: block;
        }
.sysmexMoleculeTeaserTile_teaserTile.sysmexMoleculeTeaserTile_teaser--isSingleTeaser .sysmexMoleculeTeaserTile_content {
            position: unset;
        }
@media only screen and (min-width: 1280px) {
.sysmexMoleculeTeaserTile_teaserTile {
        height: 300px;
        overflow: hidden
}
    }
@media only screen and (min-width: 768px) {
.sysmexMoleculeTeaserTile_teaserTile {
        flex-direction: row
}

        .sysmexMoleculeTeaserTile_teaserTile.sysmexMoleculeTeaserTile_teaser--isBig {
            height: 600px;
        }

        .sysmexMoleculeTeaserTile_teaserTile.sysmexMoleculeTeaserTile_teaser--isSingleTeaser {
            height: auto;
        }

        .sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_image {
            width: 50%;

            /* margin-bottom: -4px; */
        }

        .sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_content {
            width: 50%;
            position: static;
            padding: 0;
        }
    }
.sysmexMoleculeTeaserTile_teaserTile .sysmexMoleculeTeaserTile_teaser--isPreview img {
            opacity: 0.3;
        }
.sysmexMoleculeTeaserTile_isPreviewLink {
    cursor: default;
    text-decoration: none;
    pointer-events: none;
}
.sysmexMoleculeTeaserTile_isPreviewLink .sysmexMoleculeTeaserTile_previewContent {
        opacity: 0.3;
    }

/* stylelint-disable */
.sysmexMoleculeTextImageCard_wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #FFFFFF
}
@media only screen and (min-width: 992px) {
.sysmexMoleculeTextImageCard_wrapper {
        flex-direction: row
}
    }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_imageWrapper {
        height: auto;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
    }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_imageWrapper figure {
            margin: 0;
        }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_imageWrapper figcaption {
            display: none;
        }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_imageWrapper .sysmexMoleculeTextImageCard_image {
            margin: 16px;
        }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_imageWrapper .sysmexMoleculeTextImageCard_image img {
                width: 100%;
                height: auto;
            }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_imageWrapper .sysmexMoleculeTextImageCard_galleryWrapper figure a::before {
                        right: 16px;
                    }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_imageWrapper {
            width: 352px
    }
        }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper {
        padding: 16px 0;
        display: flex;
        justify-content: flex-start;
        flex-direction: column
    }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper {
            width: 100%
    }
        }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_tags {
            margin: 0 0 24px;
            display: flex;
            justify-content: flex-start;
        }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_tags li {
                list-style: none;
                margin: 0 0.2em 0 0;
            }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_tags li .sysmexMoleculeTextImageCard_tag {
                    font-style: normal;
                    font-weight: 600;
                    line-height: 16px;
                    margin-bottom: 0;
                    color: #333333;
                    text-transform: none;
                    font-size: 14px;
                    /* font-family: "Source Sans Pro", sans-serif; */

                    display: inline-block;
                    padding: 8px 24px;
                    color: #FFFFFF;
                }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content {
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 16px;
        }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_infos .sysmexMoleculeTextImageCard_date {
                    font-style: normal;
                    font-weight: 900;
                    line-height: 16px;
                    margin-bottom: 16px;
                    color: #333333;
                    text-transform: none;
                    font-size: 14px;
                    /* font-family: "Source Sans Pro", sans-serif; */
                }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_infos .sysmexMoleculeTextImageCard_title {
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                    margin-bottom: 24px;
                    color: #333333;
                    text-transform: none;
                    font-size: 22px;
                    /* font-family: "Source Sans Pro", sans-serif; */

                    font-family: Sysmex, "Source Sans Pro", sans-serif;
                }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_infos .sysmexMoleculeTextImageCard_description {
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                    margin-bottom: 0;
                    color: #333333;
                    text-transform: none;
                    font-size: 18px;
                    /* font-family: "Source Sans Pro", sans-serif; */
                }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_links {
                display: flex;
                justify-content: flex-end;
            }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_links .sysmexMoleculeTextImageCard_download,
                .sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_links .sysmexMoleculeTextImageCard_details {
                    display: inline-block;
                    padding: 8px 0 8px 8px;
                    text-decoration: none
                }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_links .sysmexMoleculeTextImageCard_download:hover, .sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_links .sysmexMoleculeTextImageCard_details:hover {
                        color: #00B8EE;
                    }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_links .sysmexMoleculeTextImageCard_download {
                    font-style: normal;
                    font-weight: 600;
                    line-height: 24px;
                    margin-bottom: 0;
                    color: #333333;
                    text-transform: none;
                    font-size: 18px;
                    /* font-family: "Source Sans Pro", sans-serif; */
                }
.sysmexMoleculeTextImageCard_wrapper .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_links .sysmexMoleculeTextImageCard_details {
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    margin-bottom: 0;
                    color: #333333;
                    text-transform: none;
                    font-size: 18px;
                    /* font-family: "Source Sans Pro", sans-serif; */
                }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="small"] {
        height: 100%;
        flex-direction: row
    }
@media only screen and (min-width: 768px) {
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="small"] {
            flex-direction: row
    }
        }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="small"] .sysmexMoleculeTextImageCard_imageWrapper {
            flex: 0 40%;
        }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="small"] .sysmexMoleculeTextImageCard_contentWrapper {
            padding: 0 0 8px 0;
            flex: 0 60%;
        }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="small"] .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_tags {
                margin: 0 0 16px 0;
            }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="small"] .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_tags li {
                    margin: 0 0.2em 0.2em 0;
                }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="small"] .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_infos {
                    padding: 0 16px 8px 16px;
                }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="small"] .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_infos .sysmexMoleculeTextImageCard_title {
                        font-style: normal;
                        font-weight: 600;
                        line-height: 28px;
                        margin-bottom: 24px;
                        color: #333333;
                        text-transform: none;
                        font-size: 22px;
                        /* font-family: "Source Sans Pro", sans-serif; */

                        word-break: break-word;
                        -webkit-hyphens: auto;
                                hyphens: auto;
                    }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="small"] .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_links .sysmexMoleculeTextImageCard_details {
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        margin-bottom: 0;
                        color: #333333;
                        text-transform: none;
                        font-size: 14px;
                        /* font-family: "Source Sans Pro", sans-serif; */

                        font-weight: 300;
                    }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="small"] .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_content .sysmexMoleculeTextImageCard_links .sysmexMoleculeTextImageCard_download {
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        margin-bottom: 0;
                        color: #333333;
                        text-transform: none;
                        font-size: 14px;
                        /* font-family: "Source Sans Pro", sans-serif; */

                        font-weight: 600;
                    }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="compressed"] .sysmexMoleculeTextImageCard_imageWrapper {
            display: none;
        }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="compressed"] .sysmexMoleculeTextImageCard_contentWrapper {
            width: 100%;
        }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="compressed"] .sysmexMoleculeTextImageCard_contentWrapper .sysmexMoleculeTextImageCard_description {
                display: none;
            }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="imageHeadline"],
    .sysmexMoleculeTextImageCard_wrapper[data-cardLayout="headlineImage"] {
        flex-direction: column;
        height: 100%;
    }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="imageHeadline"] .sysmexMoleculeTextImageCard_imageWrapper, .sysmexMoleculeTextImageCard_wrapper[data-cardLayout="headlineImage"] .sysmexMoleculeTextImageCard_imageWrapper {
            width: 100%;
        }
@media only screen and (min-width: 992px) {

        .sysmexMoleculeTextImageCard_wrapper[data-cardLayout="imageHeadline"] .sysmexMoleculeTextImageCard_contentWrapper, .sysmexMoleculeTextImageCard_wrapper[data-cardLayout="headlineImage"] .sysmexMoleculeTextImageCard_contentWrapper {
                width: auto
        }
            }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="imageHeadline"] .sysmexMoleculeTextImageCard_tags, .sysmexMoleculeTextImageCard_wrapper[data-cardLayout="headlineImage"] .sysmexMoleculeTextImageCard_tags {
            min-height: 70.4px;
        }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="imageHeadline"] .sysmexMoleculeTextImageCard_description, .sysmexMoleculeTextImageCard_wrapper[data-cardLayout="headlineImage"] .sysmexMoleculeTextImageCard_description {
            display: none;
        }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="headlineImage"] .sysmexMoleculeTextImageCard_title {
            min-height: 0
        }
@media only screen and (min-width: 992px) {
        .sysmexMoleculeTextImageCard_wrapper[data-cardLayout="headlineImage"] .sysmexMoleculeTextImageCard_title {
                min-height: 95px
        }
            }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="headlineImage"] .sysmexMoleculeTextImageCard_imageWrapper {
            order: 1;
        }
.sysmexMoleculeTextImageCard_wrapper[data-cardLayout="headlineImage"] .sysmexMoleculeTextImageCard_imageWrapper .sysmexMoleculeTextImageCard_image {
                overflow: hidden;
            }

/* stylelint-disable */
@media only screen and (min-width: 992px) {
            .sysmexMoleculeTextMediaGallery_gallery .sysmexMoleculeTextMediaGallery_column .sysmexMoleculeTextMediaGallery_image,
            .sysmexMoleculeTextMediaGallery_gallery .sysmexMoleculeTextMediaGallery_column .sysmexMoleculeTextMediaGallery_video {
                margin: 0 auto;
            }
                .sysmexMoleculeTextMediaGallery_gallery .sysmexMoleculeTextMediaGallery_column:not(:last-child) .sysmexMoleculeTextMediaGallery_image,
                .sysmexMoleculeTextMediaGallery_gallery .sysmexMoleculeTextMediaGallery_column:not(:last-child) .sysmexMoleculeTextMediaGallery_video {
                    margin-bottom: 24px;
                }
    }
.sysmexMoleculeTextMediaGallery_gallery figure {
        margin: 0;
        display: table
    }
.sysmexMoleculeTextMediaGallery_gallery figure.sysmexMoleculeTextMediaGallery_video {
            display: block;
        }
.sysmexMoleculeTextMediaGallery_gallery figure.sysmexMoleculeTextMediaGallery_video .sysmexMoleculeTextMediaGallery_imageCaption {
                display: block;
            }
.sysmexMoleculeTextMediaGallery_gallery figure.sysmexMoleculeTextMediaGallery_image,
        .sysmexMoleculeTextMediaGallery_gallery figure.sysmexMoleculeTextMediaGallery_video {
            margin-bottom: 16px
        }
@media only screen and (min-width: 480px) {

        .sysmexMoleculeTextMediaGallery_gallery figure.sysmexMoleculeTextMediaGallery_image,
        .sysmexMoleculeTextMediaGallery_gallery figure.sysmexMoleculeTextMediaGallery_video {
                text-align: center
        }
            }
@media only screen and (min-width: 992px) {

        .sysmexMoleculeTextMediaGallery_gallery figure.sysmexMoleculeTextMediaGallery_image,
        .sysmexMoleculeTextMediaGallery_gallery figure.sysmexMoleculeTextMediaGallery_video {
                text-align: left;
                text-align: initial
        }
            }
.sysmexMoleculeTextMediaGallery_gallery figure.sysmexMoleculeTextMediaGallery_image img, .sysmexMoleculeTextMediaGallery_gallery figure.sysmexMoleculeTextMediaGallery_video img {
                max-width: 100%;
                height: auto;
                vertical-align: bottom;
            }
.sysmexMoleculeTextMediaGallery_gallery .sysmexMoleculeTextMediaGallery_imageCaption {
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 16px;
        /* font-family: "Source Sans Pro", sans-serif; */

        margin: 0;
        margin-top: 8px;
        font-weight: 300;
        transform: translateY(-8px);
        font-size: 16px
    }
@media only screen and (min-width: 480px) {

    .sysmexMoleculeTextMediaGallery_gallery .sysmexMoleculeTextMediaGallery_imageCaption {
            text-align: center
    }
        }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeTextMediaGallery_gallery .sysmexMoleculeTextMediaGallery_imageCaption {
            text-align: left
    }
        }
.sysmexMoleculeTextMediaGallery_gallery iframe {
        border: 0;
    }

/* stylelint-disable */
.sysmexMoleculeTextMedia_textMedia .sysmexMoleculeTextMedia_bodytext {
        width: 100%
    }
.sysmexMoleculeTextMedia_textMedia .sysmexMoleculeTextMedia_bodytext.sysmexMoleculeTextMedia_has--images {
            margin-bottom: 40px;
        }
.sysmexMoleculeTextMedia_textMedia .sysmexMoleculeTextMedia_bodytext ul {
            padding-left: 25px;
        }
.sysmexMoleculeTextMedia_textMedia .sysmexMoleculeTextMedia_bodytext ul li {
                word-break: break-word;
            }
.sysmexMoleculeTextMedia_textMedia p,
    .sysmexMoleculeTextMedia_textMedia ul,
    .sysmexMoleculeTextMedia_textMedia ol {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px
        /* font-family: "Source Sans Pro", sans-serif; */
    }
.sysmexMoleculeTextMedia_textMedia p:not(:last-child), .sysmexMoleculeTextMedia_textMedia ul:not(:last-child), .sysmexMoleculeTextMedia_textMedia ol:not(:last-child) {
            margin-bottom: 16px;
        }
.sysmexMoleculeTextMedia_textMedia li {
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 18px;
        /* font-family: "Source Sans Pro", sans-serif; */

        font-family: "Source Sans Pro", sans-serif
    }
.sysmexMoleculeTextMedia_textMedia li:not(:last-of-type) {
            margin-bottom: 8px;
        }
.sysmexMoleculeTextMedia_textMedia a {
        text-decoration: none;
        color: #005BAC
    }
.sysmexMoleculeTextMedia_textMedia a:hover {
            text-decoration: underline;
        }
.sysmexMoleculeTextMedia_textMedia a:active,
        .sysmexMoleculeTextMedia_textMedia a .sysmexMoleculeTextMedia_active {
            color: #00B8EE;
        }
.sysmexMoleculeTextMedia_textMedia a.sysmexAtomButton_button {
            text-decoration: none;
            color: #fff
        }
.sysmexMoleculeTextMedia_textMedia a.sysmexAtomButton_button:hover {
                text-decoration: none;
            }
.sysmexMoleculeTextMedia_textMedia .sysmexMoleculeTextMedia_galleryWrapper {
        display: flex;
        justify-content: center;
        flex-flow: column;
    }
.sysmexMoleculeTextMedia_textMedia blockquote {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 24px !important;
        padding: 8px 0 8px 32px;
        font-style: italic;
    }
.sysmexMoleculeTextMedia_textMedia blockquote p,
        .sysmexMoleculeTextMedia_textMedia blockquote h1,
        .sysmexMoleculeTextMedia_textMedia blockquote h2,
        .sysmexMoleculeTextMedia_textMedia blockquote h3,
        .sysmexMoleculeTextMedia_textMedia blockquote h4,
        .sysmexMoleculeTextMedia_textMedia blockquote h5,
        .sysmexMoleculeTextMedia_textMedia blockquote h6 {
            margin-bottom: 0;
            font-style: italic;
        }
.sysmexMoleculeTextMedia_textMedia.sysmexMoleculeTextMedia_rulerAfter,
    .sysmexMoleculeTextMedia_textMedia hr {
        margin-bottom: 32px;
        border-bottom: 2px solid rgba(0,0,0,0.1);
    }

/* stylelint-disable */
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper {
        position: relative;
        padding: 24px 0;
        border-top: 1px solid rgba(0,0,0,0.1);
        border-bottom: 1px solid rgba(0,0,0,0.1);
        margin-bottom: 32px;
    }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_indicator {
            position: absolute;
            top: 0;
            height: calc(100% - 22px);
            padding-top: 22px;
            color: rgba(0,0,0,0.1);
            background-color: transparent;
            opacity: 0;
            transition: opacity 0.3s, background-color 0.5s;
            width: 24px
        }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_indicator.sysmexMoleculeThirdLevelNavigation_has--indicators {
                opacity: 1;
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_indicator i {
                color: #D2D2D2;
                transition: color 1s;
                font-size: 32px;
                pointer-events: none;
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_indicator.sysmexMoleculeThirdLevelNavigation_before {
                left: 0;
                transform: translateX(-24px);
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_indicator.sysmexMoleculeThirdLevelNavigation_before i {
                    margin-left: -5px;
                }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_indicator.sysmexMoleculeThirdLevelNavigation_after {
                right: 0;
                transform: translateX(24px);
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_indicator.sysmexMoleculeThirdLevelNavigation_is--visible i {
                    color: #333333;
                }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_indicator.sysmexMoleculeThirdLevelNavigation_is--visible:hover {
                    cursor: pointer;
                }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_indicator.sysmexMoleculeThirdLevelNavigation_is--visible:hover i {
                        color: #00B8EE;
                    }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_titles {
            overflow-x: auto;
            overflow-y: hidden;
            scrollbar-width: none;
            -ms-overflow-style: none
        }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_titles::-webkit-scrollbar {
                height: 0;
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_innerTitles {
            display: flex;
            position: relative
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_innerTitles {
                justify-content: space-between
        }
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_title {
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: none;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            border-bottom: 4px solid transparent;
            margin: 0 20px 0 12px;
            transition: all 0.2s;
            cursor: pointer;
            white-space: nowrap
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_title {
                margin: 0
        }
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_title:first-child {
                margin-left: 0;
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_title:last-child {
                margin-right: 0;
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_title.sysmexMoleculeThirdLevelNavigation_tab--isActive {
                color: #005BAC;
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_title:hover {
                color: #00B8EE;
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_title a,
            .sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_title span {
                padding: 0 5px;
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_title .sysmexMoleculeThirdLevelNavigation_disabled {
                line-height: 1;
                color: #909DA8
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_title .sysmexMoleculeThirdLevelNavigation_disabled:hover {
                    cursor: default;
                    color: #909DA8;
                }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_underlineWrapper .sysmexMoleculeThirdLevelNavigation_underline {
                height: 4px;
                background-color: #005BAC;
                transition: all 0.5s;
                z-index: 10;
                position: relative;
            }
.sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper .sysmexMoleculeThirdLevelNavigation_underlineHoverWrapper .sysmexMoleculeThirdLevelNavigation_underlineHover {
                height: 4px;
                background-color: #00B8EE;
                transition: all 0.5s;
                transform: translateY(-4px);
                z-index: 0;
                position: relative;
            }
@media only screen and (min-width: 1280px) {
        .sysmexMoleculeThirdLevelNavigation_thirdLevelNavigation .sysmexMoleculeThirdLevelNavigation_outerWrapper {
            border-top: none;
        }
    }

/* stylelint-disable */
.sysmexMoleculeVerticalNavigation_linkList {
    padding-left: 0;
    margin: 0;
    list-style: none;
    font-family: Sysmex, "Source Sans Pro", sans-serif;
}
.sysmexMoleculeVerticalNavigation_linkList li {
        padding: 0;
        background: 0
    }
@media only screen and (min-width: 768px) {

    .sysmexMoleculeVerticalNavigation_linkList li {
            padding: 8px 0
    }
        }
@media only screen and (min-width: 992px) {

    .sysmexMoleculeVerticalNavigation_linkList li {
            padding: 12px 0
    }
        }
.sysmexMoleculeVerticalNavigation_linkList li:nth-child(1) {
            border-top: 1px solid rgba(0,0,0,0.1)
        }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeVerticalNavigation_linkList li:nth-child(1) {
                border: none
        }
            }
.sysmexMoleculeVerticalNavigation_linkList li a {
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 0;
            color: #333333;
            text-transform: uppercase;
            font-size: 18px;
            /* font-family: "Source Sans Pro", sans-serif; */

            font-weight: 400;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            display: block;
            padding: 12px 0;
            margin-bottom: 0;
            line-height: 24px
        }
.sysmexMoleculeVerticalNavigation_linkList li a:hover {
                color: #00B8EE;
            }
@media only screen and (min-width: 768px) {

        .sysmexMoleculeVerticalNavigation_linkList li a {
                border: none;
                padding: 0;
                margin-bottom: 0;
                white-space: nowrap
        }
            }
.sysmexMoleculeVerticalNavigation_linkList li.sysmexMoleculeVerticalNavigation_active a {
                color: #005BAC;
            }

/* stylelint-disable */
.sysmexOrganismFooter_footer {
    border-top: 1px solid #D2D2D2;
    background-color: #FFFFFF;
}
.sysmexOrganismFooter_footer a {
        color: #005BAC
    }
.sysmexOrganismFooter_footer a:hover {
            color: #00B8EE;
        }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_contentWrapper {
        max-width: 1200px;
        width: auto;
        margin-left: 0;
        margin-right: 0;

        padding-bottom: 24px;
        padding-top: 40px;
        margin-left: 24px;
        margin-right: 24px
    }
@media only screen and (min-width: 992px) {

    .sysmexOrganismFooter_footer .sysmexOrganismFooter_contentWrapper {
            max-width: 1200px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            padding-bottom: 0
    }
        }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_contentWrapper > .sysmexOrganismFooter_row {
            margin: 0;
        }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_addressWrapper {
        display: flex;
    }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_addressWrapper > div:first-of-type {
            display: inline-block;
            vertical-align: top;
            line-height: 32px;
            margin-right: 24px;
        }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_addressWrapper .sysmexOrganismFooter_contactItem {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_addressWrapper .sysmexOrganismFooter_contactItem a {
                color: #333333
            }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_addressWrapper .sysmexOrganismFooter_contactItem a:hover {
                    color: #00B8EE;
                }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_addressWrapper .sysmexOrganismFooter_contactIcon {
            font-size: 32px;
            color: #005BAC;
            padding-right: 8px;
        }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_headline {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 22px;
        /* font-family: "Source Sans Pro", sans-serif; */

        text-transform: uppercase;
        margin-bottom: calc(8px + 4px);
        font-family: Sysmex, "Source Sans Pro", sans-serif;
    }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_bodyNormal {
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 14px;
        /* font-family: "Source Sans Pro", sans-serif; */

        line-height: 24px;
        color: #333333;
        padding-bottom: 24px;
    }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_noPadding {
        padding-bottom: 0;
    }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_paddingTop8 {
        padding-top: 8px;
    }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_paddingTop12 {
        padding-top: 12px;
    }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_smallNormal {
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 0;
        color: #333333;
        text-transform: none;
        font-size: 14px;
        /* font-family: "Source Sans Pro", sans-serif; */
    }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_socialIcons {
        margin-bottom: 32px;
        display: flex;
        justify-content: space-between
    }
@media only screen and (min-width: 768px) {

    .sysmexOrganismFooter_footer .sysmexOrganismFooter_socialIcons {
            margin-bottom: 16px;
            justify-content: unset
    }
        }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_socialIcons .sysmexOrganismFooter_socialIcon {
            font-size: 38px
        }
@media only screen and (min-width: 768px) {

        .sysmexOrganismFooter_footer .sysmexOrganismFooter_socialIcons .sysmexOrganismFooter_socialIcon {
                margin-right: 8px
        }
            }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_desktop-social {
        padding-top: 4px;
    }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_smallHeadline {
        font-style: normal;
        font-weight: 900;
        line-height: 16px;
        margin-bottom: 16px;
        color: #333333;
        text-transform: none;
        font-size: 14px;
        /* font-family: "Source Sans Pro", sans-serif; */

        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 24px;
    }
.sysmexOrganismFooter_footer .sysmexOrganismFooter_copydate {
        white-space: nowrap;
    }

.shepherd-element {
    max-width: 350px;
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #D2D2D2;
    outline: none;
    padding: 24px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 2001;
}

.shepherd-enabled.shepherd-element {
    opacity: 1;
    visibility: visible;
}

.shepherd-element[data-popper-reference-hidden]:not(.sysmexOrganismGuidedTour_shepherd-centered) {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
}

.shepherd-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.shepherd-header h3 {
        margin: 0;
    }

.shepherd-cancel-icon {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer
}

.shepherd-cancel-icon:hover {
        color: #595959;
    }

.shepherd-cancel-icon span {
        display: inline-block;
        width: 40px;
        height: 40px;
        font-size: 40px;
        padding: 4px;
    }

.shepherd-text {
    font-size: 16px;
    margin-bottom: 24px;
}

.shepherd-footer {
    display: flex;
    margin-bottom: 24px;
}

.sysmexOrganismGuidedTour_next-button,
.sysmexOrganismGuidedTour_back-button {
    width: 100%;
    border: none;
    background-color: inherit;
}

.sysmexOrganismGuidedTour_back-button.sysmexOrganismGuidedTour_step0 {
    display: none;
}

.sysmexOrganismGuidedTour_last-tooltip {
    color: #FFFFFF;
    background-color: #864B99;
    border: none;
}

.sysmexOrganismGuidedTour_last-tooltip p,
    .sysmexOrganismGuidedTour_last-tooltip h3 {
        color: #FFFFFF;
    }

.sysmexOrganismGuidedTour_last-tooltip .shepherd-cancel-icon span {
        color: #FFFFFF
    }

.sysmexOrganismGuidedTour_last-tooltip .shepherd-cancel-icon span:hover {
            color: #595959;
        }

.sysmexOrganismGuidedTour_last-tooltip .shepherd-arrow::after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 104.76 209.51'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23864b99;%7D%3C/style%3E%3C/defs%3E%3Cg id='Ebene_2' data-name='Ebene 2'%3E%3Cg id='Ebene_2-2' data-name='Ebene 2'%3E%3Cg id='Ebene_2-3' data-name='Ebene 2'%3E%3Cg id='Ebene_3' data-name='Ebene 3'%3E%3Cpolygon class='cls-1' points='104.76 0 0 104.75 104.76 209.51 104.76 0'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }

.sysmexOrganismGuidedTour_progressBarWrapper {
    width: 100%;
    position: absolute;
    bottom: -16px;
    left: 0;
}

.shepherd-arrow {
    width: 16px;
    height: auto;
}

.shepherd-arrow,
.shepherd-arrow::before {
    position: absolute;
    z-index: -1;
}

.shepherd-arrow::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 104.76 209.53'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;%7D.cls-2%7Bfill:%23d2d2d2;%7D%3C/style%3E%3C/defs%3E%3Cg id='Ebene_2' data-name='Ebene 2'%3E%3Cg id='Ebene_3' data-name='Ebene 3'%3E%3Cg id='Ebene_2-2' data-name='Ebene 2'%3E%3Cg id='Ebene_3-2' data-name='Ebene 3'%3E%3Cpolygon class='cls-1' points='104.76 0.01 0 104.76 104.76 209.52 104.76 0.01'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cg id='Ebene_1-2' data-name='Ebene 1'%3E%3Cpolygon class='cls-2' points='104.76 0 104.76 8.26 8.7 104.3 104.76 200.36 104.76 209.53 0 104.76 104.76 0'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    transform: rotate(45deg);
}

.shepherd-element[data-popper-placement^="top"] > .shepherd-arrow {
    bottom: -26px;
}

.shepherd-element[data-popper-placement^="top"] > .shepherd-arrow::after {
    display: block;
    transform: rotate(-90deg);
}

.shepherd-element[data-popper-placement^="bottom"] > .shepherd-arrow {
    top: -26px;
}

.shepherd-element[data-popper-placement^="bottom"] > .shepherd-arrow::after {
    display: block;
    transform: rotate(90deg);
}

.shepherd-element[data-popper-placement^="left"] > .shepherd-arrow {
    right: -16px;
}

.shepherd-element[data-popper-placement^="left"] > .shepherd-arrow::after {
    display: block;
    transform: translate(-1px) rotate(180deg);
}

.shepherd-element[data-popper-placement^="right"] > .shepherd-arrow {
    left: -16px;
}

.shepherd-element .shepherd-centered > .shepherd-arrow {
    opacity: 0;
}

.shepherd-modal-overlay-container {
    display: none;
}

